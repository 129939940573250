import html from './seminars-view.html';
import lang from './seminars-view.lang.json';
import server from '../../../client/asis-server.js';
import * as mixins from '../../../client/globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate],

    data: function() {
        return {
            seminar: {},
        };
    },

    methods: {
        load() {
            server.request(
                'seminars-pub', 'seminars-get',
                {seminar_id: this.$route.params.seminar_id}, null,
                (data, extra)=>{
                    this.seminar = data;
                }
            );
        },
    },

};
