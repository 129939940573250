// ASIS - Infomační systém Astronomického ústavu AV ČR
//----------------------------------------------------------------------------
// asis.js
// základní modul zajišťující přihlášení, nabídku funkcí a připojení
// dalších rozšiřujících modulů
//----------------------------------------------------------------------------
//
//----------------------------------------------------------------------------

// linter directives
// jshint esversion:6
// jshint -W069


// DATE/TIME FORMATS
// json transfer of date: YYYY/MM/DD
// json transfer of time: HH:MM(:SS)
// json transfer of datetime: YYYY/MM/DD HH:MM(:SS)
// json transfer of timestamp (only special cases): milliseconds since 1.1.1970
// all dates and times are assumed to be in Prague local time

/* jshint esversion:6 */

import asis_version from '../asis-version.json';

// load Vue & BootstrapVue
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuelidate from 'vuelidate';
import BootstrapVue from 'bootstrap-vue';
Vue.use(VueRouter);
Vue.use(Vuelidate);
Vue.use(BootstrapVue);

// load third-party JS components and their CSS styles
import 'pc-bootstrap4-datetimepicker';
//import 'pc-bootstrap4-datetimepicker-css';
import VueSelect from 'vue-select';
import VueDatePicker from 'vue-bootstrap-datetimepicker';
Vue.component('v-select', VueSelect);
Vue.component('date-picker', VueDatePicker);
//import 'pc-bootstrap-datetimepicker-css'; -- may be needed
//import eonasdan from 'eonasdan-bootstrap-datetimepicker';
import 'timeline-vuejs';

// load global ASIS components
import './ui/components/components.js';           //TODO: remove after components are separated from this file
import './ui/components/index.js';

// load ASIS JS and CSS
import './asis-lib.js';
import server from './asis-server.js';
import router from './asis-router.js';
import store from './asis-store.js';
import i18n from './asis-i18n.js';
import ui from './ui/asis-ui.js';

import moment from 'moment';
import 'moment/locale/cs';
import 'moment/locale/en-gb';

if (!Object.fromEntries) {
    alert('Your browser does not support some of the required JavaScript functionality. Please use a more modern one.');
    throw new Error("Unsupported browser");
}


// create 'asis' variable in the global name space
// that will hold application's state information
window.asis = {
    version: asis_version.major+'.'+asis_version.minor+'.'+asis_version.patch, // version number
    dev_version: (/\/\/asis-dev/i).test(window.location) || (/localhost/i).test(window.location) || (asis_version.patch=='dev'),
    test_version: (/\/\/asis-test/i).test(window.location),
    production_version: (/\/\/asis.asu.cas.cz/i).test(window.location),
    vue: null,
    user: {
        pid: null                        // user & session information
    },
    self_institution_id: 1000,   //TODO: logic shoud be moved to server side
    consts: {
        gacr_provider: 2001,    //TODO: logic shoud be moved to server side
    }
};

// javascript uncatched rejected promise handler
window.addEventListener('unhandledrejection', function(event) {
    // the event object has two special properties: e.reason and e.promise
    var report = 
        "UNHANDLED REJECTED PROMISE\n"+
        "reason: "+event.reason+"\n"+
        "event: "+JSON.stringify(event)+"\n"+
        "url: "+window.location+"\n"+
        "route-path: "+(window.asis&&window.asis.vue ? window.asis.vue.$router.currentRoute.fullPath : '')+"\n"+
        "route-params: "+(window.asis&&window.asis.vue ? JSON.stringify(window.asis.vue.$router.currentRoute.params) : '')+"\n"+
        "event object: \n"+JSON.stringify(event);
    server.error_report(report);
    if (asis.dev_version || asis.test_version)
    alert(report);
    // alert(
    //     "ERROR: An unhanled error has occured.\n\n"+
    //     "A report has been sent to the administrators and there is nothing you can do right now "+
    //     "other than wait until the problem is resolved."
    // );
    return false;
});

// javascript error handler
window.onerror = function (msg, url, lineNo, columnNo, error) {
    if (!url.includes('https://asis')) return false;
    var report = 
        "UNHANDLED CLIENT ERROR\n"+
        "reason: "+msg+"\n"+
        "url: "+url+"\n"+
        "line: "+lineNo+"\n"+
        "error object: "+JSON.stringify(error)+"\n"+
        "asis: "+JSON.stringify(Object.assign({}, window.asis, {vue:null}))+"\n"+
        "route-path: "+(window.asis&&window.asis.vue ? window.asis.vue.$router.currentRoute.fullPath : '')+"\n"+
        "route-params: "+(window.asis&&window.asis.vue ? JSON.stringify(window.asis.vue.$router.currentRoute.params) : '')+"\n";
    server.error_report(report);
    if (asis.dev_version || asis.test_version)
    alert(report);
    // alert(
    //     "ERROR: An unhanled error has occured.\n\n"+
    //     "A report has been sent to the administrators and there is nothing you can do right now "+
    //     "other than wait until the problem is resolved."
    // );
    return false;
};

Vue.config.errorHandler = function (err, vm, info) {
    var report = 
        "UNHANDLED VUE ERROR\n"+
        "reason: "+err.toString()+"\n"+
        "info: "+JSON.stringify(info)+"\n"+
        "asis: "+JSON.stringify(Object.assign({}, window.asis, {vue:null}))+"\n"+
        "route-path: "+(window.asis&&window.asis.vue ? window.asis.vue.$router.currentRoute.fullPath : '')+"\n"+
        "route-params: "+(window.asis&&window.asis.vue ? JSON.stringify(window.asis.vue.$router.currentRoute.params) : '')+"\n";
    if (!asis.dev_version) server.error_report(report);
    if (console) console.log('Custom vue error handler: ', err, vm, info);
};
Vue.config.warnHandler = function (err, vm, info) {
    if (!asis.dev_version) server.error_report(JSON.stringify({
        err:err.toString(), info: `${info}`
    }));
    if (console) console.log('Custom vue warn handler: ', err, vm, info);
};

// check if server accepts saved auth token (if there is any)
// (the application only starts after a reply is received from the server)
store.dispatch('asis/auth/login').then(()=>{
    window.asis.vue = new Vue({
        el: '#asis',
        template: '<asis></asis>',
        components: {asis:ui},
        i18n,
        router,
        store,
    });
})
.catch(()=>{
    // TODO
});
