import html from './asis-ui.html';
import store from '../asis-store-ui.js';
import * as mixins from '../globals/mixins.js';

import statusbar from './components/statusbar.js'; 


export default {
    template: html,

    components: {statusbar},

    // i18n: {
    //     messages: lang
    // },

    mixins: [
        //mixins.store_create(store, '/asis/ui'), 
        mixins.storeAccess,
        mixins.storeGetters(['notifications'], 'asis/ui'), 
    ],

    created() {
        //console.log('asis-main-ui');
    },

};


