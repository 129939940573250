import html from './workflow-homeoffice.html';
import lang from './workflow-homeoffice.lang.json';
import * as mixins from '../../../client/globals/mixins.js';
import server from '../../../client/asis-server.js';
import moment from 'moment';



export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate],

    data: function() {
        return {
            applicant: null,
            year: null,
            month: null,
            calendar_initial_date: null,
            calendar_date_min: null,
            calendar_date_max: null,
            days_approved: [],
            days_requested: [],
            comment: '',
        };
    },

    methods: {
        load() {
            server.request(
                'timesheets-mgr', 'homeoffice-request-view', 
                this.$route.params, null,   // this.$route.params provides `request_id` and `secret`
                (data, extra) => {
                    this.applicant = data.applicant;
                    this.year = data.year;
                    this.month = data.month;
                    this.calendar_initial_date = new Date(this.year, this.month-1, 15);
                    this.calendar_date_min = new Date(this.year, this.month-1, 1);
                    this.calendar_date_max = new Date(this.year, this.month, 0);  // last day of previous month (https://stackoverflow.com/a/222439/4924581)
                    this.days_approved  = data.days_approved;
                    this.days_requested = data.days_requested;
                }
            );
        },

        save(approve) {
            if (!approve && !this.comment) {
                this.$bvModal.msgBoxOk(this.$t('error_no_reject_message'), {centered:true}).catch(e=>{});
                return;
            }

            server.request(
                'timesheets-mgr', 'homeoffice-request-approve', 
                Object.assign({}, this.$route.params, {approve, comment:this.comment})
            ).then(()=>{
                this.$router.push('/');
            });
        },

        request_approve() {
            this.save(true);
        },

        request_reject() {
            this.save(false);
        },

        getclass_calendar(ymd, date) {
            var year  = date.getFullYear();
            var month = date.getMonth()+1;
            var day   = date.getDate();
            if (this.year==year && this.month==month && this.days_requested.includes(day)) return 'ho-request';
            if (this.year==year && this.month==month && this.days_approved.includes(day)) return 'ho-approved';
            return null;
        }

    }

};
