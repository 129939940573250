import html from './test.html';
//import lang from './usr-main.lang.json';
import server from '../../../client/asis-server.js';


const view_test = {
    template: html,

    data: function() {
        return {
            worksite: null,
            department: null,
            wclass: null,
            date: '2018/07/02',
            country: 'null',
            employee: null,
            file: null,
            file_progress: 0,
            institution: null,
            provider: null,
            document_description_position: 'none',
            document:null,//{document_id:123456,name:'filename.doc', size:12345, description:"navrh projektu", revision:3, created:1234567890, url:'/link-to-file'},
            documents:[
                {document_id:121,name:'filename1.doc', size:12345, description:"priloha 1", revision:1, created:1234677990, url:'/link-to-file1'},
                {document_id:122,name:'filename2.doc', size:145, description:"navrh projektu", revision:1, created:1234967590, url:'/link-to-file2'},
                {document_id:123,name:'filename3.doc', size:1442345, description:"financni tabulky", revision:2, created:1234597990, url:'/link-to-file3'},
            ],
            document_upload:null,
            documents_uploads:null,
            markdown: '# title',
            request:{module:null, action:null, data:null, response:null},
            result: {ok:false, error:false}
        };
    },

    // load default data from server
    created: function() {
        this.load();
    },

    methods: {
        load: function() {
            //server.request(
            //    'test-usr', 'test', {},
            //    (data)=>{this.fault = data;}
            //);
        },

        set_worsite: function(){
            this.worksite = 'Praha';
        },

        set_department: function(){
            this.department = 'GPS';
        },

        set_class: function(){
            this.wclass = 'V4';
        },

        set_date: function(){
            this.date = '2018/06/12';
        },

        set_country: function(){
            this.country = 'cz';
        },

        set_institution: function(){
            this.institution = 1000;
        },

        set_provider: function(){
            this.provider = 2004;
        },

        set_employee: function(){
            this.employee = 7007001;
        },

        file_upload: function(){
            console.log('FILE:', this.file, this.file instanceof File);
            server.request('test-usr', 'file-upload', {'other':'abc', 'file':this.file}, null,
            (data,extra)=>{
                console.log(response);
                //server.request('test-usr', 'file-download', {document_id:response.docs[0].document_id}, null, {responseType:'blob'}).then((response) => {
                //});
            },
            (error)=>{},
            {onProgress:(perc)=>{this.file_progress=perc}}, 
            );
        },

        send_request: function(){
            var jsonData = (!this.request.data || this.request.data.length>0) ? JSON.parse(this.request.data) : '{}';
            server.request(this.request.module, this.request.action, jsonData, null, (response) => {
                if (!response.error) {
                    this.request.response = response;
                } else {
                    this.request.response = 'error';
                }
            });
        },

        // submit the form data to server
        submit: function() {
            this.result = {ok:false, error:false};
            /*
            server.request('faults-usr', 'report-save', this.fault).then((response) => {
                // on success show confirmation and reset the form
                if (!response.error) {
                    this.result.ok = 4;  // notification message with delayed auto-dissmiss
                    this.reset();
                } else {
                    // no soft errors are expected
                }
            });
           */
            return false;
        }

    }
};

export default view_test;

