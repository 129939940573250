import Vue from 'vue';
import server from '../../../client/asis-server.js';


export default function (store, namespace, module) {
    const store_module = {
        namespaced: true,

        state: () => ({
            calendars: [],
            calendar_id: null,
            loading: false,
        }),


        getters: {
            calendars: state => state.calendars,
            calendar_id: state => state.calendar_id,
            loading: state => state.loading,
        },


        mutations: {
            calendars(state, calendars) {
                state.calendars = calendars;
                if (!state.calendar_id && state.calendars.length>0) state.calendar_id = state.calendars[0].cal_id;
            },

            calendar_id(state, calendar_id) {
                state.calendar_id = calendar_id;
            },

            loading(state, loading) {
                state.loading = loading;
            },
        },


        actions: {
            load(context) {
                context.commit('loading', true);
                return server.request(
                    module, 'calendars-list', {}, null,
                    (data, extra)=>{
                        context.commit('calendars', data||[]);
                    }
                ).then(()=>{
                    context.commit('loading', false);
                });
            },
        },
    };

    return store_module;
};
