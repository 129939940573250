import html from './rooms-list.html';
import lang from './rooms-list.lang.json';
import server from '../../../client/asis-server.js';
//import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate],

    data: function() {
        var $t = this.$t.bind(this);
        return {
            rooms: [],
            rooms_fields: [
                {key: 'room_name', label:$t('list.room_name'), sortable:true},
                {key: 'worksite', label:$t('list.worksite'), sortable:true},
                {key: 'phone1', label:$t('list.phone1')},
                {key: 'phone2', label:$t('list.phone2')},
                {key: 'actions', label:'', thStyle:{width:"15%"}}
            ],
            loading: false,
        };
    },

    methods: {
        load() {
            this.loading = true;
            server.request(
                'personalinfo-mgr', 'rooms-list', {}, null,
                (data)=>{
                    this.rooms = data;
                    this.loading = false;
                }
            );
        },
    }

};


