import html from './projects-summary.html';
import lang from './projects-summary.lang.json';
import server from '../../../client/asis-server.js';
import * as mixins from '../../../client/globals/mixins.js';
import * as directives from '../../../client/globals/directives.js';
import * as validators from '../../../client/globals/validators.js';

export default {
    template: html,

    mixins: [
        //mixins.module_name, 
        directives.label_required, 
        mixins.loadDataOnCreate, 
    ],

    i18n: {
        messages: lang
    },

    data() {
        return {
            project: null,
            loading: false,
            saving: false,
            show_saved_notification: false,
        }
    },

    validations() {
        return {
            project: {
                summary_cz: {
                    required: validators.required,
                    minLength: validators.minLength(300),
                },
                summary_en: {
                    required: validators.required,
                    minLength: validators.minLength(300),
                },
            },
        };
    },

    methods: {
        load() {
            var project_id = this.$route.params.project_id;
            this.loading = true;
            return server.request(
                'projects-usr', 'projects-summary-get', {project_id}, null,
                (data, extra) => {
                    this.project = data;
                }
            ).then(()=>{
                this.loading = false;
            });
        },

        save() {
            this.saving = true;
            this.show_saved_notification = false;
            return server.request(
                'projects-usr', 'projects-summary-save', {
                    project_id: this.project.project_id,
                    summary_cz: this.project.summary_cz,
                    summary_en: this.project.summary_en,
                }, null,
                (data, extra) => {
                    this.show_saved_notification = true;
                }
            ).then(()=>{
                this.saving = false;
            });
        }

    },
};
