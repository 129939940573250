import html from './seminars-list.html';
import lang from './seminars-list.lang.json';
import server from '../../../client/asis-server.js';
import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate],

    data: function() {
        var $t = this.$t.bind(this);
        return {
            loading: false,
            seminars: [],
            seminars_fields: [
                {key: 'date', label:$t('list.date'), thClass:'text-nowrap', tdClass:'text-nowrap', formatter:translators.datetime_asis2ddmmyyyy_hhmm},
                {key: 'title', label:$t('list.title'), thStyle:{width:"70%"}},
                {key: 'author', label:$t('list.author'), thStyle:{width:"30%"}},
                {key: 'department', label:$t('list.department')},
                {key: 'owner_name', label:$t('list.owner'), thClass:'text-nowrap'},
                {key: 'actions', label:''}
            ],
            seminars_old: 0,
            seminars_page: 1,
        };
    },

    methods: {
        load() {
            this.loading = true;
            server.request(
                this.$parent.module_name, 'seminars-list', {show_old:this.seminars_old}, null,
                (data)=>{
                    this.seminars = data;
                }
            ).then(()=>{
                this.loading = false;
            });
        },

        seminar_class(item, type) {
            //if (!item || type !== 'row') return;
            if (item && item.past_event) return 'text-muted';
        },
    },

    watch: {
        seminars_old(newV, oldV) {
            this.load();
        }
    }


};

 
