'use strict';
// jshint esversion:6
// jshint -W069


import config from './timesheets.config.json';
import view_mgr_main from './views/main-mgr.js';
import view_timesheet from './views/timesheet.js';
import view_settings from './views/settings.js';
import view_workflow_homeoffice from './views/workflow-homeoffice.js';
import view_breakdowns_meals from './views/breakdowns-meals.js';
import view_overviews_shifts from './views/overviews-shifts.js';
import view_overviews_meals from './views/overviews-meals.js';
import view_overviews_accounting from './views/overviews-accounting.js';
import view_overviews_worksitetrips from './views/overviews-worksitetrips.js';
import view_worksitetrips_usr_request from './views/worksitetrips/worksitetrips-usr-request.js';
import view_worksitetrips_usr_view from './views/worksitetrips/worksitetrips-usr-view.js';
import view_worksitetrips_mgr_approve from './views/worksitetrips/worksitetrips-mgr-approve.js';
import view_worksitetrips_mgr_overview from './views/worksitetrips/worksitetrips-mgr-overview.js';

import moment from 'moment';


const current_date = moment().format('YYYYMM');

const module_timesheets_usr = {
    module_name: 'timesheets-usr',
    module_class: 'usr',
    title: config.usr.title,
    icon: config.usr.icon,
    routes: [
        // default route
        {path:'', redirect:{name:'timesheets-usr-timesheet', params:{date:current_date}}},
        // specific routes
        {name:'timesheets-usr-timesheet', path:'timesheet/:date', component:view_timesheet},
        {name:'timesheets-usr-settings', path:'settings', component:view_settings},
        {name:'timesheets-usr-meals-breakdown', path:'meals-breakdown/:date', component:view_breakdowns_meals},
        {name:'timesheets-mgr-worksitetrips-request', path:'worksitetrips/request/:date', component:view_worksitetrips_usr_request},
        {name:'timesheets-mgr-worksitetrips-view', path:'worksitetrips/view/:requisition_id/:secret', component:view_worksitetrips_usr_view},
    ]
};

const module_timesheets_mgr = {
    module_name: 'timesheets-mgr',
    module_class: 'mgr',
    title: config.mgr.title,
    icon: config.mgr.icon,
    routes: [
        // default route
        {path:'', component: view_mgr_main, xredirect:{name:'timesheets-mgr-overview-shifts', params:{date:current_date}}, children: [
            {name:'timesheets-mgr-timesheets', path:'timesheet/:date', component:view_timesheet},
            {name:'timesheets-mgr-overview-shifts', path:'overview-shifts/:date', component:view_overviews_shifts},
            {name:'timesheets-mgr-overview-meals', path:'overview-meals/:date', component:view_overviews_meals},
            {name:'timesheets-mgr-overview-accounting', path:'overview-accounting/:date', component:view_overviews_accounting},
            {name:'timesheets-mgr-overview-worksitetrips', path:'overview-worksitetrips/:date', component:view_overviews_worksitetrips},
            {name:'timesheets-mgr-worksitetrips-overview', path:'worksitetrips/overview', component:view_worksitetrips_mgr_overview},
        ]},
        // specific routes
        {name:'timesheets-mgr-worksitetrips-approve', path:'worksitetrips/approve/:requisition_id/:secret/:role', component:view_worksitetrips_mgr_approve},
        {name:'timesheets-mgr-workflow-homeoffice', path:'workflow/homeoffice/:request_id/:secret', component:view_workflow_homeoffice},
    ]
};

export default [module_timesheets_usr, module_timesheets_mgr];


