import html from './project-submit.html';
import lang from './project-submit.lang.json';
import server from '../../../../client/asis-server.js';
import * as mixins from '../../../../client/globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.asis,
        mixins.storeAccess,
        mixins.storeGetters([
            'project','$v','pending_approval_financial', 'pending_approval_council', 
            'save_errors', 'missing_abstract','missing_budget','missing_proposal'
        ]),
    ],

    data() {
        return {
            submit_comments: '',
            send_final_running: false,
            send_final_sent: false,
            send_final_error: null,
        };
    },

    methods: {
        // send proposal to director's office and mark it as submitted
        async send_final() {
            this.send_final_running = true;

            // save project and check any save errors before the approval request
            await this.store_dispatch('save');
            if (this.save_errors.length > 0) {
                this.send_final_running = false;
                this.$bvModal.msgBoxOk(this.$t('save_errors'),{centered:true});
                return false;
            }

            await server.request(
                'projects-usr', 'projects-submit-director', 
                {project_id: this.project.project_id, comments:this.submit_comments}, null,
                (result) => {
                    this.$bvModal.msgBoxOk(this.$t('send_final_sent'),{centered:true}).then(()=>{
                        this.$router.replace({name:'projects-usr-list'});
                    })
                },
                (error_code) => {
                    this.send_final_error = error_code;
                }
            );

            this.send_final_running = false;
        }
    },

};
