//----------------------------------------------------------------------------
// ASIS Informational System
//----------------------------------------------------------------------------
// indexes/employee.js
// component for the list of employees
//----------------------------------------------------------------------------
// (c) Astronomický ústav AV ČR, v.v.i. (see licence file for legal info)
//----------------------------------------------------------------------------

'use strict';
// jshint esversion:6


import Vue from 'vue';
import jquery from 'jquery';
import server from '../../../client/asis-server.js';

// A general note on two-way data binding:
// The underlying component do not typicly  use v-model, but instead they use
// v-bind and v-on to handle proper 2-way propagation of
// the selected value. v-bind propagates the value from the parent
// to the child (e.g. to v-select) and v-on propagates changes of the value back to
// the parent using an event (in case of a component, $event is the actual value).
// The prop for the value MUST be named `value` for this to work.
// see: https://vuejs.org/v2/guide/components.html#Using-v-model-on-Components
// Example:
//      <v-select
//          v-bind:value="value"
//          v-on:input="$emit('input', $event)">
//      </v-select>



// department component
// a drop-down select for department
Vue.component('asis-employee', {
    template: `
        <v-select 
                v-model="employee"
                :options="employees" 
                :searchable="true" 
                :label="selectLabel"
                :disabled="readonly || disabled"
                :clearable="false">
        </v-select>
    `,

    props: ['value','state','disabled','readonly'],  //TODO keep ony one of readonly/disabled

    data: function() {
        return {
            // define a variable to store the list of worksites
            employees: [],    // list of departments
            employee: null,   // selected department (this is a member of this.departments - an object)
            selectLabel: 'full_name_last'
        };
    },

    created: function() {
        // load the list of departments from server
        server.request(
            'indexes-pub', 'employees-list', {}, null,
            (data)=>{
                this.employees = data;
                // select a default
                this.employee = null;
                for (let e of this.employees) {
                    if (e['pid'] == this.value) {this.employee = e; break;}
                }
            }
        );
    },

    watch: {
        value(){
            // on value change (induced by the parent) find the corresponding item in the list of departments
            // and set the local `department` variable accordingly
            var new_value = null;
            for (let e of this.employees) {
                if (e['pid'] == this.value) {new_value = e; break;}
            }
            this.employee = new_value;
        },
        employee(value) {
            // on local value change (induced by v-select), notify the parent of the change,
            // but send back only the scalar value
            this.$emit('input', this.employee ? this.employee['pid'] : null);
        },
        state() {
            // on state change, set/clear the error class of the v-select
                        //TODO:rewrite this using Vue
            if (this.state) jquery(this.$el).removeClass('is-invalid'); else jquery(this.$el).addClass('is-invalid');
        }
    }
});

