import html from './students-list-usr.html';
import lang from './students-list-usr.lang.json';
import server from '../../../client/asis-server.js';
import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';
import student_card from './components/student-card.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.module_name, mixins.loadDataOnCreate],

    components: {'student-card': student_card},

    data: function() {
        return {
            students: [],
            loading: false,
        };
    },

    methods: {
        load() {
            this.loading = true;
            server.request(
                this.module_name, 'students-list', {}, null,
                (data)=>{
                    this.students = data;
                }
            ).then(()=>{
                this.loading = false;
            });
        },

    },

    computed: {
        university_students() {return this.students.filter(student => student.is_university); },
        highschool_students() {return this.students.filter(student => student.is_highschool); },
        other_students()      {return this.students.filter(student => !student.is_university && !student.is_highschool); },
    }


};

 
