import html from './drives-edit.html';
import lang from './drives-edit.lang.json';
import * as directives from '../../../client/globals/directives.js';
import * as validators from '../../../client/globals/validators.js';
import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';
import store from './drives-edit-store.js';
import moment from 'moment';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    props: {
        drive_id: {type: Number, default: 0},
        car: {type: String, default: null},
    },

    data() {
        return {
            car_selected: this.car || null,
        }
    },

    mixins: [
        mixins.module_name, 
        mixins.loadDataOnCreate,
        directives.label_required, validators.vuelidate,
        mixins.store_create(store, 'drives-edit'), 
        mixins.storeGetters(['drive','cars','destinations','can_edit','loading','saving','removing','error','$v']), 
        mixins.storeAccess, 
    ],

    beforeRouteUpdate(to, from, next) {
        this.load();
        next();
    },

    methods: {
        load() {
            this.store_dispatch('load_cars').then(()=>{
                if (!this.car && !this.drive_id) {
                    // preselect either car prop if given or the first car from the list
                    var regplates = this.cars.map(c => c.regplate);
                    var preselect = (this.cars.length == 1) ? this.cars[0].regplate : null;
                    if (this.car_selected != preselect) this.$router.replace({name:this.$route.name, params:{car:preselect}});
                }

                // load drive
                this.store_dispatch('load_drive', {drive_id:this.drive_id, car:this.car_selected});
            });
        },

        save() {
            new Promise((resolve, reject) => {
                if (!this.drive.drive_id) return resolve();
                const h = this.$createElement;
                const cond_dest = (this.drive.destination == this.drive.destination_original);
                const cond_odo  = (this.drive.odo_end == this.drive.odo_end_original);
                let messages = [];
                if (!cond_dest) messages.push(h('p', {domProps: {innerHTML:this.$t('destination_change_confirm')}}));
                if (!cond_odo)  messages.push(h('p', {domProps: {innerHTML:this.$t('odo_end_change_confirm')}}));
                messages.push(h('p', {domProps: {innerHTML:this.$t('continue')}}));;
                if (messages.length <= 1) return resolve();
                this.$bvModal.msgBoxConfirm(messages, {okVariant:'warning', centered:true})
                    .then(value => {value ? resolve() : reject();})
                    .catch(err => reject());
            })
            .then(()=>{
                this.store_dispatch('save').then(
                    (ok) => {
                        this.$router.back();
                    }, 
                    (error) => {
                        // noop
                    }
                );
            })
            .catch(()=>{
                // nothing
            });
        },

        remove() {
            if (!confirm(this.$t('remove_confirm'))) return false;

            this.store_dispatch('remove').then(()=>{this.$router.back()});
        },

        uppercase: translators.uppercase,
    },

    computed: {
        car_names: function() {
            return this.cars.map(c => ({
                value: c.regplate, 
                text:  c.regplate + (c.alias ? ' ('+c.alias+')' : '')
            }));
        },
        cost_per_litre: function() {
            if (this.drive.fueling_volume>0 && this.drive.fueling_cost>0) {
                var u = this.drive.fueling_cost/this.drive.fueling_volume;
                return this.$t('fueling_unit_cost1')+': '+u.toFixed(2)+' '+this.$t('fueling_unit_cost2');
            } else {
                return null;
            }
        }
    },

    watch:{
        car_selected(newValue) {
            if (!this.drive_id && this.car!=newValue) this.$router.replace({name:this.$route.name, params:{car:newValue}});
        }
    }

};

