'use strict';
// jshint esversion:6
// jshint -W069


import config from './carlog.config.json';
import view_main_usr from './views/main-usr.js';
import view_main_mgr from './views/main-mgr.js';
import view_drives_list_usr from './views/drives-list-usr.js';
import view_drives_edit_usr from './views/drives-edit.js';
import view_orders_list_usr from './views/orders-list.js';
import view_drives_list_mgr from './views/drives-list-mgr.js';



const module_carlog_usr = {
    module_name: 'carlog-usr',
    module_class: 'usr',
    title: config.usr.title,
    icon: config.usr.icon,
    routes: [
        // default route (with sub-tabs)
        {path:'', component: view_main_usr, redirect:{name:'carlog-usr-drives-list'}, children: [
            {name:'carlog-usr-drives-list', path:'drives/list/:car?', component:view_drives_list_usr, props:true},
        ]},
        // specific routes
        {name:'carlog-usr-drives-add', path:'drives/add/:car?', component:view_drives_edit_usr, props:true},
        {name:'carlog-usr-drives-edit', path:'drives/edit/:car/:drive_id', component:view_drives_edit_usr, props:true},
    ]
};

const module_carlog_mgr = {
    module_name: 'carlog-mgr',
    module_class: 'mgr',
    title: config.mgr.title,
    icon: config.mgr.icon,
    routes: [
        // default route (with sub-tabs)
        {path:'', component: view_main_mgr, redirect:{name:'carlog-mgr-drives-list'}, children: [
            {name:'carlog-mgr-drives-list', path:'drives/list', component:view_drives_list_mgr},
        ]},
        // // specific routes
        // {name:'carlog-usr-drives-add', path:'drives/add/:car?', component:view_drives_edit_usr, props:true},
        // {name:'carlog-usr-drives-edit', path:'drives/edit/:car/:drive_id', component:view_drives_edit_usr, props:true},
    ]
};


export default [module_carlog_usr,module_carlog_mgr];
