import Vue from 'vue';
import server from '../../../client/asis-server.js';
import * as validators from '../../../client/globals/validators.js';




export default function (store, namespace, module) {
    const validator = new Vue({
        mixins: [
            validators.vuelidate
        ],
        computed: {
            activity() {
                return store.getters[namespace + '/activity'];
            }
        },
        validations() {
            var $data_validations = {};
            if (this.activity){
            switch (this.activity.category) {
                case 'public-lecture':
                    $data_validations = {
                        'url': {
                            required: validators.required,
                        }
                    };
                    break;
            };
            }
            //console.log('validations',(this.activity||{}).category, this, $data_validations);
            return {
                activity: {
                    category: {
                        required: validators.required,
                    },
                    //data: $data_validations,
                },
            };
        },
    });


    const store_module = {
        namespaced: true,

        state: () => ({
            activity: null,
            documents: [],
            documents_uploads: [],
            loading: false,
            saving: false,
            removing: false,
            save_progress: 0,
        }),

        getters: {
            activity: state => state.activity,
            documents: state => state.documents,
            documents_uploads: state => state.documents_uploads,
            loading: state => state.loading,
            saving: state => state.saving,
            removing: state => state.removing,
            save_progress: state => state.save_progress,
            $v: state => Object.assign({}, validator.$v),    // this is not to expose API of $v
        },


        mutations: {
            activity(state, data) {
                state.activity = data.activity;
                state.documents = data.documents || [];
                //console.log('set activity', state.activity, state.documents);
            },

            documents_uploads(state, docs) {
                state.documents_uploads = docs;
                //console.log('state.uploads_proposal',state.documents_uploads);
            },

            loading(state, loading) {
                state.loading = loading;
            },

            saving(state, saving) {
                state.saving = saving;
                state.save_progress = 0;                // reset progress on any saving state change
                if (saving) state.save_errors = [];     // reset error on start saving
            },

            save_progress(state, progress) {
                state.save_progress = progress;
            },

            // save_errors(state, errors) {
            //     state.save_errors = errors;
            // },

            removing(state, removing) {
                state.removing = removing;
            },

        },


        actions: {
            load(context, {activity_id, category}) {
                context.commit('loading', true);
                return server.request(
                    module, 'activities-get', {activity_id}, {category},
                    (data, extra) => {
                        context.commit('activity', {
                            activity: data, 
                            documents: extra.documents,
                        });
                    }
                ).then(()=>{
                    context.commit('loading', false);
                });
            },

            save(context) {
                if (validator.$v.$invalid) return;
                context.commit('saving', true);
                return server.request(
                    module, 'activities-save', context.state.activity, {
                        documents: context.state.documents_uploads,
                    },
                    null, null, // callbacks
                    {onProgress:(percent)=>{
                        context.commit('save_progress', percent);
                    }, timeout:600000},
                ).then(()=>{
                    // when finished, reset uploads and reset saving flag
                    context.state.documents_uploads = [],
                    context.commit('saving', false);
                });
            },

            remove(context) {
                context.commit('removing', true);
                return server.request(
                    module, 'activities-remove', {activity_id:context.state.activity.activity_id}, {},
                    (data, extra) => {
                        context.commit('activity', {activity:null, documents:[]});
                    }
                ).then(()=>{
                    context.commit('removing', false);
                });
            }
        },

    };

    return store_module;
};
