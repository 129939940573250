import html from './projects-edit-mgr.html';
import lang from './projects-edit-mgr.lang.json';
import * as validators from '../../../client/globals/validators.js';
import server from '../../../client/asis-server.js';
import * as mixins from '../../../client/globals/mixins.js';

import store from './projects-edit-store.js';
import tab_info from './components/project-info.js';
import tab_budget from './components/project-budget.js';
import tab_documents from './components/project-documents.js';
import tab_approvals from './components/project-approvals-mgr.js';
import tab_status from './components/project-status.js';
import tab_submit from './components/project-submit.js';

export default {
    template: html,

    mixins: [
        mixins.module_name, 
        mixins.loadDataOnCreate, 
        mixins.store_create(store, 'projects-edit'),
        mixins.storeAccess,
        mixins.storeGetters(['project','loading','saving','save_progress','save_errors','$v']),
    ],

    props: {
        readonly: {
            type: Boolean,
            default: false
        },
    },

    components: {
        'project-info': tab_info,
        'project-budget': tab_budget,
        'project-documents': tab_documents,
        'project-approvals': tab_approvals,
        'project-status': tab_status,
    },

    i18n: {
        messages: lang
    },

    // // TODO
    // // provide/inject mechanism in Vue2 is not reactive, but it seems to be the right way to pass
    // // `project` and $v objects to children components, better than v-model and props
    // // In vue3, it is doable, but has to wait until BootstrapVue 3
    // // BUT: https://blog.logrocket.com/how-to-make-provide-inject-reactive/
    // provide() {
    //     // provide object to all children components
    //     return {
    //         readonly: this.readonly,    // readonly flag
    //     };
    // },

    methods: {
        load() {
            this.store_dispatch('load', this.$route.params.project_id||0);
        },

        save() {
            this.store_dispatch('save');
        },
    },
};
