import * as translators from '../../../client/globals/translators.js';
import server from '../../../client/asis-server.js';

'use strict';
// jshint esversion:6


//TODO: this component shaould be replace by the global one (asis-document)

// file list component
export default {
    template: `
        <b-table-simple responsive small class="border-bottom mb-2">
            <b-tbody>
                <b-tr>
                    <b-td style="width:60%">
                        <b-link :href="document.url" class="text-info font-weight-bold">{{document.name}}</b-link>
                    </b-td>
                    <b-td>{{date_timestamp2ddmmyyyy(document.created)}}</b-td>
                    <b-td>rev.&nbsp;{{document.revision}}</b-td>
                    <b-td>{{filesize_auto(document.size)}}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>                
    `,

    props: ['document'],

    // data must be a function, so each instance has its own copy of data
    data: function() {
        return {
        };
    },

    methods: {
        // translators:
        date_asis2ddmmyyyy: translators.date_asis2ddmmyyyy,
        date_timestamp2ddmmyyyy: translators.date_timestamp2ddmmyyyy,
        filesize_auto: translators.filesize_auto,
    }
};




