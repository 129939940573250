import html from './projects-view.html';
import lang from './projects-view.lang.json';
import * as mixins from '../../../client/globals/mixins.js';

import store from './projects-view-store.js';
import tab_info from './components/project-info.js';
import tab_budget from './components/project-budget.js';
import tab_documents from './components/project-documents.js';
import tab_approvals from './components/project-approvals-mgr.js';


export default {
    template: html,

    mixins: [
        mixins.module_name, 
        mixins.loadDataOnCreate, 
        mixins.store_create(store, 'projects-view'),
        mixins.storeAccess,
        mixins.storeGetters(['project','loading']),
    ],

    components: {
        'project-info': tab_info,
        'project-budget': tab_budget,
        'project-documents': tab_documents,
        'project-approvals': tab_approvals,
    },

    i18n: {
        messages: lang
    },

    methods: {
        load() {
            this.store_dispatch('load', {
                project_id: this.$route.params.project_id,
                authorization: this.$route.params.authorization,
                secret: this.$route.params.secret
            });
        },
    },
};
