// ASIS - lib

// JSHint: disable W069 warning (use dotted notation instead of bracket notation)
/*jshint -W069 */



// extend String object with trim() function to return string with leading and trailing white-spaces trimmed off
// only extend if not already there (in new browsers with ES >= 5)
if (!String.prototype.trim) {
    String.prototype.trim = function() {
        return this.replace(/^\s+|\s+$/g, '');
    };
}

// extend String object with empty() function to return if if a string is blank or contains only white-space
String.prototype.empty = function() {
    return (this.length === 0 || !this.trim());
};

// pad string with a given character on the left side
String.prototype.pad = function(len, c){
    var s = '', c = c || ' ', len = (len || 2)-this.length;
    while (s.length < len) s += c;
    return s + this;
}

// extend Math object to return a number rounded to 1 decimal digits
Math.round10 = function(number) {
    return Math.round(number*10.0)/10.0;
};

// return 
Math.randInt = function(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

// extend Math object to return a number rounded to 2 decimal digits
Math.round100 = function(number) {
    return Math.round(number*100.0)/100.0;
};

// return number as string left-padded with zeros
Number.prototype.zeroPad= function(len){
    return String(this).pad(len,'0');
}


// extend Number object to return a string representation formated to given number of decimal places, but without trailing zeros
Number.prototype.toFixed2 = function(places) {
    var s = this.toFixed(places);
    if (places < 1) return s;
    while (s.charAt(s.length-1) == '0') s = s.substr(0,s.length-1);
    if (s.charAt(s.length-1) == '.') s = s.substr(0,s.length-1);
    return s;
};



// extend Date object to return a copy of itself
Date.prototype.clone = function() {
    return new Date(this.getTime());
};

// extend Date object to reset the time of the Date object to 12:00 AM (00:00), which is the start of the day
Date.prototype.clearTime = function () {
    this.setHours(0);
    this.setMinutes(0);
    this.setSeconds(0);
    this.setMilliseconds(0);
    return this;
};

// extend Date object to return a new date object with the date set to n-th day of current month
Date.prototype.nthDay = function(day) {
    var d = this.clone().clearTime();
    d.setDate(day);
    return d;
};

// extend Date object to add the specified number of days to this instance (number can be positive or negative)
Date.prototype.addDays = function(value) {
    this.setDate(this.getDate() + value);
    return this;
};

// extend Date object to tell if the two dates are equal
Date.equals = function(date1, date2) { 
    return (date1.compareTo(date2) === 0); 
};

// extend Date object to tell if the instance is equal to a date
Date.prototype.equals = function(date) {
    return Date.equals(this, date || new Date());
};

// extend Date object to compare to another one
Date.prototype.compareTo = function (date) {
    return Date.compare(this, date);
};

// extend Date object to compare the first date to the second date
// - returns an number -1 if date1<date2, 0 if date1=date2, +1 if date1>date2.    
Date.compare = function (date1, date2) {
    if (isNaN(date1) || isNaN(date2)) { 
        throw new Error(date1 + " - " + date2); 
    } else if (date1 instanceof Date && date2 instanceof Date) {
        return (date1 < date2) ? -1 : (date1 > date2) ? 1 : 0;
    } else { 
        throw new TypeError(date1 + " - " + date2); 
    }
};    

Date.prototype.asis_date = function () {
    var d = this.getDate().toString().pad(2,'0');
    var m = (this.getMonth()+1).toString().pad(2,'0');
    var Y = this.getFullYear().toString();
    return Y+'/'+m+'/'+d;
};

// extend Date object to return date of easter Sunday
Date.prototype.easter = function(year) {
    if (!year) year = this.getFullYear();
    var a = year % 19;
    var b = Math.floor(year/100);
    var c = year % 100;
    var d = Math.floor(b/4);
    var e = b % 4;
    var f = Math.floor((b+8) / 25);
    var g = Math.floor((b-f+1) / 3);
    var h = (19*a + b - d - g + 15) % 30;
    var i = Math.floor(c/4);
    var j = c % 4;
    var k = (32 + 2*e + 2*i - h - j) % 7;
    var m = Math.floor((a + 11*h + 22*k) / 451);
    var MM = Math.floor((h + k - 7*m + 114) / 31);
    var DD = ((h + k - 7*m +114) % 31) + 1;
    return new Date(year, MM-1, DD);
};

// extend Date object to return list of CZ public holidays in a given year
Date.prototype.getPublicHolidaysCZ = function(year) {
    var holidays = [];
    if (!year) year = this.getFullYear();
    // add all fixed day holidays
    holidays.push(new Date(year,  0,  1));
    holidays.push(new Date(year,  4,  1));
    holidays.push(new Date(year,  4,  8));
    holidays.push(new Date(year,  6,  5));
    holidays.push(new Date(year,  6,  6));
    holidays.push(new Date(year,  8, 28));
    holidays.push(new Date(year,  9, 28));
    holidays.push(new Date(year, 10, 17));
    holidays.push(new Date(year, 11, 24));
    holidays.push(new Date(year, 11, 25));
    holidays.push(new Date(year, 11, 26));
    holidays.push(this.easter(year).addDays(+1));  // easter Monday
    if (year>=2016) holidays.push(this.easter(year).addDays(-2));  // easter Friday
    return holidays;
};

// extend Date object to test if current day is a public holiday
Date.prototype.isHoliday = function() {
    var day  = this.clone().clearTime();
    var holidays = this.getPublicHolidaysCZ();
    var result = false;
    for (let d of holidays) result = result || day.equals(d);
    return result;
};

// extend Date object to test if current day is a weekend
Date.prototype.isWeekend = function() {
    return ((this.getDay() === 0) || (this.getDay() == 6));
};

// extend Date object to get number of paid (weekday) public holidays in a month
Date.prototype.getPaidHolidaysInMonth = function() {
    var result = 0;
    var m = this.getMonth();
    var y = this.getFullYear();
    var h = this.getPublicHolidaysCZ();
    for (var i=0; i<h.length; ++i) if ((h[i].getMonth() == m) && (h[i].getDay() !== 0) && (h[i].getDay() != 6)) result++;
    return result;
};

// extend window object with defaultLanguage property that indicates default browser language
window.defaultLanguage =
    ((window.navigator.userLanguage || window.navigator.language).toLowerCase().indexOf("cs") != -1) ? 'cz' : 'en'; 

/*
// extend Date object to get number of all days in a month
// extend Date object to get number of all days in a month between the two dates (inclusive)
Date.prototype.getTotalDaysInMonth = function(d1,d2){
    if (typeof d1 === 'string') d1 = new Date(d1); if (d1) d1.clearTime();
    if (typeof d2 === 'string') d2 = new Date(d2); if (d2) d2.clearTime();
    var monthFirst = this.clone(); monthFirst.moveToFirstDayOfMonth().clearTime();
    var monthLast  = this.clone(); monthLast.moveToLastDayOfMonth().clearTime();
    var daysInMonth = Date.getDaysInMonth(this.getFullYear(), this.getMonth());
    var reduction = 0;
    if (d1) {
        if (d1.isAfter(monthLast)) return 0;
        if (this.isSameYearAndMonth(d1)) reduction += (d1.getDate() - 1);
    }
    if (d2) {
        if (d2.isBefore(monthFirst)) return 0;
        if (this.isSameYearAndMonth(d2)) reduction += (daysInMonth - d2.getDate());
    }
    return Math.round(daysInMonth - reduction);
}

// extend Date object to get number of working days in a month (monday to friday taking into account public holidays)
Date.prototype.getTotalWorkingDaysInMonth = function(d1,d2) {
    if (typeof d1 === 'string') d1 = new Date(d1); if (d1) d1.clearTime();
    if (typeof d2 === 'string') d2 = new Date(d2); if (d2) d2.clearTime();
    var result = 0;
    for (var d=1; d<=this.getTotalDaysInMonth(); d++) {
        var day = this.clone().clearTime(); day.setDate(d);
        if ((day.getDay() != 0) && (day.getDay() != 6) && (!day.isHoliday()) && ((d1==null)||(!day.isBefore(d1))) && ((d2==null)||(!day.isAfter(d2)))) result++;
    }
    return result;
};
*/

// extend Date object to compare if the two dates are of the same year and month
// input can be Date object or string
Date.prototype.isSameYearAndMonth = function(date) {
    if (typeof date === 'string') date = new Date(date);
    return ((this.getMonth() == date.getMonth()) && (this.getFullYear() == date.getFullYear()));
};

// extend Date object to compare if the intrinsic date is same or later than the supplied date (in terms of year and month)
// input can be Date object or string
Date.prototype.isSameOrLaterYearAndMonth = function(date) {
    if (typeof date === 'string') date = new Date(date);
    return (
        ((this.getFullYear() == date.getFullYear()) && (this.getMonth() >= date.getMonth())) ||
            (this.getFullYear() >  date.getFullYear())
    );
};

// extend Date object to compare if the intrinsic date is same or sooner than the supplied date (in terms of year and month)
// input can be Date object or string
Date.prototype.isSameOrSoonerYearAndMonth = function(date) {
    if (typeof date === 'string') date = new Date(date);
    return (
        ((this.getFullYear() == date.getFullYear()) && (this.getMonth() <= date.getMonth())) ||
            (this.getFullYear() <  date.getFullYear())
    );
};

// extend Date object to compare if the intrinsic date is in between supplied dates (in terms of year and month)
// input can be Date object or string
Date.prototype.isBetweenYearAndMonth = function(d1, d2) {
    if (typeof d1 === 'string') date = new Date(d1);
    if (typeof d2 === 'string') date = new Date(d2);
    return (this.isSameOrLaterYearAndMonth(d1) && this.isSameOrSoonerYearAndMonth(d2));
};


Object.filter_by_value = function(obj, predicate) 
// Returns filtered object, where only properties with value confirmed by predicate function will be included.
// https://stackoverflow.com/questions/5072136/javascript-filter-for-objects
// Example:
// var scores = {John: 2, Sarah: 3, Janet: 1};
// var filtered = Object.filter_by_value(scores, score => score > 1); 
// gives: {"John": 2, "Sarah": 3}
{ 
    return Object.keys(obj)
        .filter( key => predicate(obj[key]) )
        .reduce( (res, key) => Object.assign(res, { [key]: obj[key] }), {} );
}


// extend Array to search in array of objects
// iterates over objects in array and returns first object that has matching
// key:value or returns null if there is not such object
Array.prototype.findObject = function(key, value) {
    if (this == null) throw new TypeError('this is null or not defined');
    for (let i=0; i<this.length; i++) {
        if ((typeof this[i] === 'object') && (this[i][key] === value)) return this[i];
    }
    return null;
};


// extend Array to search in array of objects
// iterates over objects in array and returns index of the first object that has matching
// key:value or returns -1 if there is not such object
Array.prototype.indexOfObject = function(key, value) {
    if (this == null) throw new TypeError('this is null or not defined');
    for (let i=0; i<this.length; i++) {
        if ((typeof this[i] === 'object') && (this[i][key] === value)) return i;
    }
    return -1;
};

