import html from './students-edit.html';
import lang from './students-edit.lang.json';
import server from '../../../client/asis-server.js';
import * as directives from '../../../client/globals/directives.js';
import * as validators from '../../../client/globals/validators.js';
import * as mixins from '../../../client/globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.module_name, mixins.loadDataOnCreate, directives.label_required, validators.vuelidate],

    data: function() {
        return {
            student: {},                        // student data
            //submit_error: null,                 // soft error
            options_contract_types: {},
            options_supervisor_roles: {},
            submit_error: null,
        };
    },

    validations: {
        student: {
            first_name: {
                required: validators.required,
            },
            last_name: {
                required: validators.required,
            },
            institution_id: {
                required: validators.required,
            },
            program: {
                maxLength: validators.maxLength(50),
            },
            supervisor: {
                required: validators.required,
            },
            supervisor_role: {
                required: validators.required,
            },
            contract_type: {
                required: validators.required,
            },
            contract_start: {
                required: validators.required,
                date: validators.asis_date
            },
            contract_end: {
                required: validators.required,
                date: validators.asis_date
            },
            defense_date: {
                date: validators.asis_date
            },
            title_cz: {
                required: validators.required,
            },
            title_en: {
                required: validators.required,
            },
            abstract_cz: {
                required: validators.requiredIf(e => ['bsc','msc','phd'].includes(e.contract_type)),
            },
            abstract_en: {
                required: validators.requiredIf(e => ['bsc','msc','phd'].includes(e.contract_type)),
            },
            comment: {},
        }
    },

    methods: {
        load() {
            var copy = this.$route.path.includes('/copy/');
            server.request(
                this.module_name, 'students-get',
                {student_id: this.$route.params.student_id||0}, {copy},
                (data, extra) => {
                    this.student = data;
                    this.options_contract_types = extra.contract_types.map(t => ({value:t, text:this.$t('contract_types.'+t)}));
                    this.options_supervisor_roles = extra.supervisor_roles.map(t => ({value:t, text:this.$t('supervisor_roles.'+t)}));
                }
            );
        },

        submit() {
            // check if the form validates
            if (this.$v.$invalid) return;

            this.submit_error = null;
            server.request(
                this.module_name, 'students-save', this.student, null, 
                (data) => {
                    // if ok, go to previous view
                    this.$router.back();
                }, 
                (error) => {
                    this.submit_error = error;
                }
            );
            return false;
        },

        remove() {
            const h = this.$createElement;
            var html_message = this.$t('remove_confirmation').split("\n").map($t => h('p',$t));
            this.$bvModal.msgBoxConfirm(html_message, {
                title: this.$t('remove'),
                okVariant: 'danger',
                okTitle: this.$t('remove'),
                cancelTitle: this.$t('remove_cancel'),
                centered: true
            }).then((value) => {
                if (!value) return false;
                server.request(
                    this.module_name, 'students-delete', {student_id: this.student.student_id}, null, 
                    (data) => {
                        // if ok, go to previous view
                        this.$router.back();
                    }
                );
            })
            .catch(()=>{
                // noop
            });
        }

    }
};

