import html from './project-documents.html';
import lang from './project-documents.lang.json';
import * as mixins from '../../../../client/globals/mixins.js';
import * as directives from '../../../../client/globals/directives.js';


export default {
    template: html,

    mixins: [
        directives.label_required,
        mixins.storeAccess,
        mixins.storeGetters(['project','project_proposal', 'project_documents', '$v']),
    ],

    props: {
        readonly:Boolean,
    },

    i18n: {
        messages: lang
    },

    methods: {
        proposal_update(doc) {
            this.store_commit('upload_proposal', doc);
        },

        documents_update(docs) {
            console.log('upload_documents', docs);
            this.store_commit('upload_documents', docs);
        },
    },

};
