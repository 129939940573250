import html from './publications-list-mgr.html';
import lang from './publications-list-mgr.lang.json';
import server from '../../../client/asis-server.js';
import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate, mixins.hasRole],

    data: function() {
        var $t = this.$t.bind(this);
        var currentYear = new Date().getFullYear(); 
        return {
            harvest_year: currentYear-1,
            harvest_years: [],
            statistics: null,
            publications: [],
            publications_fields: [
                {key: 'publication_id', label:$t('list.publication_id'), sortable:true},
                {key: 'type', label:$t('list.type'), sortable:true},
                {key: 'year', label:$t('list.year'), sortable:true},
                {key: 'details.authors_joined', label:$t('list.authors'), formatter:'authors', sortable:true},
                {key: 'details.title', label:$t('list.title')},
                {key: 'actions', label:''},
            ],
            loading: false,
        };
    },

    methods: {
        load() {
            this.loading = true;
            server.request(
                'publications-mgr', 'publications-list', {harvest_year:this.harvest_year}, null,
                (data, extra)=>{
                    this.publications = data;
                    this.harvest_years = extra.harvest_years;
                    this.statistics    = extra.statistics;
                    this.loading = false;
                }
            );
        },

        authors(value, prop, row) {
            var authors = value.split(';');
            return (authors.length < 4)
                ? authors.join(', ')
                : authors[0] + ' et al.';
        },

        export_link() {
            return server.link(
                'publications-mgr', 'publications-export', {harvest_year:this.harvest_year}
            );
        },
    },

    watch: {
        // reload publication list on harvest year change
        harvest_year(newV, oldV) {
            this.load();
        }
    }
};

