import html from './documents-edit.html';
import lang from './documents-edit.lang.json';
import server from '../../../client/asis-server.js';
import * as directives from '../../../client/globals/directives.js';
import * as translators from '../../../client/globals/translators.js';
import * as validators from '../../../client/globals/validators.js';
import * as mixins from '../../../client/globals/mixins.js';
//import document_info from '../components/document-info.js';

export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate, directives.label_required, validators.vuelidate],

    // components: {
    //     'revision-list': document_info  // not required
    // },

    data: function() {
        return {
            document: {},               // original server data
            documents: [],              // document metadata
            new_revision: null,
            submit_progress: null,
            submit_error: null,
        };
    },

    validations() {
        // validation rules for document object fields
        //return {
            var validation = {};
            validation.document = {
                title_cz: {
                    required: validators.required
                },
                title_en: {
                    required: validators.required
                },
            };
    
            if (this.new_revision)
            validation.new_revision = {
                date: {
                    required: validators.required,
                    asis_date: validators.asis_date
                },
                doc_cz: {
                    required: validators.required,
                    file: validators.file,
                },
                pdf_cz: {
                    required: validators.required,
                    file: validators.file,
                },
                doc_en: {
                    file: validators.file,
                },
                pdf_en: {
                    required: validators.requiredIf('doc_en'),
                    file: validators.file,
                },
                attachments_cz: {
                    minLength: validators.minLength(0),
                    $each: {
                        doc: {
                            required: validators.required,
                            file: validators.file,
                        },
                        pdf: {
                            file: validators.file,
                        },
                        description: {
                            required: validators.required,
                        },
                    }
                },
                attachments_en: {
                    minLength: validators.minLength(0),
                    $each: {
                        doc: {
                            required: validators.required,
                            file: validators.file,
                        },
                        pdf: {
                            file: validators.file,
                        },
                        description: {
                            required: validators.required,
                        },
                    }
                },
            };
            return validation;
        },


    methods: {
        load() {
            server.request(
                'council-mgr', 'documents-get',
                {document_id: this.$route.params.document_id||0},
                null,
                (data, extra, schema)=>{
                    this.document = data;
                    this.documents = extra.documents;
                    // fill uploads for a newly added documents
                    // if (this.meeting.new_meeting) this.uploads = {
                    //     minutes_doc: null,
                    //     minutes_pdf: null,
                    //     resolutions_doc: null,
                    //     resolutions_pdf: null,
                    // }
                }
            );
            // automatically add new revision in adding mode
            if (!this.$route.params.document_id) this.new_revision_add();
        },

        submit(event) {
            // check if the form validates
            if (this.$v.$invalid) return;

            this.submit_progress = 0;
            this.submit_error = null;
            server.request(
                'council-mgr', 'documents-save', 
                //this.document, {new_revision:this.new_revision},
                Object.assign({}, this.document, {new_revision:this.new_revision}), {},
                (result)=>{
                    this.$router.back();
                },
                (error)=>{
                    this.submit_error = error;      // show error message
                    this.submit_progress = 0;       // hide upload progress bar
                },
                {onProgress:(perc)=>{
                    this.submit_progress=perc
                }, timeout:600000}
            );
        },

        new_revision_add() {
            // add new revision
            this.new_revision = {
                date: null,
                doc_cz: null,
                pdf_cz: null,
                doc_en: null,
                pdf_en: null,
                attachments_cz: [],
                attachments_en: [],
            };
        },

        attachment_cz_add() {
            // add cz attachment
            this.new_revision.attachments_cz.push({
                attachment_id: this.new_revision.attachments_cz.length,
                doc: null,
                pdf: null,
                description: ''
            });
        },

        attachment_cz_del() {
            // remove last cz attachment
            this.new_revision.attachments_cz.pop();
        },

        attachment_en_add() {
            // add en attachmant
            this.new_revision.attachments_en.push({
                attachment_id: this.new_revision.attachments_en.length,
                doc: null,
                pdf: null,
                description: ''
            });
        },

        attachment_en_del() {
            // remove last en attachment
            this.new_revision.attachments_en.pop();
        },

        // translators:
        date_asis2ddmmyyyy: translators.date_asis2ddmmyyyy,
        date_timestamp2ddmmyyyy: translators.date_timestamp2ddmmyyyy,
        filesize_auto: translators.filesize_auto,
    }
};


