import html from './employees-edit.html';
import lang from './employees-edit.lang.json';
import server from '../../../client/asis-server.js';
import * as directives from '../../../client/globals/directives.js';
import * as validators from '../../../client/globals/validators.js';
import * as mixins from '../../../client/globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate, directives.label_required, validators.vuelidate],

    data: function() {
        return {
            employee: {},                       // employee data
            new_employee: false,
            homeoffice_contracts: [],
            submit_progress: 0,
            submit_error: null,                 // soft error
        };
    },

    validations() {
        // validation rules for employee object fields
        // (due to reference to this.employee object it has to be declared as a function)
        return {
            employee: {
                pid: {
                    required: validators.required,
                    personalNumber: validators.personalNumber
                },
                first_name: {
                    required: validators.required
                },
                last_name: {
                    required: validators.required
                },
                birth_year: {
                    required: validators.required,
                    year: validators.year
                },
                department: {
                    required: validators.required,
                    minLength: validators.minLength(3)
                },
                worksite: {
                    required: validators.required
                },
                class: {
                    required: validators.required
                },
                contract_type: {
                    required: validators.required
                },
                contract_from: {
                    required: validators.required,
                    date: validators.asis_date
                },
                contract_until: {
                    date: validators.asis_date,
                    dateAfter: validators.dateAfter('contract_from')
                },
                homeoffice_from: {
                    date: validators.asis_date
                },
                homeoffice_until: {
                    date: validators.asis_date,
                    dateAfter: validators.dateAfter('homeoffice_from'),
                },
            }
        };
    },

    methods: {
        load() {
            server.request(
                this.$parent.module_name, 'employees-get',
                {pid: this.$route.params.pid||0}, null,
                (data, extra) => {
                    this.employee = data;
                    this.new_employee = extra.new_employee;
                    this.homeoffice_contracts = extra.homeoffice_contracts;
                }
            );
        },

        submit() {
            // check if the form validates
            if (this.$v.$invalid) return;

            this.submit_error = null;
            server.request(
                this.$parent.module_name, 'employees-save', this.employee, {new_employee:this.new_employee}, 
                (data) => {
                    // if ok, go to previous view
                    this.$router.back();
                }, 
                (error) => {
                    this.submit_error = error;
                }
            );
            return false;
        }
    }
};

