import html from './breakdowns-meals.html';
import lang from './breakdowns-meals.lang.json';
import * as mixins from '../../../client/globals/mixins.js';
import server from '../../../client/asis-server.js';
import moment from 'moment';

const regex_route_date = /^\d{6}$/;

export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [],

    data: function() {
        var $t = this.$t.bind(this);
        return {
            date: moment(),
            unit_costs: {},
            breakdown: [],
            totals: {},
            loading: false,
        };
    },

    // react to route changes
    beforeRouteUpdate (to, from, next) {
        if (regex_route_date.test(to.params.date)) {
            this.month_set(to.params.date);
            next();
        } else {
            next(moment().format('YYYYMM'));
        }
    },


    created: function() {
        this.month_set(this.$route.params.date);
    },


    methods: {
        month_set(date) {
            // validate date paramenter
            if (!regex_route_date.test(date))
                date = moment();
            else 
                date = moment(date+'01', 'YYYYMMDD', true);
            if (!date || !date.isValid()) date = moment();

            // set date and refresh data
            // note: child components that are binded to this.data will refresh automaticly when date changes
            this.date = date;
            this.load();
        },

        load() {
            this.loading = true;
            server.request(
                'timesheets-usr', 'breakdown-meals', {year:this.date.year(), month:this.date.month()+1}, null,
                (data, extra) => {
                    this.unit_costs = data.unit_costs;
                    this.breakdown = data.breakdown;
                    this.totals = data.totals;
                }
            ).then(()=>{
                this.loading = false;
            });
        },

    },

};
