import html from './news-pub.html';
import lang from './news-pub.lang.json';
import server from '../../../client/asis-server.js';
import * as mixins from '../../../client/globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate],

    data: function() {
        return {
            news: null,
        };
    },


    mounted() {
        this.$i18n.locale = this.$route.params.lang || 'cz';
    },


    methods: {
        load() {
            server.request(
                'intranet-pub', 'news-get', this.$route.params, null,
                (data, extra)=>{
                    this.news = data;
                }
            );
        },

        switch_language() {
            this.$i18n.locale = (this.$i18n.locale == 'cz') ? 'en' : 'cz';            
        }
    },

};
