import Vue from 'vue';
import server from '../../../client/asis-server.js';
import * as validators from '../../../client/globals/validators.js';


export default function (store, namespace, module) {
    const store_module = {
        namespaced: true,

        state: () => ({
            projects: [],
            filter_status: null,
            loading: false,
        }),


        getters: {
            projects: state => state.projects,
            filter_status: state => state.filter_status,
            loading: state => state.loading,
        },


        mutations: {
            reset(state) {
                state.projects = [];
                state.filter = default_filter;
                state.loading = false;
                
            },

            projects(state, data) {
                state.projects = data;
            },

            filter_status(state, filter_status) {
                state.filter_status = filter_status;
            },

            loading(state, loading) {
                state.loading = loading;
            },
        },


        actions: {
            load(context) {
                context.commit('loading', true);
                return server.request(
                    module, 'projects-list', {filter:context.state.filter}, null,
                    (data, extra) => {
                        context.commit('projects', data);
                    }
                ).then(()=>{
                    context.commit('loading', false);
                });
            },

            delete(context, project_id) {
                context.commit('loading', true);
                return server.request(
                    module, 'projects-delete', {project_id}, null,
                ).then(()=>{
                    context.dispatch('load');
                });
            },

        }    
    };

    return store_module;
};

