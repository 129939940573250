import Vue from 'vue';
import server from '../../../client/asis-server.js';
import * as validators from '../../../client/globals/validators.js';




export default function (store, namespace, module) {
    const validator = new Vue({
        mixins: [
            validators.vuelidate
        ],
        computed: {
            // project() {
            //     return store.getters[namespace + '/project'];
            // }
        },
        validations() {
            return {
                // project: {
                // },
            };
        },
    });

    const getInitialState = () => ({
        pid: store.getters['asis/auth/user'].pid,
        year: (new Date()).getYear(),
        month: (new Date()).getMonth()+1,
        contracts: [],
        // project: null,
        // project_proposal: null,
        // project_documents: [],
        // uploads_proposal: null,
        // uploads_documents: [],
        loading: false,
        saving: false,
        // save_progress: 0,
        // save_errors: [],
    });


    const store_module = {
        namespaced: true,

        state: getInitialState(),

        getters: {
            pid: state => state.pid,
            year: state => state.year,
            month: state => state.month,
            contracts: state => state.contracts,
            contract: state => contract_id => state.contracts.find(contract => contract.contract_id == contract_id),
            // project: state => state.project,
            // project_dates: state => (state.project ? state.project.start + '-' + state.project.end : null),
            // project_proposal: state => state.project_proposal,
            // project_documents: state => state.project_documents,
            loading: state => state.loading,
            saving: state => state.saving,
            // save_progress: state => state.save_progress,
            // save_errors: state => state.save_errors,
            // $v: state => Object.assign({}, validator.$v),    // this is not to expose API of $v
            // missing_budget: state => validator.$v.project.budget_total.$invalid || validator.$v.project.budget.$invalid || (state.project.budget_total <= 0),
            // missing_proposal: state => !state.project.proposal,
            // missing_abstract: state => (state.project.abstract_cz.length < 100) || (state.project.abstract_en.length < 100),
            // missing_approval_financial: state => !state.approval_financial,
            // missing_approval_council: state => !state.approval_council,
        },


        mutations: {
            stateReset(state) {
                // Merge rather than replace so we don't lose observers
                // https://github.com/vuejs/vuex/issues/1118
                Object.assign(state, getInitialState());
            },

            pid(state, pid) {
                //console.log('pid mutation', pid);
                state.pid = pid;
            },

            date(state, {year, month}) {
                state.year = year;
                state.month = month;
            },

            contracts(state, contracts) {
                state.contracts = contracts;
            },

            // project(state, data) {
            //     state.project = data.project;
            //     state.project_proposal = data.proposal;
            //     state.project_documents = data.documents || [];
            // },

            // project_dates_update(state) 
            // // update budget years according to the project duration
            // {
            //     var new_budget_data = {};
            //     var budget_defaults = {total:0, personal:0, investments:0, indirect:0, cofinancing:0};
            //     var budget_total = 0;
            //     var current_budget = ((typeof state.project.budget === 'object') && (!Array.isArray(state.project.budget)) &&
            //         (state.project.budget !== null)) ? state.project.budget : {};
            //     // get the first and last year of the project
            //     var year_min = new Date(state.project.start).getFullYear();
            //     var year_max = new Date(state.project.end).getFullYear();
            //     // push each year data into the new budget object
            //     for (let year=year_min; year<=year_max; year++) {
            //         var year_key = year.toString();
            //         var obj = current_budget[year_key] || {};
            //         new_budget_data[year_key] = Object.assign({}, budget_defaults, obj);
            //         budget_total += obj.total || 0;
            //     }
            //     // assign the new budget object
            //     state.project.budget = new_budget_data;
            // },

            // project_budget(state, data) 
            // // change a single budget field: store the provided value & update other fields
            // // - data is supposed to contain: {year, field, value} (can also be null)
            // {
            //     // update budget if data is submitted
            //     if (data && state.project.budget[data.year]) {
            //         state.project.budget[data.year][data.field] = data.value;
            //     }

            //     // recalculate total budget if there is only a single beneficiary
            //     if (state.project.cobeneficiaries.length == 0) {
            //         var budget_total = 0;
            //         for (let year in state.project.budget) budget_total += parseFloat(state.project.budget[year].total) || 0;
            //         state.project.budget_total = budget_total;
            //     }
            // },

            // upload_proposal(state, doc) {
            //     state.uploads_proposal = {
            //         file: doc.file,
            //         action: state.project.proposal ? 'replace' : 'add',
            //         document_id: state.project.proposal,
            //     };
            //     console.log('state.uploads_proposal',state.uploads_proposal);
            // },

            // loading(state, loading) {
            //     state.loading = loading;
            // },

            // saving(state, saving) {
            //     state.saving = saving;
            //     state.save_progress = 0;                // reset progress on any saving state change
            //     if (saving) state.save_errors = [];     // reset error on start saving
            // },

            // save_progress(state, progress) {
            //     state.save_progress = progress;
            // },

            // save_errors(state, errors) {
            //     state.save_errors = errors;
            // },
        },


        actions: {
            load_project_contracts(context) {
                return server.request(
                    module, 'projectsheets-get', 
                    {pid:context.state.pid, year:context.state.year, month:context.state.month}, null,
                    (data, extra)=>{
                        context.commit('contracts', data);
                    }
                );
            },

            save_project_contracts(context) {
                var contract_task_hours = Object.fromEntries(context.state.contracts.map(c => ([c.contract_id, c.task_hours])));
                return server.request(
                    module, 'projectsheets-save', 
                    {pid:context.state.pid, year:context.state.year, month:context.state.month,contract_task_hours}, null,
                    (data, extra)=>{
                        //context.commit('contracts', data);
                    }
                );
            },

        //     load(context, project_id) {
        //         context.commit('loading', true);
        //         return server.request(
        //             module, 'projects-get', {project_id}, null,
        //             (data, extra) => {
        //                 context.commit('project', {
        //                     project: data, 
        //                     proposal: extra.proposal, 
        //                     documents: extra.documents,
        //                 });
        //             }
        //         ).then(()=>{
        //             context.commit('loading', false);
        //         });
        //     },

        //     save(context) {
        //         if (validator.$v.$invalid) return;
        //         context.commit('saving', true);
        //         return server.request(
        //             module, 'projects-save', context.state.project, {
        //                 proposal: context.state.uploads_proposal,
        //             },
        //             (data, extra) => {
        //                 context.commit('project', {
        //                     project: data, 
        //                     proposal: extra.proposal, 
        //                     documents: extra.documents,
        //                 });
        //             },
        //             (errors)=>{
        //                 context.commit('save_errors', errors);
        //             },
        //             {onProgress:(percent)=>{
        //                 context.commit('save_progress', percent);
        //             }, timeout:600000},
        //         ).then(()=>{
        //             // when finished, reset uploads and reset saving flag
        //             context.state.uploads_proposal = null,
        //             context.state.uploads_documents = [],
        //             context.commit('saving', false);
        //         });
        //     }
        },
    };

    return store_module;
};
