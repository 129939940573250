'use strict';
// jshint esversion:6
// jshint -W069


import config from './council.config.json';
import view_main from './views/main.js';
import view_meetings_list from './views/meetings-list.js';
import view_meetings_edit from './views/meetings-edit.js';
import view_documents_list from './views/documents-list.js';
import view_documents_edit from './views/documents-edit.js';


const module_council_mgr = {
    module_name: 'council-mgr',
    module_class: 'mgr',
    title: config.mgr.title,
    icon: config.mgr.icon,
    routes: [
        // default route
        {path:'', component: view_main, redirect:{name:'meetings-list'}, children: [
            {name:'meetings-list',  path: 'meetings', component: view_meetings_list},
            {name:'documents-list',  path: 'documents', component: view_documents_list},
        ]},
        // meetings
        {name:'meetings-add',  path:'meetings/add', component:view_meetings_edit},
        {name:'meetings-edit', path:'meetings/edit/:meeting_id', component:view_meetings_edit},
        // documents
        {name:'documents-add',  path:'documents/add', component:view_documents_edit},
        {name:'documents-edit', path:'documents/edit/:document_id', component:view_documents_edit},
    ]
};


export default [module_council_mgr];


