import html from './meetings-edit.html';
import lang from './meetings-edit.lang.json';
import server from '../../../client/asis-server.js';
import * as directives from '../../../client/globals/directives.js';
import * as translators from '../../../client/globals/translators.js';
import * as validators from '../../../client/globals/validators.js';
import * as mixins from '../../../client/globals/mixins.js';
import document_info from '../components/document-info.js';

export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate, directives.label_required, validators.vuelidate],

    components: {
        'meeting-document': document_info
    },

    data: function() {
        return {
            meeting: {},                // original server data
            documents: [],              // document metadata
            uploads: {},                // documents to upload
            submit_progress: null,
            submit_error: null,
        };
    },

    validations() {
        // validation rules for directive object fields
        return {
            meeting: {
                meeting_id: {
                    required: validators.required,
                    integer: validators.integer,
                    minValue: validators.minValue(1),
                },
                date: {
                    required: validators.required,
                    date: validators.date,
                },
            },
            uploads: {
                minutes_doc: {
                    //TODO: temporarily do not require
                    // required: validators.requiredIf(()=>
                    //     this.meeting.new_meeting || !this.meeting.minutes_doc
                    // ),
                    file: validators.file(['doc','docx']),
                },
                minutes_pdf: {
                    required: validators.requiredIf(()=>
                        this.meeting.new_meeting || !this.meeting.minutes_pdf
                    ),
                    file: validators.file(['pdf']),
                },
                resolutions_doc: {
                    //TODO: temporarily do not require
                    // required: validators.requiredIf(()=>
                    //     this.meeting.new_meeting || !this.meeting.resolutions_doc
                    // ),
                    file: validators.file(['doc','docx']),
                },
                resolutions_pdf: {
                    required: validators.requiredIf(()=>
                        this.meeting.new_meeting || !this.meeting.resolutions_pdf
                    ),
                    file: validators.file(['pdf']),
                },
            }
        };
    },


    methods: {
        load() {
            server.request(
                'council-mgr', 'meetings-get',
                {meeting_id: this.$route.params.meeting_id||0},
                null,
                (data, extra, schema)=>{
                    this.meeting = data;
                    this.documents = extra.documents;
                    // fill uploads for a newly added meeting
                    if (this.meeting.new_meeting) this.uploads = {
                        minutes_doc: null,
                        minutes_pdf: null,
                        resolutions_doc: null,
                        resolutions_pdf: null,
                    }
                }
            );
        },

        submit(event) {
            // check if the form validates
            this.$v.$touch();
            if (this.$v.$invalid) return;

            this.submit_progress = 0;
            this.submit_error = null;
            server.request(
                'council-mgr', 'meetings-save',
                //this.meeting, {uploads:this.uploads},   // add `uploads` to a copy of `meeting`
                Object.assign({}, this.meeting, {uploads:this.uploads}), {},
                (result)=>{
                    this.$router.back();
                },
                (error)=>{
                    this.submit_error = error;      // show error message
                    this.submit_progress = 0;       // hide upload progress bar
                },
                {onProgress:(perc)=>{
                    this.submit_progress=perc
                }, timeout:600000}
            );
        },

        document_replace(id) {
            // create property in uploads reactively
            // (reactive equivalent of uploads[id]=null)
            this.$set(this.uploads, id, null);
        },

        // translators:
        date_asis2ddmmyyyy: translators.date_asis2ddmmyyyy,
        date_timestamp2ddmmyyyy: translators.date_timestamp2ddmmyyyy,
        filesize_auto: translators.filesize_auto,
    }
};
