import html from './report.html';
import lang from './report.lang.json';
import server from '../../../client/asis-server.js';

export default {
    template: html,

    i18n: {
        messages: lang,
    },

    data: function () {
        return {
            report: '',
        }
    },

    methods: {
        submit() {
            server.request(
                'system-usr', 'report-save', {report:this.report}, null, 
                (data) => {
                    this.$bvModal.msgBoxOk(this.$t('thanks'), {centered:true})
                    .then(v=>{
                        // if ok, go to previous view
                        this.$router.back();
                    })
                    .catch(e=>{});
                }
            );
            return false;
        }
    }


};


