'use strict';
// jshint esversion:6
// jshint -W069


import config from './seminars.config.json';
import view_seminars_list from './views/seminars-list.js';
import view_seminars_edit from './views/seminars-edit.js';
import view_seminars_view from './views/seminars-view.js';


const module_seminars_usr = {
    module_name: 'seminars-usr',
    module_class: 'usr',
    title: config.usr.title,
    icon: config.usr.icon,
    routes: [
        {path:'', redirect:{name:'seminars-list'}},
        {name:'seminars-list', path:'list', component:view_seminars_list},
        {name:'seminars-add',  path:'add', component:view_seminars_edit},
        {name:'seminars-edit', path:'edit/:seminar_id', component:view_seminars_edit},
    ]
};

const module_seminars_pub = {
    module_name: 'seminars',
    module_class: 'pub',
    routes: [
        // public routes
        {name:'seminars-view', path:'view/:seminar_id', component:view_seminars_view, meta:{noAuth:true}},
    ]
};

export default [module_seminars_usr, module_seminars_pub];

