import html from './overviews-shifts.html';
import lang from './overviews-shifts.lang.json';
import * as mixins from '../../../client/globals/mixins.js';
import * as translators from '../../../client/globals/translators.js';
import server from '../../../client/asis-server.js';
import month_select_mixin from './components/month-select.js';
import moment from 'moment';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [month_select_mixin],

    data: function() {
        var $t = this.$t.bind(this);
        return {
            fields_employee:[
                {key:'employee_pid', label:$t('employee_pid'), sortable:true},
                {key:'employee_name', label:$t('employee_name'), sortable:true, formatter:'format_employee_name', sortByFormatted:true},
                {key:'employee_department', label:$t('employee_department'), sortable:true, sortByFormatted:(a,b,c)=>this.sortDeptName(a,b,c)},
                //{key:'employee_worksite', label:$t('employee_worksite'), sortable:true},
                {key:'employee_duty', label:$t('employee_duty'), tdClass:'text-right'},
            ],
            fields_sums:[
                {key:'total_hours_worked', label:$t('total_hours_worked'), tdClass:'text-right'},
                {key:'total_days_annual_leave', label:$t('total_days_annual_leave'), tdClass:'text-right'},
                {key:'total_days_unpaid_leave', label:$t('total_days_unpaid_leave'), tdClass:'text-right'},
                {key:'total_days_paid_leave', label:$t('total_days_paid_leave'), tdClass:'text-right'},
                {key:'total_days_sick_leave', label:$t('total_days_sick_leave'), tdClass:'text-right'},
                {key:'total_days_homeoffice', label:$t('total_days_homeoffice'), tdClass:'text-right'},
                {key:'total_days_credited', label:$t('total_days_credited'), tdClass:'text-right'},
                {key:'lock', label:''},
            ],
            fields:[],
            overview: [],
            overview_totals: {},
            departments: [],
            all_locked: false,
            filter_department: '(none)',//null,
            print_url: null,
            loading: false,
            locking: false,
        };
    },

    methods: {
        load() {
            this.loading = true;
            server.request(
                'timesheets-mgr', 'overview-shifts', {year:this.date.year(), month:this.date.month()+1, department:this.filter_department}, null,
                (data, extra) => {
                    var fields_days = [];
                    for (let i=1; i<=extra.days_in_month; i++) {
                        let d = new Date(this.date.year(), this.date.month(), i)
                        fields_days.push({key:'day-'+i, label:i.toString().pad(2,"0"), class:(d.isHoliday()||d.isWeekend()?'table-secondary':''), tdClass:'text-center'});
                    }
                    this.fields = [].concat(this.fields_employee, fields_days, this.fields_sums);
                    this.overview = data;
                    this.overview_totals = extra.timesheet_totals;
                    this.all_locked = extra.all_locked;
                    this.print_url = extra.print_url;
                },
                null,
                {timeout: 30000}
            ).then(()=>{
                this.loading = false;
            });
        },

        format_day_shift_and_status(data) {
            if (data.status=='D/2') data.status = 'd';
            if (data.status == '-') {
                return data.homeoffice ? 'H' : (data.worked_hours ? data.worked_hours.toFixed(1) : '-');
            } else {
                if (data.worked_hours>0) 
                    return data.status + '+' + Math.ceil(data.worked_hours);
                else
                    return data.homeoffice ? 'H+'+data.status : data.status;
            }
        },

        format_employee_name(value, prop, row) {
            return `${row.employee_last_name}, ${row.employee_first_name}`;
        },

        getclass_total(data) {
            var diff = data.timesheet_summary.total_days_credited - data.timesheet_summary.total_days_pool;

            if (diff < -0.5) return 'text-danger';

            if (data.employee_class=='V') {
                if (diff>-0.5 && diff<+5.0) return 'text-success';
            } else {
                if (diff>-0.5 && diff<+0.5) return 'text-success';
            }
            return 'text-warning';
        },

        timesheets_lock(state, pid) 
        // lock/unlock all timesheets
        // state [boolean]: new state of timesheets
        // pid [int|null]: affected timesheet; null for all
        {
            this.locking = true;
            server.request(
                'timesheets-mgr', 'timesheets-lock', {
                    year:this.date.year(), 
                    month:this.date.month()+1, 
                    state:state, 
                    pid:pid||null, 
                    department:this.filter_department
                }, null,
                (data, extra) => {
                    if (pid) this.overview.findObject('employee_pid', pid).timesheet_locked = state;
                }
            ).then(()=>{
                this.locking = false;
                if (!pid) this.load();
            });
        },

        sortDeptName(value, prop, row) {
            return `${row.employee_department} ${row.employee_last_name} ${row.employee_first_name}`;
        },

        // translators:
        float: translators.float,
    },

    watch: {
        filter_department() {
            this.load();
        }
    }

};
