import * as validators from '../../../client/globals/validators.js';
import * as translators from '../../../client/globals/translators.js';
import server from '../../../client/asis-server.js';


const document_replacements_mixin = {
        data() {
        return {
            document_replacements: {},
        };
    },

    validations: {
        document_replacements: {
            doc: {
                file: validators.file,
            },
            pdf: {
                file: validators.file(['pdf']),
            },
        }
    },

    methods: {
        documents_replace() {
            // assign new properties reactively
            this.$set(this.document_replacements, 'doc', null);
            this.$set(this.document_replacements, 'pdf', null);
        },
    },

    watch: {
        document_replacements: {
            deep: true,
            handler() {
                this.$v.$touch();
                var replacements = {};
                if (this.doc) {
                    replacements[this.doc.document_id] = (!this.$v.document_replacements.doc.$invalid) ? this.document_replacements.doc : null;
                }
                if (this.pdf) {
                    replacements[this.pdf.document_id] = (!this.$v.document_replacements.pdf.$invalid) ? this.document_replacements.pdf : null;
                }
                this.$emit('document-replace', replacements);
            }        
        },
    },

};



const document_row = {
    mixins: [document_replacements_mixin],

    props: {
        doc: Object,
        pdf: Object,
    },

    template: `
        <b-tbody role="rowgroup" class="border-bottom">
            <b-tr>
                <b-td style="width:15%">{{ $t('document') }}</b-td>
                <b-td>
                    <b-link v-if="doc" :href="doc.url" class="text-info font-weight-bold">{{doc.name}}</b-link>
                    <span v-else class="text-secondary">{{ $t('no_doc') }}</span>
                </b-td>
                <b-td style="width:5%">
                    <b-link v-if="pdf" :href="pdf.url" class="text-info font-weight-bold">PDF</b-link>
                    <span v-else class="text-secondary">{{ $t('no_pdf') }}</span>
                </b-td>
                <b-td style="width:10%">{{doc ? date_timestamp2ddmmyyyy(doc.created) : ''}}</b-td>
                <b-td style="width:5%" class="text-nowrap">{{doc ? 'rev. '+doc.revision : ''}}</b-td>
                <b-td style="width:5%">{{doc  ? filesize_auto(doc.size) : ''}}</b-td>
                <b-td style="width: 1%">
                    <b-btn v-if="doc" @click.stop="documents_replace" :disabled="document_replacements.hasOwnProperty('doc')" size="sm" variant="warning" class="text-nowrap">{{$t('replace_document')}}</b-btn>
                </b-td>
            </b-tr>

            <b-tr v-if="document_replacements.hasOwnProperty('doc')" variant="warning">
                <td></td>
                <td colspan="6" class="py-2">
                    <b-form-file
                        v-if="doc"
                        id="docInput"
                        v-model="document_replacements.doc"
                        accept=".doc, .docx"
                        :state="$v.document_replacements.doc.$dirty ? !$v.document_replacements.doc.$error : null"
                        :placeholder="$t('attachment_doc_hint')"
                        class="d-block"
                    ></b-form-file>
                    <asis-validation-feedback :state="$v.document_replacements.doc"></asis-validation-feedback>
                    <b-form-file
                        v-if="pdf"
                        id="pdfInput"
                        v-model="document_replacements.pdf"
                        accept=".pdf"
                        :state="$v.document_replacements.pdf.$dirty ? !$v.document_replacements.pdf.$error : null"
                        :placeholder="$t('attachment_pdf_hint')"
                        class="d-block mt-2"
                    ></b-form-file>
                    <asis-validation-feedback :state="$v.document_replacements.pdf"></asis-validation-feedback>
                </td>
            </b-tr>
        </b-tbody>
    `,

    i18n: {
        messages: {
            cz: {
                document: "Hlavní dokument",
                replace_document: "Nahradit",
                attachment_doc_hint: "Vybrat původní soubor",
                attachment_pdf_hint: "Vybrat PDF verzi",
                no_doc: "(není nahrán)",
                no_pdf: "(není)",
            },
            en: {
                document: "Main document",
                replace_document: "Replace",
                attachment_doc_hint: "Choose the original file",
                attachment_pdf_hint: "Choose the PDF version",
                no_doc: "(not available)",
                no_pdf: "(n/a)",
            }
        }
    },

    methods: {
        // translators:
        date_asis2ddmmyyyy: translators.date_asis2ddmmyyyy,
        date_timestamp2ddmmyyyy: translators.date_timestamp2ddmmyyyy,
        filesize_auto: translators.filesize_auto,
    },

};




const attachment_row = {
    mixins: [document_replacements_mixin],

    props: {
        attachment_id: Number,
        attachment_index: Number,
        doc: Object,
        pdf: Object,
        description: String,
    },

    template: `
        <b-tbody role="rowgroup" class="border-bottom">
            <b-tr>
                <b-td style="width:15%">{{ $t('attachment') }} {{attachment_index+1}}</b-td>
                <b-td>
                    <span class="d-block">{{description}}</span>
                    <b-link v-if="doc" :href="doc.url" class="text-info font-weight-bold d-block">{{doc.name}}</b-link>
                    <span v-else class="text-secondary">{{ $t('no_doc') }}</span>
                </b-td>
                <b-td class="align-bottom" style="width:5%">
                    <b-link v-if="pdf" :href="pdf.url" class="text-info font-weight-bold">PDF</b-link>
                    <span v-else class="text-secondary">{{ $t('no_pdf') }}</span>
                </b-td>
                <b-td class="align-bottom" style="width:10%">{{date_timestamp2ddmmyyyy(doc.created)}}</b-td>
                <b-td class="align-bottom" style="width: 5%">rev.&nbsp;{{doc.revision}}</b-td>
                <b-td class="align-bottom" style="width: 5%">{{filesize_auto(doc.size)}}</b-td>
                <b-td class="align-bottom" style="width: 1%">
                    <b-btn @click.stop="documents_replace" :disabled="document_replacements.hasOwnProperty('doc')" size="sm" variant="warning" class="text-nowrap">{{$t('replace_document')}}</b-btn>
                </b-td>
            </b-tr>

            <b-tr v-if="document_replacements.hasOwnProperty('doc')" variant="warning">
                <td></td>
                <td colspan="6" class="py-2">
                    <b-form-file
                        v-if="doc"
                        id="docInput"
                        v-model="document_replacements.doc"
                        :state="$v.document_replacements.doc.$dirty ? !$v.document_replacements.doc.$error : null"
                        :placeholder="$t('attachment_doc_hint')"
                        class="d-block"
                    ></b-form-file>
                    <asis-validation-feedback :state="$v.document_replacements.doc"></asis-validation-feedback>
                    <b-form-file
                        v-if="pdf"
                        id="pdfInput"
                        v-model="document_replacements.pdf"
                        accept=".pdf"
                        :state="$v.document_replacements.pdf.$dirty ? !$v.document_replacements.pdf.$error : null"
                        :placeholder="$t('attachment_pdf_hint')"
                        class="d-block mt-2"
                    ></b-form-file>
                    <asis-validation-feedback :state="$v.document_replacements.pdf"></asis-validation-feedback>
                </td>
            </b-tr>
        </b-tbody>
    `,

    i18n: {
        messages: {
            cz: {
                attachment: "Příloha",
                replace_document: "Nahradit",
                attachment_doc_hint: "Vybrat původní soubor",
                attachment_pdf_hint: "Vybrat PDF verzi",
                no_doc: "(není nahrán)",
                no_pdf: "(není)",
            },
            en: {
                attachment: "Attachment",
                replace_document: "Replace",
                attachment_doc_hint: "Choose the original file",
                attachment_pdf_hint: "Choose the PDF version",
                no_doc: "(not available)",
                no_pdf: "(n/a)",
            }
        }
    },

    methods: {
        // translators:
        date_asis2ddmmyyyy: translators.date_asis2ddmmyyyy,
        date_timestamp2ddmmyyyy: translators.date_timestamp2ddmmyyyy,
        filesize_auto: translators.filesize_auto,
    },

};


export default {
    props: {
        docId: Number,              // document_id for the main original document
        pdfId: Number,              // document_id for the main PDF document
        attachments: {              // attachments (array of objects)
            type: Array,
            default: [],
        },
        documents: {                // array of document_id=>document pairs
            type: Object,
            default: {},
        },
    },

    data() {
        return {
            document_replacements: {},
        };
    },

    components: {
        'document-row': document_row,
        'attachment-row': attachment_row,
    },

    template: `
        <b-table-simple v-if="docId" responsive small borderless class="border-top">
            <!-- main document -->
            <document-row 
                :doc="documents[docId]" 
                :pdf="documents[pdfId]"
                @document-replace="document_replace"
            ></document-row>

            <!-- attachments -->
            <attachment-row 
                v-for="(att, index) in attachments" :key="att.attachment_id"
                :attachment_id="att.attachment_id"
                :attachment_index="index"
                :doc="documents[att.doc]" 
                :pdf="documents[att.pdf]"
                :description="att.description"
                @document-replace="document_replace"
            ></attachment-row>
        </b-table-simple>
        <p v-else class="text-secondary">{{$t('not_available')}}</p>
    `,

    i18n: {
        messages: {
            cz: {
                not_available: "(není k dispozici)",
            },
            en: {
                not_available: "(not available)",
            }
        }
    },

    methods: {
        document_replace(value) {
            // reemit to parent component
            this.$emit('document-replace', value);
        }
    }
    
};
