'use strict';
// jshint esversion:6
// jshint -W069

import config from './indexes.config.json';

// load components
import './components/class.js';
import './components/country.js';
import './components/department.js';
import './components/provider.js';
import './components/institution.js';
import './components/employee.js';
import './components/worksite.js';
import './components/country.js';


const module_indexes_usr = {
    module_name: 'indexes-usr',
    module_class: 'usr',
    title: config.usr.title,
    icon: config.usr.icon,
    //routes: [
    //    {path:'',       component:view_main}
    //]
};

const module_indexes_mgr = {
    module_name: 'indexes-mgr',
    module_class: 'mgr',
    title: config.mgr.title,
    icon: config.mgr.icon,
    //routes: [
    //    {path:'',       component:view_main}
    //]
};


export default [];

