import html from './student-card.html';
import lang from './student-card.lang.json';
import server from '../../../../client/asis-server.js';
//import * as translators from '../../../../client/globals/translators.js';
//import moment from 'moment';


export default {

    props: {
        student: Object,
    },

    template: html,

    i18n: {
        messages: lang
    },

    methods: {
    },

};

