//----------------------------------------------------------------------------
// ASIS Informational System
//----------------------------------------------------------------------------
// indexes/provider.js
// component for the list of funding providers
//----------------------------------------------------------------------------
// (c) Astronomický ústav AV ČR, v.v.i. (see licence file for legal info)
//----------------------------------------------------------------------------

'use strict';
// jshint esversion:6


import Vue from 'vue';
import jquery from 'jquery';
import server from '../../../client/asis-server.js';

// A general note on two-way data binding:
// The underlying component do not typicly  use v-model, but instead they use
// v-bind and v-on to handle proper 2-way propagation of
// the selected value. v-bind propagates the value from the parent
// to the child (e.g. to v-select) and v-on propagates changes of the value back to
// the parent using an event (in case of a component, $event is the actual value).
// The prop for the value MUST be named `value` for this to work.
// see: https://vuejs.org/v2/guide/components.html#Using-v-model-on-Components
// Example:
//      <v-select
//          v-bind:value="value"
//          v-on:input="$emit('input', $event)">
//      </v-select>



// department component
// a drop-down select for department
Vue.component('asis-provider', {
    template: `
        <v-select 
                v-model="provider"
                :options="providers" 
                :searchable="true" 
                :label="selectLabel"
                :disabled="readonly || disabled"
                :clearable="false">
        </v-select>
    `,

    props: ['value','state','readonly','disabled'],

    data: function() {
        return {
            // define a variable to store the list of worksites
            providers: [],    // list of departments
            provider: null,   // selected department (this is a member of this.departments - an object)
            selectLabel: 'provider_name'
        };
    },

    created: function() {
        // load the list of departments from server
        server.request(
            'indexes-pub', 'providers-list', {}, null,
            (data)=>{
                this.providers = data;
                // select a default
                this.provider = null;
                for (let p of this.providers) {
                    if (p['provider_id'] == this.value) {this.provider = p; break;}
                }
            }
        );
    },

    watch: {
        value(){
            // on value change (induced by the parent) find the corresponding item in the list of departments
            // and set the local `department` variable accordingly
            var new_value = null;
            for (var p of this.providers) {
                if (p['provider_id'] == this.value) {new_value = p; break;}
            }
            this.provider = new_value;
        },
        provider(value) {
            // on local value change (induced by v-select), notify the parent of the change,
            // but send back only the scalar value
            this.$emit('input', this.provider ? this.provider['provider_id'] : null);
        },
        state() {
            // on state change, set/clear the error class of the v-select
            //TODO:rewrite this using Vue
            if (this.state) jquery(this.$el).removeClass('is-invalid'); else jquery(this.$el).addClass('is-invalid');
        }
    }
});

