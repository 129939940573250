import html from './waterline.html';
import lang from './waterline.lang.json';
//import server from '../../../../client/asis-server.js';
//import * as translators from '../../../../client/globals/translators.js';
//import moment from 'moment';
import attribute_linetype from './attribute-linetype.js';
import attribute_photo from './attribute-photo.js';

export default {

    props: {
        object: Object,
        edit: Boolean,
    },

    template: html,

    // data() {
    //     return {
    //         object: null,
    //     };
    // },

    components: {
        'line-type': attribute_linetype,
        'photo': attribute_photo,
    },


    i18n: {
        messages: lang
    },

    methods: {
    },

};

