import html from './people-edit.html';
import lang from './people-edit.lang.json';
import server from '../../../client/asis-server.js';
import * as directives from '../../../client/globals/directives.js';
import * as validators from '../../../client/globals/validators.js';
import * as mixins from '../../../client/globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate, directives.label_required, validators.vuelidate],

    data: function() {
        return {
            personalinfo: {},                   // employee data
            submit_error: null                  // soft error
        };
    },

    validations: {
        personalinfo: {
            email: {
                email: validators.email,
            },
            room: {
                format: validators.regex(/^[A-Z]\d{1,3}$/),
            },
            phone1: {
                phonenumber: validators.phonenumber,
            },
            phone2: {
                phonenumber: validators.phonenumber,
            },
            mobile: {
                phonenumber_international: validators.phonenumber_international,
            },
        }
    },

    methods: {
        load() {
            server.request(
                'personalinfo-mgr', 'people-get',
                {pid: this.$route.params.pid}, null,
                (data)=>{this.personalinfo = data;}
            );
        },

        submit() {
            // check if the form validates
            if (this.$v.$invalid) return;

            this.submit_error = null;
            server.request(
                'personalinfo-mgr', 'people-save', this.personalinfo, null, 
                (data) => {
                    // if ok, go to previous view
                    this.$router.back();
                }, 
                (error) => {
                    this.submit_error = error;
                }
            );
            return false;
        }
    }
};

