import html from './referee-report.html';
import lang from './referee-report.lang.json';
import * as directives from '../../../../client/globals/directives.js';
import * as mixins from '../../../../client/globals/mixins.js';
import * as validators from '../../../../client/globals/validators.js';


export default {
    template: html,

    props: {
        data: Object,
        readonly: Boolean,
        v_state: Object,
    },

    mixins: [
        directives.label_required, 
        validators.vuelidate
    ],

    i18n: {
        messages: lang
    },

    data() {
        let $t = this.$t.bind(this);
        return {
            work_type_options: [
                {value: 'paper', text: $t('work_types.paper')},
                {value: 'project', text: $t('work_types.project')},
                {value: 'thesis-bsc', text: $t('work_types.thesis_bsc')},
                {value: 'thesis-msc', text: $t('work_types.thesis_msc')},
                {value: 'thesis-phd', text: $t('work_types.thesis_phd')},
                {value: 'thesis-hab', text: $t('work_types.thesis_hab')},
                {value: 'other', text: $t('work_types.other')},
            ]
        };
    },

    mounted() {
        // emit update event to initialize the validation state
        this.$emit('update:v_state', this.$v);
    },

    validations() {
        return {
            data: {
                date: {
                    required: validators.required,
                    asis_date: validators.asis_date,
                },
                refereed_for: {
                    required: validators.required,
                },
                work_type: {
                    required: validators.required,
                },
                work_title: {
                    required: validators.required,
                },
                work_author: {},
                comments: {},
            },
        };
    },

    watch: {
        '$v.$invalid': function() {
            // emit update event on each change of the validation state
            this.$emit('update:v_state', this.$v);
        },
    },    

};


