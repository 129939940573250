import html from './events-list.html';
import lang from './events-list.lang';
import store from './events-list-store.js';
import server from '../../../client/asis-server.js';
import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';

import fullcalendar from '@fullcalendar/vue';
import fullcalendar_daygrid from '@fullcalendar/daygrid';
import fullcalendar_interaction from '@fullcalendar/interaction';
import moment from 'moment';


export default {
    template: html,
    
    i18n: {
        messages: lang
    },

    mixins: [
        mixins.store_create(store, 'events-list'),
        mixins.storeAccess,
        mixins.storeGetters(['calendars','loading']),
        mixins.storeLoadOnCreate,
    ],

    components: {
        'full-calendar': fullcalendar
    },

    data: function() {
        var $t = this.$t.bind(this);
        return {
            fullcalendar_options: {
                plugins: [fullcalendar_daygrid, fullcalendar_interaction],
                aspectRatio: 2.0,
                contentHeight: 'auto',
                expandRows: true,
                windowResizeDelay: 500,
                initialView: 'dayGridMonth',
                customButtons: {
                    eventAdd: {
                        class: 'btn-success',
                        icon: 'plus-square',
                        click: () => { this.$router.push({name:'reservations-add', params:{cal_id:this.calendar_id}}); }
                    }
                },
                headerToolbar: {
                    start: 'title',
                    center: '',
                    end: 'eventAdd today prev,next'
                },
                buttonText: {
                    today: $t('today'),
                },
                themeSystem: 'bootstrap',
                titleFormat: {year:'numeric', month:'long'},
                dayHeaderFormat: {weekday: 'short'},
                locale: this.$i18n.locale,
                firstDay: 1, //1=monday
                weekends: true,
                fixedWeekCount: true,
                //navLinks: false,
                selectable: true,
                selectMirror: true,
                events: this.calendar_events,
                eventDataTransform: this.calendar_events_transform,
                eventTextColor: 'black',
                eventBorderColor: '#ff8b9a',
                eventDisplay: 'block',
                //eventTimeFormat: {hour:'2-digit', minute:'2-digit', meridiem:false},
                //displayEventTime: true,
                //displayEventEnd: true,
                eventContent: this.calendar_events_render,
                // editable: false,
                // weekMode: 'fixed',
                // allDayDefault: false,
                eventClick: this.calendar_click,
                dateClick: this.calendar_click,

            },
        };
    },

    methods: {
        calendar_set(cal_id) {
            this.calendar_id = cal_id;
        },

        calendar_events(fetchInfo, success, failure) {
            if (!this.calendar_id) {
                success([]);
                return;
            }
            return server.request(
                this.$parent.module_name, 'events-list', 
                {
                    cal_id: this.calendar_id,
                    start: translators.date_date2asis(fetchInfo.start),
                    end: translators.date_date2asis(fetchInfo.end),
                }
            );
        },

        calendar_events_transform(eventData) {
            // transform asis events to fullcalendar Event objects
            return {
                title: eventData.title,
                start: new Date(eventData.start),
                end: new Date(eventData.end),
                editable: false,
                backgroundColor: eventData.past_event ? '#ddd' : (eventData.can_edit ? '#dcf582' : '#ffadbc'),
                borderColor: eventData.past_event ? '#aaa' : (eventData.can_edit ? '#c2e161' : '#ff8b9a'),
                classNames: eventData.can_edit ? ['clickable'] : [],
                owner: eventData.owner,
                owner_lastname: eventData.owner_lastname,
                comment: eventData.comment,
                can_edit: eventData.can_edit,
                extendedProps: eventData,
            };
        },

        calendar_events_render(info) {
            var e = info.event;
            var c = this.calendars.findObject('cal_id', this.calendar_id);
            var eventDateTimeText;
            var event_dt1 = moment(e.start);
            var event_dt2 = e.end ? moment(e.end) : null;
            if (c.options.allday) {
                eventDateTimeText = ((!event_dt2) || event_dt1.isSame(event_dt2, 'day')) ? event_dt1.format('D.M.') : event_dt1.format('D.M.')+" &ndash; "+event_dt2.format('D.M.');
            } else {
                var eventDateTimeFormat = event_dt1.isSame(event_dt2, 'day') ? 'H:mm' : 'D.M. H:mm';
                eventDateTimeText = event_dt1.format(eventDateTimeFormat)+" &ndash; "+event_dt2.format(eventDateTimeFormat);
            }
            var html = '';
            html = '<div><strong>%DATETIME%</strong></div><div><span class="fc-event-owner">%OWNER%</span>: %TITLE%</div>';
            html = html.replace('%DATETIME%', eventDateTimeText);
            html = html.replace('%OWNER%', e.extendedProps.owner_lastname);
            html = html.replace('%TITLE%', e.title);
            if (e.extendedProps.comment) html = html + '<div class="fc-event-comment">('+e.extendedProps.comment+')</div>';
            return {html: html};
        },

        calendar_click(info) {
            if (info.event) {
                // event click
                if (info.event.extendedProps.can_edit) {
                    this.$router.push({name:'reservations-edit', params:{cal_id:this.calendar_id, event_id:info.event.extendedProps.event_id}});
                }
            } else {
                // day click
                var date = moment(info.date);
                if (!date.isBefore(moment(), 'day')) {
                    this.$router.push({name:'reservations-add', params:{cal_id:this.calendar_id, clicked_date:info.date}});
                }
            }
        },
    },

    computed: {
        calendar_id: {
            get () {
                return this.store_getter('calendar_id');
            },
            set (value) {
                this.store_commit('calendar_id', value);
            }
        }
    },

    watch: {
        calendar_id: function() {
            this.$refs.fullCalendar.getApi().refetchEvents();
        }
    }


};
