import html from './main-mgr.html';
import lang from './main-mgr.lang.json';


export default {
    template: html,

    i18n: {
        messages: lang
    },
};

