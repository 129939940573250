import html from './longterm-stay.html';
import lang from './longterm-stay.lang.json';
import * as directives from '../../../../client/globals/directives.js';
import * as mixins from '../../../../client/globals/mixins.js';
import * as validators from '../../../../client/globals/validators.js';


export default {
    template: html,

    props: {
        data: Object,
        readonly: Boolean,
        v_state: Object,
    },

    mixins: [
        directives.label_required, 
        validators.vuelidate
    ],

    i18n: {
        messages: lang
    },

    mounted() {
        // emit update event to initialize the validation state
        this.$emit('update:v_state', this.$v);
    },

    validations() {
        return {
            data: {
                date_from: {
                    required: validators.required,
                    asis_date: validators.asis_date,
                },
                date_until: {
                    required: validators.required,
                    asis_date: validators.asis_date,
                },
                place: {
                    required: validators.required,
                },
                country: {
                    required: validators.required,
                },
                funding: {
                    required: validators.required,
                },
                purpose: {
                    required: validators.required,
                },
                comments: {},
            },
        };
    },

    watch: {
        '$v.$invalid': function() {
            // emit update event on each change of the validation state
            this.$emit('update:v_state', this.$v);
        },
    },    

};


