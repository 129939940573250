'use strict';
// jshint esversion:6
// jshint -W069


import config from './canteen.config.json';
import view_mgr_main from './views/main-mgr.js';
import view_menus_list_usr from './views/menus-list-usr.js';
import view_menus_list_mgr from './views/menus-list-mgr.js';
import view_menus_list_pub from './views/menus-list-pub.js';
import view_menus_overview_usr from './views/menus-overview-usr.js';
//import view_menus_overview_mgr from './views/menus-overview-mgr.js';
import view_menus_edit from './views/menus-edit.js';
import view_reservations from './views/reservations-mgr.js';


const module_canteen_usr = {
    module_name: 'canteen-usr',
    module_class: 'usr',
    title: config.usr.title,
    icon: config.usr.icon,
    routes: [
        // default route
        {path:'', redirect:{name:'canteen-usr-menus-list', params:{day:'current'}}},
        // specific routes
        {name:'canteen-usr-menus-list', path:'list/:day', component:view_menus_list_usr},
        {name:'canteen-usr-menus-overview', path:'overview', component:view_menus_overview_usr},
    ]
};

const module_canteen_mgr = {
    module_name: 'canteen-mgr',
    module_class: 'mgr',
    title: config.mgr.title,
    icon: config.mgr.icon,
    routes: [
        // default route
        {path:'', component: view_mgr_main, redirect:{name:'canteen-mgr-menus-list', params:{day:'current'}}, children: [
            {name:'canteen-mgr-menus-list', path:'list/:day', component:view_menus_list_mgr},
            {name:'canteen-mgr-reservations', path:'reservations', component:view_reservations},
        ]},
        // specific routes
        {name:'canteen-mgr-menus-edit', path:'edit/:menu_id', component:view_menus_edit},
    ]
};


const module_canteen_pub = {
    module_name: 'canteen',
    module_class: 'pub',
    routes: [
        // public routes
        {name:'canteen-pub-menu', path:'menu', component:view_menus_list_pub, meta:{noAuth:true}},
    ]
};


export default [module_canteen_usr, module_canteen_mgr, module_canteen_pub];


