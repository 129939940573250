import html from './activities-list.html';
import lang from './activities-list.lang.json';
import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';
import store from './activities-list-store.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.loadDataOnCreate, 
        mixins.store_create(store, 'activities-list'), 
        mixins.storeGetters(['activities','categories','loading']), 
        mixins.storeAccess, 
    ],

    data: function() {
        var $t = this.$t.bind(this);
        return {
            // definition of table fields with variable items
            // (https://stackoverflow.com/questions/44908159/how-to-define-an-array-with-conditional-elements)
            activities_fields: [
                {key:'display_date', label:$t('list.date'), class:'min'},
                {key:'display_title', label:$t('list.title')},
                {key:'display_aux1', label:''},
                {key:'display_aux2', label:''},
                {key:'documents', label:'', class:'min'},
                {key:'actions', label:'', class:'min'},
            ],
        };
    },

    methods: {
        load() {
            this.store_dispatch('load');
        }
    },

    computed: {
        filter_categories() {
            // add 'all' item as the first category and map the list to {value:, text:} pairs
            return (['all'].concat(this.categories||[])).map(i => ({value:i, text:this.$t('categories.'+i).toLowerCase()}));
        },
        filter: {
            get() {
                return this.store_getter('filter');
            },
            set(value) {
                this.store_commit('filter', value);
            },
        }        
    }

};


