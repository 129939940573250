// linter directives
// jshint esversion:6

// import all modules of the application
import module_activities from '../modules/activities/activities.js';
import module_canteen from '../modules/canteen/canteen.js';
import module_carlog from '../modules/carlog/carlog.js';
import module_council from '../modules/council/council.js';
import module_directives from '../modules/directives/directives.js';
import module_employees from '../modules/employees/employees.js';
import module_events from '../modules/events/events.js';
import module_indexes from '../modules/indexes/indexes.js';
import module_intranet from '../modules/intranet/intranet.js';
import module_maps from '../modules/maps/maps.js';
//import module_faults from '../modules/faults/faults.js';
import module_personalinfo from '../modules/personalinfo/personalinfo.js';
import module_publications from '../modules/publications/publications.js';
import module_projects from '../modules/projects/projects.js';
import module_reservations from '../modules/reservations/reservations.js';
import module_seminars from '../modules/seminars/seminars.js';
import module_students from '../modules/students/students.js';
import module_timesheets from '../modules/timesheets/timesheets.js';
import module_test from '../modules/test/test.js';
import module_system from '../modules/system/system.js';

import module_component from './ui/components/module.js';


//! @class
class ASIS_Modules {
    constructor() {
        this.modules = [].concat(
            module_activities,
            module_canteen,
            module_carlog,
            module_council,
            module_directives,
            module_employees,
            module_events,
            module_indexes,
            module_intranet,
            module_maps,
            //module_faults,
            module_personalinfo,
            module_projects,
            module_publications,
            module_reservations,
            module_seminars,
            module_students,
            module_system,
            module_timesheets,
            module_test
        );

        this.routes = this.modules.map(m => ({
            path: '/'+m.module_name,
            component: module_component,
            props: {
                module_name: m.module_name,
                module_class: m.module_class,
                module_basename: m.module_name.includes('-') ? m.module_name.substr(0, m.module_name.length-4) : m.module_name,
                title: m.title,
                icon: m.icon,
            },
            children: m.routes
        }));

        this.stores = {};
        this.modules.forEach(m => {
            //let namespace = m.module_name.includes('-') ? m.module_name.substr(0, m.module_name.length-4) : m.module_name;
            let namespace = m.module_name.includes('-') ? m.module_name : m.module_name+'-pub';
            this.stores[namespace] = {
                namespaced: true,
                state: {},
            };
        });
    }
}


// create and export ASIS_Server class instance
// (one instance per application)
export default new ASIS_Modules();

