import html from './refpoint.html';
import lang from './refpoint.lang.json';
import server from '../../../../client/asis-server.js';
//import * as translators from '../../../../client/globals/translators.js';
//import moment from 'moment';
import attribute_photo from './attribute-photo.js';

export default {

    props: {
        object: Object,
        edit: Boolean,
    },

    template: html,

    // data() {
    //     return {
    //         object: null,
    //     };
    // },

    components: {
        'photos': attribute_photo,
    },


    i18n: {
        messages: lang
    },

    methods: {
        attribute_add_photo() {
            server.request(
                'maps-usr', 'objects-attributes-add',
                {object_id:this.object.object_id, attribute_type:'photos'}, null,
                (data, extra)=> {
                    this.object.attributes.push(data);
                }
            );
        },
    },

};

