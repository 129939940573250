import { mapGetters } from 'vuex';


export const state_validation = {
    methods: {
        state_validation(value) {
            return value.$dirty ? !value.$error : null;
        },
    },
};


export const loadDataOnCreate = {
    created: function() {
        this.load();
    },

    methods: {
        load() {}
    }
};

export const loadDataWhenReady = {
    mounted: function() {
        this.$nextTick(function() {
            this.load();
        });
    },

    methods: {
        load() {}
    }
};

export const asis = {
    data() {
        return {asis: window.asis};
    }
}

export const module_name = {
    data() {
        var t = this;
        return {
            module_basename: function() {
                let parent = t.$parent;
                while (!parent.module_basename) parent = parent.$parent;
                return parent.module_basename;
            }(),
            module_name: function() {
                let parent = t.$parent;
                while (!parent.module_name) parent = parent.$parent;
                return parent.module_name;
            }(),
            module_class: function() {
                let parent = t.$parent;
                while (!parent.module_class) parent = parent.$parent;
                return parent.module_class;
            }(),
        };
    }
}

export const reloadOnRouteUpdate = {
    beforeRouteUpdate(to, from, next) {
        // react to params change
        // (https://router.vuejs.org/guide/essentials/dynamic-matching.html#reacting-to-params-changes)
        if (this.load) this.load();
        next();
    }
};

export const hasRole = {
    methods: {
        hasRole(roles) {
            var user = this.$store.getters['asis/auth/user'];
            if (typeof roles === 'string') roles = roles.split(',');
            if (!Array.isArray(roles)) throw 'roles is not an array';
            return ((roles.filter(v => -1 !== user.roles.indexOf(v))).length > 0);
        }
    }
}

// // dynamic registration of Vuex store for a component
// // Usage:
// // mixins: [store(store)]
// namespace: full namespace, typicaly in the form 'module/view'
export const store_create = function(storeModule, namespace) {
    return {
        beforeCreate(){
            //console.log('==store_create==',storeModule, namespace);
            let namespace_expanded, module_name;
            if (namespace.startsWith('/')) {
                namespace_expanded = namespace.split('/').slice(1);
            } else {
                namespace_expanded = namespace.split('/');
                //console.log('namespace_expanded',namespace_expanded);
                // find `module_name` of the current module
                module_name = function($this) {
                    let parent = $this.$parent;
                    while (parent && !parent.module_name) parent = parent.$parent;
                    let module_name = parent ? parent.module_name : null;
                    if (module_name && !module_name.includes('-')) module_name = module_name + '-pub';
                    return module_name;
                }(this);
                //console.log('module_name',module_name);
                if (!module_name) throw new Error('relative store namespace used outside module - '+namespace);
                namespace_expanded.unshift(module_name);
                //console.log('namespace_expanded2',namespace_expanded,module_name);
            }
            //console.log('namespace defined', namespace, namespace_expanded);
            if (namespace_expanded.length<2) throw new Error('namespace has to have 2 component minimum: '+namespace);
            let namespace_full = namespace_expanded.join('/');  // redefine `namespace` to match namespace_expanded
            if (!this.$store || !this.$store.state) throw new Error('global store is not defined');
            //console.log('namespace_full', namespace_full);
            //console.log('vuex module exists:', namespace_full, this.$store.hasModule(namespace_expanded));
            if (!this.$store.hasModule(namespace_expanded)) {
                //console.log('registering store', namespace_full, parent, namespace_expanded.slice(0,-1));
                // register store module
                if (this.$store.hasModule(namespace_expanded.slice(0,-1))) {
                    //console.log('creating vue store', this.$store,namespace_full,module_name);
                    var module_obj = (typeof storeModule === 'function') ? storeModule(this.$store,namespace_full,module_name) : storeModule;
                    this.$store.registerModule(namespace_expanded, module_obj);
                    //console.log('store registered', namespace_expanded);
                } else {
                    throw new Error('mixin.store: no parent store module for '+namespace_full);
                }
            } else {
                //console.log('store already exists', namespace_expanded);
                // dispatch state reset mutation to reinitialize module's default state (if implemented by the module)
                // https://tkacz.pro/vue-vuex-reset-store-state-to-default-initial/
                this.$store.commit(namespace_full+'/stateReset');
            }

            // if watchers are part of the store definition, setup vuex store watcher on given getters
            var module_obj = this.$store._modules.get(namespace_expanded)._rawModule;
            this.unwatchers = [];
            for (let w in module_obj.watchers) {
                let watcher = module_obj.watchers[w];
                let context = this.$store._modules.get(namespace_expanded).context;
                let uw = this.$store.watch(
                    (state,getters) => getters[watcher.getter],
                    (newVal,oldVal) => watcher.callback(context, newVal, oldVal)
                );
                this.unwatchers.push(uw);
            }

            this.$options.store_namespace = namespace_full;
        },

        beforeDestroy () {
            if (this.unwatchers) for (let uw of this.unwatchers) uw();
        },

        // methods: {
        //     store_dispatch(action, params) {
        //         this.$store.dispatch(namespace + '/' + action, params);
        //     },
        //     store_commit(mutation, params) {
        //         this.$store.commit(namespace + '/' + mutation, params);
        //     },
        // },

        // computed: {
        //     ...mapGetters(namespace, getters),
        // },

    };
};


export const storeLoadOnCreate = {
    created() {
        return this.$store.dispatch(this.$options.store_namespace + '/load');
    }
};


export const storeGetters = function(getters, getters_namespace) {
    return {
        beforeCreate() {
            if (!this.$options.store_namespace && !getters_namespace) {
                // find a parent that provides `store_namespace` prop/data member
                var p = this;
                while (p) {
                    if (p.$options.store_namespace) break;
                    p = p.$parent;
                }
                if (!p || !p.$options.store_namespace) return console.error('storeGetters: no parent component provides required prop: store_namespace',this,p,p?p.$options.store_namespace:null);

                if (!this.$options.store_namespace) this.$options.store_namespace = p.$options.store_namespace;
            }

            // assign namespaced getters
            this.$options.computed = {
                ...this.$options.computed,
                ...mapGetters(getters_namespace || this.$options.store_namespace, getters||[]),
            };
        }
    };
};


export const storeAccess = {
    beforeCreate() {
        if (!this.$options.store_namespace) {
            // find a parent that provides `store_namespace` prop/data member
            var p = this;
            while (p) {
                if (p.$options.store_namespace) break;
                p = p.$parent;
            }
            // if a parent with store namespace is found, assign its namespace
            if (p && p.$options.store_namespace) this.$options.store_namespace = p.$options.store_namespace;
        }
    },

    // assign methods for accessing namespaced dispatch/commit calls
    methods: {
        store_getter(getter, store_namespace) {
            if (!this.$options.store_namespace && !store_namespace)  console.error('storeAccess: cannot use relative path in store_getter - parent namespace is not assigned');
            return this.$store.getters[(store_namespace || this.$options.store_namespace) + '/' + getter];
        },
        store_commit(mutation, params) {
            if (!this.$options.store_namespace && !mutation.includes('/'))  console.error('storeAccess: cannot use relative namespace in store_commit - parent namespace is not assigned');
            var mutation_full = mutation.includes('/') ? mutation : this.$options.store_namespace + '/' + mutation;
            return this.$store.commit(mutation_full, params);
        },
        store_dispatch(action, params) {
            if (!this.$options.store_namespace && !action.includes('/'))  console.error('storeAccess: cannot use relative namespace in store_dispatch - parent namespace is not assigned');
            var action_full = action.includes('/') ? action : this.$options.store_namespace + '/' + action;
            return this.$store.dispatch(action_full, params);
        },
    },
};
