import html from './media-appearance.html';
import lang from './media-appearance.lang.json';
import * as directives from '../../../../client/globals/directives.js';
import * as mixins from '../../../../client/globals/mixins.js';
import * as validators from '../../../../client/globals/validators.js';


export default {
    template: html,

    props: {
        data: Object,
        readonly: Boolean,
        v_state: Object,
    },

    mixins: [
        directives.label_required, 
        validators.vuelidate
    ],

    i18n: {
        messages: lang
    },

    data() {
        let $t = this.$t.bind(this);
        return {
            media_type_options: [
                {value: 'tv', text: $t('media_types.tv')},
                {value: 'radio', text: $t('media_types.radio')},
                {value: 'printed', text: $t('media_types.printed')},
                {value: 'online', text: $t('media_types.online')},
                {value: 'web', text: $t('media_types.web')},
                {value: 'other', text: $t('media_types.other')},
            ]
        };
    },

    mounted() {
        // emit update event to initialize the validation state
        this.$emit('update:v_state', this.$v);
    },

    validations() {
        return {
            data: {
                title: {
                    required: validators.required,
                },
                date: {
                    required: validators.required,
                    asis_date: validators.asis_date,
                },
                media_type: {
                    required: validators.required,
                },
                media_title: {
                    required: validators.required,
                },
                contribution_type: {},
                url: {
                    url: validators.url,
                },
                comments: {},
            },
        };
    },

    watch: {
        '$v.$invalid': function() {
            // emit update event on each change of the validation state
            this.$emit('update:v_state', this.$v);
        },
    },    

};


