import * as translators from '../../../../client/globals/translators.js';
//import server from '../../../client/asis-server.js';

'use strict';
// jshint esversion:6

// file list component
export default {
    template: `
        <b-table-simple responsive small class="border-bottom mb-2">
            <b-tbody v-if="assets.length>0">
                <b-tr v-for="asset in assets" :key="asset.document_id">
                    <b-td>{{asset.document_id}}</b-td>
                    <b-td>
                        <b-link :href="asset.url" class="text-info font-weight-bold" target="_blank">{{asset.name}}</b-link>
                    </b-td>
                    <b-td>{{asset.description}}</b-td>
                    <b-td class="text-nowrap" style="width:5%">{{date_timestamp2ddmmyyyy(asset.created)}}</b-td>
                    <b-td class="text-nowrap" style="width:5%">rev.&nbsp;{{asset.revision}}</b-td>
                    <b-td class="text-nowrap" style="width:5%">{{filesize_auto(asset.size)}}</b-td>
                    <b-td class="text-nowrap" style="width:5%"><b-button variant="danger" class="py-0" @click="$emit('remove', asset.document_id)"><i class="fas fa-times"></i></b-button></b-td>
                </b-tr>
            </b-tbody>
            <b-tbody v-else>
                <b-tr>
                    <b-td>{{ $t('no_assets') }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>                
    `,

    props: ['assets'],

    // data must be a function, so each instance has its own copy of data
    data: function() {
        return {
        };
    },

    methods: {
        // translators:
        date_asis2ddmmyyyy: translators.date_asis2ddmmyyyy,
        date_timestamp2ddmmyyyy: translators.date_timestamp2ddmmyyyy,
        filesize_auto: translators.filesize_auto,
    }
};




