import html from './news-list.html';
import lang from './news-list.lang.json';
import server from '../../../client/asis-server.js';
//import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate],

    data: function() {
        var $t = this.$t.bind(this);
        return {
            loading: false,
            news: [],
            news_fields: [
                {key: 'title', label:$t('list.title'), sortable: false},
                {key: 'date_from', label:$t('list.date_from'), sortable: true},
                {key: 'date_until', label:$t('list.date_until'), sortable: true},
                {key: 'owner_name', label:$t('list.owner'), sortable: true},
                {key: 'actions', label:''}
            ],
            news_old: 1,
            news_page: 1,
        };
    },

    methods: {
        load() {
            this.loading = true;
            server.request(
                'intranet-usr', 'news-list', {show_old:this.news_old}, null,
                (data, extra)=>{
                    this.news = data;
                }
            ).then(()=>{
                this.loading = false;
            });
        },

        news_class(item, type) {
            //if (!item || type !== 'row') return;
            if (item && item.past_event) return 'text-muted';
        },
    },


};


