import html from './worksitetrips-mgr-approve.html';
import lang from './worksitetrips-mgr-approve.lang.json';
import * as directives from '../../../../client/globals/directives.js';
import * as validators from '../../../../client/globals/validators.js';
import * as translators from '../../../../client/globals/translators.js';
import * as mixins from '../../../../client/globals/mixins.js';
import server from '../../../../client/asis-server.js';
import moment from 'moment';



export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.module_name, 
        mixins.loadDataOnCreate, 
    ],

    data: function() {
        return {
            request: null,
            calendar_date: null,
            responded: false,
            comment: '',
        };
    },

    methods: {
        load() {
            server.request(
                'timesheets-mgr', 'worksitetrip-request-view', 
                this.$route.params, null,   // this.$route.params provides `requsition_id`, `secret` and `role`
                (data, extra) => {
                    this.request = data;
                    this.request.applicant = extra.applicant;
                    this.calendar_date = moment(this.request.details.date, 'YYYY/MM/DD').format('YYYY-MM-DD');
                    this.responded = extra.responded;
                    // this.applicant = data.applicant;
                    // this.year = data.year;
                    // this.month = data.month;
                    // this.calendar_initial_date = new Date(this.year, this.month-1, 15);
                    // this.calendar_date_min = new Date(this.year, this.month-1, 1);
                    // this.calendar_date_max = new Date(this.year, this.month, 0);  // last day of previous month (https://stackoverflow.com/a/222439/4924581)
                    // this.days_approved  = data.days_approved;
                    // this.days_requested = data.days_requested;
                }
            );
        },

        save(approve) {
            if (!approve && !this.comment) {
                this.$bvModal.msgBoxOk(this.$t('error_no_reject_message'), {centered:true}).catch(e=>{});
                return;
            }

            server.request(
                'timesheets-mgr', 'worksitetrip-request-approve', 
                Object.assign({}, this.$route.params, {approve, comment:this.comment})
            ).then(()=>{
                this.$bvModal.msgBoxOk(this.$t('request_sent'),{centered:true}).then(value => {
                    this.$router.push('/');
                });
            });
        },

        request_approve() {
            this.save(true);
        },

        request_reject() {
            this.save(false);
        },

        getclass_calendar(ymd, date) {
            var year  = date.getFullYear();
            var month = date.getMonth()+1;
            var day   = date.getDate();
            if (this.year==year && this.month==month && this.days_requested.includes(day)) return 'ho-request';
            if (this.year==year && this.month==month && this.days_approved.includes(day)) return 'ho-approved';
            return null;
        },

        // translators:
        datetime_timestamp2dmyyyy_hhmm: translators.datetime_timestamp2dmyyyy_hhmm,
    },


    computed: {
        status_class() {
            switch (this.request.status) {
                case 'Q': return 'warning';
                case 'A': return 'success';
                case 'R': return 'danger';
                case 'C': return 'dark';
            }
        }
    },

};
