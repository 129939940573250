import Vue from 'vue';
import server from '../../../client/asis-server.js';
//import * as validators from '../../../client/globals/validators.js';




export default function (store, namespace, module) {
    const store_module = {
        namespaced: true,

        state: () => ({
            project: null,
            project_proposal: null,
            project_documents: [],
            loading: false,
        }),


        getters: {
            project: state => state.project,
            project_proposal: state => state.project_proposal,
            project_documents: state => state.project_documents,
            loading: state => state.loading,
            $v: state => {},
        },


        mutations: {
            project(state, data) {
                state.project = data.project;
                state.project_proposal = data.proposal;
                state.project_documents = data.documents || [];
            },

            loading(state, loading) {
                state.loading = loading;
            },
        },


        actions: {
            load(context, {project_id, authorization, secret}) {
                context.commit('loading', true);
                return server.request(
                    module, 'projects-get', 
                        {project_id, authorization, secret}, null,
                    (data, extra) => {
                        context.commit('project', {
                            project: data, 
                            proposal: extra.proposal, 
                            documents: extra.documents,
                        });
                    }
                ).then(()=>{
                    context.commit('loading', false);
                });
            },
        },
    };

    return store_module;
};
