import html from './attribute-linetype.html';
import lang from './attribute-linetype.lang.json';
//import server from '../../../../client/asis-server.js';
//import * as translators from '../../../../client/globals/translators.js';
//import moment from 'moment';


export default {

    props: {
        attribute: Object,
        edit: Boolean,
    },

    template: html,

    // data() {
    //     return {
    //         object: null,
    //     };
    // },

    i18n: {
        messages: lang
    },

    methods: {
    },

};

