import html from './worksitetrips-usr-view.html';
import lang from './worksitetrips-usr-view.lang.json';
import * as directives from '../../../../client/globals/directives.js';
import * as validators from '../../../../client/globals/validators.js';
import * as translators from '../../../../client/globals/translators.js';
import * as mixins from '../../../../client/globals/mixins.js';
import server from '../../../../client/asis-server.js';
import moment from 'moment';



export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.module_name, 
        mixins.loadDataOnCreate, 
    ],

    data: function() {
        return {
            requisition: null,
            calendar_date: null,
            can_cancel: false,
        };
    },

    methods: {
        load() {
            server.request(
                this.module_name, 'worksitetrip-view', 
                this.$route.params, null,   // this.$route.params provides `requsition_id` and `secret`
                (data, extra) => {
                    this.requisition = data;
                    this.calendar_date = moment(this.requisition.details.date, 'YYYY/MM/DD').format('YYYY-MM-DD');
                    this.can_cancel = extra.can_cancel;
                }
            );
        },

        cancel() {
            if (!this.can_cancel) return;
            if (!confirm(this.$t('cancel_confirmation'))) return;
            server.request(
                this.module_name, 'worksitetrip-cancel', 
                this.$route.params, null,   // this.$route.params provides `requsition_id` and `secret`
            ).then(()=>{
                this.$bvModal.msgBoxOk(this.$t('request_sent'),{centered:true}).then(value => {
                    this.$router.go(-1);
                });
            });
        },

        // translators:
        datetime_timestamp2dmyyyy_hhmm: translators.datetime_timestamp2dmyyyy_hhmm,
    },

    computed: {
        status_class() {
            switch (this.requisition.status) {
                case 'Q': return 'warning';
                case 'A': return 'success';
                case 'R': return 'danger';
                case 'C': return 'dark';
            }
        }
    },


};
