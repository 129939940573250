import html from './projects-reporting.html';
import lang from './projects-reporting.lang.json';
import server from '../../../client/asis-server.js';
import * as mixins from '../../../client/globals/mixins.js';

export default {
    template: html,

    mixins: [
        // mixins.module_name, 
        // mixins.loadDataOnCreate, 
    ],

    i18n: {
        messages: lang
    },

    data() {
        return {
            export_kis_start: new Date().getFullYear()-1,
            export_kis_end: new Date().getFullYear(),
            processing_export_kis: false,
        }
    },

    methods: {
        export_kis() {
            this.processing_export_kis = true;
            server.request(
                'projects-mgr', 'projects-export-kis', {
                    start: this.export_kis_start,
                    end: this.export_kis_end,
                }, null,
                null, null, // callbacks
                {responseType:'blob', timeout:90000}
            ).then(()=>{
                this.processing_export_kis = false;
            });
        },

        neorocks_summary() {
            //this.loading = true;
            return server.request(
                'projects-mgr', 'projects-timesheets-summary', {
                    project_id: 140216,
                    period_start: '2020/1/1',
                    period_end: '2021/11/30',
                }, null,
                null, null, // callbacks
                {responseType:'blob', timeout:90000}
            ).then(()=>{
                //this.loading = false;
            });
        },


        neorocks_print() {
            //this.loading = true;
            return server.request(
                'projects-mgr', 'projects-timesheets-print', {
                    project_id: 140216,
                    //period_start: '2020/1/1',
                    //period_end: '2021/11/30',
                    period_start: '2021/11/1',
                    period_end: '2021/11/30',
                }, null,
                null, null, // callbacks
                {responseType:'blob', timeout:90000}
            ).then(()=>{
                //this.loading = false;
            });
        },


        solarnet_summary() {
            //this.loading = true;
            return server.request(
                'projects-mgr', 'projects-timesheets-summary', {
                    project_id: 140148,
                    period_start: '2020/7/1',
                    period_end: '2021/12/31',
                }, null,
                null, null, // callbacks
                {responseType:'blob', timeout:90000}
            ).then(()=>{
                //this.loading = false;
            });
        }

    },
};
