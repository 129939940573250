
const go_back_button = {
    // uses router-back-button component
    template: `
        <b-button 
                x-v-if="" 
                x-to="{path: $routerHistory.previous().path}"
                x-click="$router.back()"
                @click="go_back()"
                variant="outline-secondary" 
                class="go-back-button">
            <i class="fa fa-arrow-left"></i> {{$t('title')}}
        </b-button>`,

    i18n: {
        messages:{
            cz: {title: "Zpět"},
            en: {title: "Back"}
        }
    },

    methods: {
        go_back() {
            this.$router.back();//backward();
        }
    }
/*
this was
    methods: {
        go_back() {
            / * --- commented out as it is not very intuitive

            // current path splitted into parts by '/' divider
            var path = this.$route.path.split('/');
            // remove the last part
            path.pop();
            // navigate to a new path
            this.$router.push(path.join('/')||'/');
            * /
            if (this.$route.path.split('/').length > 2) {
                this.$router.go(-1);
            } else {
                this.$router.push('/');
            }
        }
    }
    */
};
export default go_back_button;


