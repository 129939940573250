import html from './menus-overview-usr.html';
import lang from './menus-overview-usr.lang.json';
import server from '../../../client/asis-server.js';
import * as mixins from '../../../client/globals/mixins.js';
import * as translators from '../../../client/globals/translators.js';
import moment from 'moment';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate],

    data: function() {
        var $t = this.$t.bind(this);
        return {
            reservations_past: [],                       // server data
            reservations_future: [],                       // server data
            past_fields: [
                {key: 'month', label:$t('summary.month'), formatter: 'summaryMonth'},
                {key: 'count', label:$t('summary.count')},
            ],
            future_fields: [
                {key: 'date', label:$t('summary.date'), formatter: translators.date_asis2ddmmyyyy},
                {key: 'count', label:$t('summary.count')},
            ],
            loading: false,
        };
    },

    methods: {
        load() {
            this.loading = true;
            server.request(
                'canteen-usr', 'reservations-overview',
                {}, null,
                (data, extra)=>{
                    this.reservations_past = data.reservations_past;
                    this.reservations_future = data.reservations_future;
                }
            ).then(()=>{
                this.loading = false;
            });
        },

        summaryMonth(value, prop, row) {
            var [year, month] = value.split('-');
            return `${month}/${year}`;
        },

    },

    // computed: {
    //     // a computed getter
    //     dayofweek: function () {
    //         // `this` points to the vm instance
    //         const daysofweek_cz = ['pondělí','úterý','středa','čtvrtek','pátek','sobota','neděle'];
    //         var d = moment(this.menu.date,  "YYYY/MM/DD");
    //         return daysofweek_cz[d.isoWeekday()-1] + ', ' + d.week() + '. týden';
    //     }
    // }
}
