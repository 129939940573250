import html from './seminars-edit.html';
import lang from './seminars-edit.lang.json';
import server from '../../../client/asis-server.js';
import * as directives from '../../../client/globals/directives.js';
import * as validators from '../../../client/globals/validators.js';
import * as mixins from '../../../client/globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.loadDataOnCreate, 
        mixins.hasRole, 
        directives.label_required, 
        validators.vuelidate,
        mixins.storeGetters(['lang'],'asis/ui'),
    ],

    data: function() {
        return {
            seminar: {},                        // seminar data
            submit_progress: 0,
            submit_error: null,                 // soft error
        };
    },

    validations() {
        return {
            seminar: {
                date: {
                    required: validators.required,
                    date: validators.asis_datetime,
                },
                title: {
                    required: validators.required,
                    maxLength: validators.maxLength(255),

                },
                author: {
                    required: validators.required,
                    maxLength: validators.maxLength(255),
                },
                department: {
                    required: validators.requiredUnless('department_all')
                },
                affil: {
                    maxLength: validators.maxLength(255),
                },
                abstract: {
                },
                comment: {
                },
                location: {
                    required: validators.required
                },
            }
        };
    },

    methods: {
        load() {
            server.request(
                this.$parent.module_name, 'seminars-get',
                {seminar_id: this.$route.params.seminar_id||0}, null,
                (data, extra)=>{
                    this.seminar = data;
                }
            );
        },

        submit() {
            // check if the form validates
            if (this.$v.$invalid) return;

            this.submit_error = null;
            server.request(
                this.$parent.module_name, 'seminars-save', this.seminar, null, 
                (data) => {
                    // if ok, go to previous view
                    this.$router.back();
                }, 
                (error) => {
                    this.submit_error = error;
                }
            );
            return false;
        },

        remove() {
            if (!confirm(this.$t('remove_confirmation'))) return false;
            
            server.request(
                this.$parent.module_name, 'seminars-remove', this.seminar, null, 
                (data) => {
                    // if ok, go to previous view
                    this.$router.back();
                }
            );
            return false;
        }

    }
};

