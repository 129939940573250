import html from './notifications.html';
import lang from './notifications.lang.json';
import server from '../../../client/asis-server.js';
import * as mixins from '../../../client/globals/mixins.js';

export default {
    template: html,

    i18n: {
        messages: lang,
    },

    mixins: [
        mixins.storeAccess,
    ],

    data: function () {
        return {
            notifications: [],
        }
    },

    created() {
        this.load();
    },
    
    methods: {
        load() {
            this.store_dispatch('asis/ui/statusbar_notifications');
            server.request(
                'system-usr', 'notifications-list', {}, null, 
                (data) => {
                    this.store_commit('asis/ui/notifications', data);
                    this.notifications = data;
                }
            );
        },

        ack(notification_id) {
            server.request(
                'system-usr', 'notifications-ack', {notification_id}, null, 
                (data) => {
                    this.load();
                }
            );
        }

        // submit() {
        //     server.request(
        //         'system-usr', 'notifications-get', {report:this.report}, null, 
        //         (data) => {
        //             this.$bvModal.msgBoxOk(this.$t('thanks'), {centered:true})
        //             .then(v=>{
        //                 // if ok, go to previous view
        //                 this.$router.back();
        //             })
        //             .catch(e=>{});
        //         }
        //     );
        //     return false;
        // }
    }

};


