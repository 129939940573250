import html from './projects-approval-mgr.html';
import lang from './projects-approval-mgr.lang.json';
import * as mixins from '../../../client/globals/mixins.js';

import store from './projects-edit-store.js';
import tab_info from './components/project-info.js';
import tab_budget from './components/project-budget.js';
import tab_documents from './components/project-documents.js';
import tab_approvals from './components/project-approvals-mgr.js';
import tab_status from './components/project-status.js';
import server from '../../../client/asis-server.js';


export default {
    template: html,

    mixins: [
        mixins.module_name, 
        mixins.loadDataOnCreate, 
        mixins.store_create(store, 'projects-approval'),
        mixins.storeAccess,
        mixins.storeGetters(['project','loading','saving','save_progress','save_errors']),
    ],

    components: {
        'project-info': tab_info,
        'project-budget': tab_budget,
        'project-documents': tab_documents,
        'project-approvals': tab_approvals,
        'project-status': tab_status,
    },

    i18n: {
        messages: lang
    },

    data() {
        return {
            project_id: this.$route.params.project_id,
            request_id: parseInt(this.$route.params.approve_request_id),
            request_secret: this.$route.params.approve_request_secret,
            request: null,
        };
    },

    methods: {
        load() {
            this.store_dispatch('load', this.$route.params.project_id);

            server.request(
                'projects-mgr', 'projects-approvals-get', 
                {request_id: this.request_id, secret:this.request_secret}, null,
                (data, extra) => {
                    this.request = data;
                }
            );
        },
    },
};
