import html from './publications-list-usr.html';
import lang from './publications-list-usr.lang.json';
import server from '../../../client/asis-server.js';
import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate],

    data: function() {
        var $t = this.$t.bind(this);
        var currentYear = new Date().getFullYear(); 
        return {
            publications: [],
            publications_fields: [
                {key: 'publication_id', label:$t('list.publication_id'), sortable:true},
                {key: 'type', label:$t('list.type'), sortable:true},
                {key: 'year', label:$t('list.year'), sortable:true},
                {key: 'details.authors', label:$t('list.authors'), formatter:'authors', sortable:true},
                {key: 'details.title', label:$t('list.title')},
                {key: 'links', label:$t('list.links')},
                {key: 'actions', label:''},
            ],
            year_min: currentYear-5,
            year_max: currentYear,
            export_url: null,
            loading: false,
        };
    },

    methods: {
        load() {
            this.loading = true;
            server.request(
                'publications-usr', 'publications-list', 
                {year_min:this.year_min, year_max:this.year_max}, null,
                (data, extra)=>{
                    this.publications = data;
                    this.export_url = extra.export_url;
                }
            ).then(() => {
                this.loading = false;
            });
        },
    },

};

