'use strict';
// jshint esversion:6
// jshint -W069


import config from './reservations.config.json';
import view_events_list from './views/events-list.js';
import view_events_edit from './views/events-edit.js';


const module_reservations_usr = {
    module_name: 'reservations-usr',
    module_class: 'usr',
    title: config.usr.title,
    icon: config.usr.icon,
    routes: [
        {path:'', redirect:{name:'reservations-list'}},
        {name:'reservations-list', path:'list', component:view_events_list},
        {name:'reservations-add',  path:':cal_id/add', component:view_events_edit},
        {name:'reservations-edit', path:':cal_id/edit/:event_id', component:view_events_edit},
    ]
};


export default [module_reservations_usr];

