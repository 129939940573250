import html from './news-edit.html';
import lang from './news-edit.lang.json';
import server from '../../../client/asis-server.js';
import * as directives from '../../../client/globals/directives.js';
import * as validators from '../../../client/globals/validators.js';
import * as mixins from '../../../client/globals/mixins.js';
//import document_info from '../components/document-info.js';

// import 'tinymce'
// import 'tinymce-theme-silver';
// import 'tinymce-plugins-code';
// import 'tinymce-plugins-image';
// import 'tinymce-plugins-link';
// import Editor from 'tinymce-vue';
// import ClassicEditor from 'ckeditor';
// import 'ckeditor-cs';
// import CKEditor from 'ckeditor-vue';


export default {
    template: html,

    // components:{
    //     //'tinymce-editor': Editor
    //     'ckeditor': CKEditor.component
    // },

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate, directives.label_required, validators.vuelidate],

    // components: {
    //     'news-assets': document_info
    // },

    data: function() {
        return {
            //editor: ClassicEditor,
            news: {},                           // news data
            assets: [],
            // assets_upload: {
            //     file: null,
            //     description: '',
            // },
            // assets_upload_progress: 0,
            // assets_upload_error: null,
            submit_error: null,                 // soft error
            saving: false,
            saved_notification: false,
        };
    },

    validations: {
        news: {
            title_cz: {
                required: validators.required,
            },
            title_en: {
                required: validators.required,
            },
            date_from: {
                required: validators.required,
                date: validators.asis_date,
            },
            date_until: {
                required: validators.required,
                date: validators.asis_date,
            },
            content_cz: {
            },
            content_en: {
            },
        },
        // assets_upload: {
        //     file: {
        //         file: validators.file,
        //     },
        //     description: {
        //     }
        // }
    },

    methods: {
        load() {
            server.request(
                'intranet-usr', 'news-get',
                {news_id: this.$route.params.news_id||0}, null,
                (data, extra)=>{
                    this.news = data;
                    //this.assets = extra.assets || [];
                }
            );
        },

        submit(publish) {
            // check if the form validates
            if (this.$v.$invalid) return;

            this.submit_error = null;
            this.saving = true;
            this.saved_notification = false;
            this.news.published = this.news.published || (publish||false);
            server.request(
                'intranet-usr', 'news-save', this.news, null, 
                (data) => {
                    // if ok, go to previous view
                    this.saved_notification = true;
                    this.news = data;
                    console.log('data',data);
                    console.log('publish',publish);
                    if (publish) this.$router.back();
                }, 
                (error) => {
                    this.submit_error = error;
                }
            ).then(()=>{
                this.saving = false;
            });
        },

        // assets_upload_file() 
        // //! Uploads a file as a page attachment.
        // {
        //     // check if page has been assigned an ID
        //     if (!this.page.page_id) return;
        //     // check if the form validates and file is selected
        //     if (this.$v.assets_upload.$invalid || !this.assets_upload.file) return;

        //     this.assets_upload_progress = 0;
        //     this.assets_upload_error = null;
        //     server.request(
        //         'intranet-usr', 'assets-save', Object.assign({}, this.assets_upload, {page_id:this.page.page_id}),
        //         {onProgress:(perc)=>{
        //             this.assets_upload_progress = perc
        //         }, timeout:600000},
        //         (result)=>{
        //             this.assets_upload.file = null;
        //             this.assets_upload.description = '';
        //             this.load();
        //         },
        //         (error)=>{
        //             this.assets_upload_error = error;      // show error message
        //         }
        //     ).then(()=>{
        //         this.assets_upload_progress = 0;       // hide upload progress bar
        //     })
        // },

        // assets_remove(document_id) 
        // //! Remove an asset from the page.
        // {
        //     if (!confirm(this.$t('assets.remove'))) return;

        //     server.request(
        //         'intranet-usr', 'assets-remove', {page_id:this.page.page_id, doc_id:document_id}, null,
        //         (result)=>{
        //             this.load();
        //         }
        //     );
        // }

    }
};

