import html from './overviews-worksitetrips.html';
import lang from './overviews-worksitetrips.lang.json';
import * as mixins from '../../../client/globals/mixins.js';
import server from '../../../client/asis-server.js';
import month_select_mixin from './components/month-select.js';
import moment from 'moment';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [month_select_mixin],

    data: function() {
        var $t = this.$t.bind(this);
        return {
            fields_employee:[
                {key:'employee_pid', label:$t('employee_pid'), sortable:true},
                {key:'employee_name', label:$t('employee_name'), sortable:true},
                {key:'employee_department', label:$t('employee_department'), sortable:true},
                {key:'employee_worksite', label:$t('employee_worksite'), sortable:true},
            ],
            fields_sums:[
                {key:'total', label:$t('total')},
            ],
            fields:[],
            overview: [],
            export_slm_url: null,
            loading: false,
        };
    },

    methods: {
        load() {
            this.loading = true;
            this.fields = [];
            server.request(
                'timesheets-mgr', 'overview-worksitetrips', {year:this.date.year(), month:this.date.month()+1}, null,
                (data, extra) => {
                    var fields_days = [];
                    for (let i=1; i<=extra.days_in_month; i++) {
                        let d = new Date(this.date.year(), this.date.month(), i)
                        fields_days.push({key:'day-'+i, label:i.toString().pad(2,"0"), class:{'table-secondary':(d.isHoliday()||d.isWeekend()), 'text-center':true}});
                    }
                    this.fields = [].concat(this.fields_employee, fields_days, this.fields_sums);
                    this.overview = data;
                    this.export_slm_url = extra.export_slm_url;
                },
                null,
                {timeout: 30000}
            ).then(()=>{
                this.loading = false;
            });
        },

        format_trip(trips, day) {
            for (let t of  trips) {
                var d = new Date(t.details.date);
                if (d.getDate() == day) return t.status.toUpperCase();
            }
            return '-';
        },

    },

};
