'use strict';
// jshint esversion:6
// jshint -W069


import config from './employees.config.json';
import view_employees_edit from './views/employees-edit.js';
import view_employees_list from './views/employees-list.js';
import view_homeoffice_contracts_edit from './views/homeoffice-contracts-edit.js';


const module_employees_mgr = {
    module_name: 'employees-mgr',
    module_class: 'mgr',
    title: config.mgr.title,
    icon: config.mgr.icon,
    routes: [
        {path:'', redirect:{name:'employees-list'}},
        {name:'employees-list', path:'list', component:view_employees_list},
        {name:'employees-add',  path:'add', component:view_employees_edit, meta:{roles:'personal-manager'}},
        {name:'employees-edit', path:'edit/:pid', component:view_employees_edit, meta:{roles:'personal-manager'}},
        {name:'employees-ho-contracts-add', path:'homeoffice/add/:pid', component:view_homeoffice_contracts_edit, meta:{roles:'personal-manager'}},
        {name:'employees-ho-contracts-edit', path:'homeoffice/edit/:pid/:contract_id', component:view_homeoffice_contracts_edit, meta:{roles:'personal-manager'}},
    ]
};


export default [module_employees_mgr];

