import Vue from 'vue';
import server from './asis-server.js';
import i18n from './asis-i18n.js';
import moment from 'moment';


export default {
    namespaced: true,

    state: () => ({
        lang: 'cz',
        modules: null,
        modules_loading: false,
        notifications: null,
    }),


    getters: {
        lang: state => state.lang,
        modules_usr: state => state.modules ? state.modules.usr : [],
        modules_mgr: state => state.modules ? state.modules.mgr : [],
        modules_loading: state => state.modules_loading,
        notifications: state => state.notifications || [],
    },


    mutations: {
        lang(state, lang) {
            //console.log('asis.ui: setting lang to', lang);
            if (lang=='cz') moment.locale('cs');
            if (lang=='en') moment.locale('en-gb');
            i18n.locale = lang;
            server.lang = lang;
            state.lang  = lang;
        },
        modules(state, modules) {
            state.modules = modules;
        },

        modules_loading(state, loading) {
            state.modules_loading = loading;
        },

        notifications(state, notifications) {
            state.notifications = notifications;
        },
    },


    actions: {
        lang(context, lang) {
            context.commit('lang', lang);
            context.dispatch('asis/ui/modules_list', true,  {root:true});
        },

        modules_list(context, force) {
            if (context.state.modules && !force) return;
            //console.log('loading modules');
            context.commit('modules_loading', true);
            return server.request(
                'system-usr', 'modules', {}, null, 
                (data) => {
                    context.commit('modules', data);
                }
            ).then(()=>{
                context.commit('modules_loading', false);
            });
        },


        statusbar_notifications(context) {
            // if not authenticated, skip silently
            if (!context.rootGetters['asis/auth/authenticated']) return;

            // fetch notifications
            //console.log('loading notification');
            return server.request(
                'system-usr', 'notifications-list', {brief:true}, null, 
                (data) => {
                    context.commit('notifications', data);
                },
                (error) => {},
                {ignore_errors: true}
            );
        },
    }
};

