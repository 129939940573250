import L from 'leaflet';
import 'leaflet-draw';
import 'leaflet-fullscreen';
import 'leaflet.vectorgrid';
import server from '../../../../client/asis-server.js';




// define WaterLine feature as a PolyLine descendant
L.Draw.WaterLine = L.Draw.Polyline.extend({
	statics: {
		TYPE: 'waterline'
	},

	initialize: function (map, options) {
		L.Draw.Polyline.prototype.initialize.call(this, map, options);
		this.type = L.Draw.WaterLine.TYPE;
	},
});

// define DataLine feature as a PolyLine descendant
L.Draw.DataLine = L.Draw.Polyline.extend({
	statics: {
		TYPE: 'dataline'
	},

	initialize: function (map, options) {
		L.Draw.Polyline.prototype.initialize.call(this, map, options);
		this.type = L.Draw.DataLine.TYPE;
	},
});

// define PowerLine feature as a PolyLine descendant
L.Draw.PowerLine = L.Draw.Polyline.extend({
	statics: {
		TYPE: 'powerline'
	},

	initialize: function (map, options) {
		L.Draw.Polyline.prototype.initialize.call(this, map, options);
		this.type = L.Draw.PowerLine.TYPE;
	},
});

// define PowerLine feature as a PolyLine descendant
L.Draw.RefPoint = L.Draw.Marker.extend({
	statics: {
		TYPE: 'refpoint'
	},

	initialize: function (map, options) {
		L.Draw.Marker.prototype.initialize.call(this, map, Object.assign({
            icon: new L.icon({
                iconUrl: '/assets/maps/icon-refpoint1.png',
                iconSize: [12,12],
                iconAnchor: [6,12],
            }),
        }, options));
		this.type = L.Draw.RefPoint.TYPE;
	},
});

// override definition of Leaflet.Draw toolbar to list a custom set of controls
L.DrawToolbar.prototype.getModeHandlers = function(map) {
    return [
        {
            enabled: true,
            handler: new L.Draw.WaterLine(map, this.options.waterline||{}),
            title: 'Vodovodní vedení',
        },
        {
            enabled: true,
            handler: new L.Draw.DataLine(map, this.options.dataline||{}),
            title: 'Datové vedení'
        },
        {
            enabled: true,
            handler: new L.Draw.PowerLine(map, this.options.powerline||{}),
            title: 'Elektrické vedení'
        },
        {
            enabled: true,
            handler: new L.Draw.RefPoint(map, this.options.refpoint||{}),
            title: 'Referenční bod'
        },
    ];
};


L.Control.DrawExtended = L.Control.Draw.extend({

    selectedPathOptions: {
        dashArray: '10, 10',
        fill: true,
        fillColor: '#fe57a1',
        fillOpacity: 0.1,
        // Whether to user the existing layers color
        maintainColor: false
    },


	initialize: function (options) {
        this.editHandler = null;
        this.editControls = null;
        this.editableObjects = new L.FeatureGroup();

        options = Object.assign({}, options||{}, {edit:false});
        L.Control.Draw.prototype.initialize.call(this, options);
    },

    onAdd: function (map) {
        this.editHandler = new L.EditToolbar.Edit(map, {
            featureGroup: this.editableObjects,
            selectedPathOptions: this.selectedPathOptions,
            poly: null//L.EditToolbar.options.poly
        });

        return L.Control.Draw.prototype.onAdd.call(this, map);
    },

    onRemove: function (map) {
        this.stopEditObject(false);

        return L.Control.Draw.prototype.onRemove.call(this);
    },

    startEditObject: function(object) {
        // cancel any previous editing
        this.stopEditObject(false);
        // add object to editable layer and start edit mode
        this.editableObjects.clearLayers();
        this.editableObjects.addLayer(object);
        this.editHandler.enable();
        // create edit controls (save/cancel) and show them on map
        this.editControls = new L.Control.EditControls(this);
        this.editControls.addTo(this._map);
        // disable tooltip
        this.editHandler._tooltip.dispose();
        console.log('editstart',this)
        if (typeof this.options.onObjectEditStart === 'function') this.options.onObjectEditStart(object);
    },

    stopEditObject: function(save) {
        // save or revert changes
        if (save) {
            this.editHandler.save();
            if (typeof this.options.onObjectEditConfirm === 'function') this.options.onObjectEditConfirm();
        } else { 
            this.editHandler.revertLayers();
            if (typeof this.options.onObjectEditCancel === 'function') this.options.onObjectEditCancel();
        }
        // remove controls and disable editting
        this.editableObjects.clearLayers();
        if (this.editControls) this.editControls.remove();
        this.editControls = null;
        this.editHandler.disable();
    },
});

// define edit button control
L.Control.EditButton = L.Control.extend({
    options: {
        position: 'topleft',
        onclick: null,
        title: 'Edit',
    },
    onAdd: function (map) {
        var container = L.DomUtil.create('div', 'leaflet-bar leaflet-control');
        var button = L.DomUtil.create('a', 'leaflet-control-button asis-maps-button-edit', container);
        L.DomEvent.disableClickPropagation(button);
        L.DomEvent.on(button, 'click', ()=>{
            if (typeof this.options.onclick === 'function') this.options.onclick();
        });
        container.title = this.options.title;
        return container;
    },
    onRemove: function(map) {},
});


// define edit button control
L.Control.EditControls = L.Control.extend({
    options: {
        position: 'topleft',
        save: {
            title: 'Ulozit',
            onclick: null,
        },
        cancel: {
            title: 'Zrusit',
            onclick: null,
        },
    },
	initialize: function (handler) {
        this._handler = handler;
        L.Control.prototype.initialize.call(this, this.options);
    },
    onAdd: function (map) {
        var container = L.DomUtil.create('div', 'leaflet-bar leaflet-control');
        var btn_save = L.DomUtil.create('a', 'leaflet-control-button btn-edit-save', container);
        var btn_cancel = L.DomUtil.create('a', 'leaflet-control-button btn-edit-cancel', container);
        L.DomEvent.disableClickPropagation(btn_save);
        L.DomEvent.disableClickPropagation(btn_cancel);
        L.DomEvent.on(btn_save, 'click', ()=>{
            this._handler.stopEditObject(true);
            if (typeof this.options.save.onclick === 'function') this.options.save.onclick();
        });
        L.DomEvent.on(btn_cancel, 'click', ()=>{
            this._handler.stopEditObject(false);
            if (typeof this.options.cancel.onclick === 'function') this.options.cancel.onclick();
        });
        btn_save.title = this.options.save.title;
        btn_cancel.title = this.options.cancel.title;
        return container;
    },
    onRemove: function(map) {},
});


class LeafletMap {
    constructor(parent)
    {
        this.parent = parent;
        this.container = null;
        this.map = null;
        this.editmode = false;
        this.controls = {};
        this.objects = new L.FeatureGroup();
    }

    mount(container)
    {
        this.container = document.getElementById(container);

        // create a map
        this.map = L.map(this.container, {
            minZoom: 1,//16
            maxZoom: 25,
            scrollWheelZoom: true,
            maxBounds: [
                [49.90606, 14.77796], // south west        
                [49.91712, 14.78770]  // north east
            ], 
        });
        this.map.setView([49.90965, 14.78152], 18);
        var osm_mapnik = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 25,
            xattribution: '&copy; OSM Mapnik <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(this.map);

        var vectorStyles = {
            water: {	// Apply these options to the "water" layer...
              fill: true,
              weight: 1,
              fillColor: '#ff0000',
              color: '#ff000',
              fillOpacity: 0.8,
              opacity: 0.4,
            },
            transportation: {	// Apply these options to the "transportation" layer...
              weight: 0.5,
              color: '#f2b648',
              fillOpacity: 0.2,
              opacity: 0.4,
            },
            landuse: {
                weight: 0,
                fillColor: '#9bc2c4',
                fillOpacity: 1,
                fill: true
            },
            building: {
                weight: 0,
                fillColor: '#ccddee',
                fillOpacity: 1,
                fill: true
            },
            // housenumber: {
            //     weight: 1,
            //     fillColor: '#0000ff',
            //     fillOpacity: 0,
            //     fill: false
            // },
          
            // And so on, until every layer in https://openmaptiles.org/schema/ has a style
          
            // aeroway:
            // boundary:
            // building:
            // housenumber:
            // landcover:
            // landuse:
            // park:
            // place:
            // poi:
            // transportation:
            // transportation_name:
            // water:
            // water_name:
            // waterway:

            'cities-point': function(properties, zoom) {
                return {
                    weight: 2,
                    color: 'red',
                    opacity: 1,
                    fillColor: 'yellow',
                    fill: true,
                    radius: 6,
                    fillOpacity: 0.7
                }
            },

            'housenumber': function(properties, zoom) {
                return {
                    weight: 2,
                    color: 'purple',
                    opacity: 1,
                    fillColor: 'green',
                    fill: true,
                    radius: 4,
                    fillOpacity: 0.3
                }
            },

            park: [],
            place: [],
            water: [],
            waterway: [],
            boundary: [],
            country_label: [],
            marine_label: [],
            state_label: [],
            place_label: [],
            waterway_label: [],
            water_name: [],
            landcover: [],
            landuse: [],
            landuse_overlay: [],
            road: [],
            transportation: [],
            waterway: [],
            aeroway: [],
            tunnel: [],
            bridge: [],
            barrier_line: [],
            building: [],
            road_label: [],
            road_name: [],
            transportation_name: [],
            housenum_label: [],
            mountain_peak: [],
          };
          

        // var vector_url = 'https://api.mapbox.com/v4/mapbox.mapbox-streets-v8/{z}/{x}/{y}.vector.pbf?access_token=pk.eyJ1IjoibXhkb2UiLCJhIjoiY2xqc2YxZDdqMGE0MjNnbnVtNXBudjRqOSJ9.XFi9TsC08-hsAotUfuckWQ';
        // var mapillaryLayer = L.vectorGrid.protobuf(vector_url, {
        //     vectorTileLayerStyles: vectorStyles,
        // }).on('click',function(e) {
        //     console.log(e);
        //     L.DomEvent.stop(e);
        // }).addTo(this.map);

//        var wmsLayer = L.tileLayer.wms('https://ags.cuzk.cz/arcgis1/services/ORTOFOTO/MapServer/WMSServer', {}).addTo(this.map);

        // var cuzk = L.tileLayer('https://ags.cuzk.cz/arcgis1/rest/services/ORTOFOTO/MapServer/tile/{z}/{x}/{y}', {
        //     maxZoom: 25,
        //     xattribution: '&copy; OSM Mapnik <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        // }).addTo(this.map);

        // // works ok:
        // var cuzk = L.tileLayer.wms('http://geoportal.cuzk.cz/WMS_ORTOFOTO_PUB/WMService.aspx?', {
        //     //maxNativeZoom: 20,
        //     maxZoom: 25,
		// 	layers: 'GR_ORTFOTORGB',
		// 	format: 'image/png',
        //     opacity: 1.0,
		// 	transparent: true,
		// 	continuousWorld : true,
		// }).addTo(this.map);

        // // works ok:
        // var cuzk = L.tileLayer('https://ags.cuzk.cz//arcgis1/rest/services/ORTOFOTO/MapServer/tile/{z}/{x}/{y}', {
        //     //maxNativeZoom: 20,
        //     maxZoom: 25,
		// 	layers: 'GR_ORTFOTORGB',
		// 	format: 'image/png',
        //     opacity: 1.0,
		// 	transparent: true,
		// 	continuousWorld : true,
		// }).addTo(this.map);



        // var ctrlFullScreen = new L.Control.Fullscreen({
        //     title: {
        //         'false': 'Režim celé obrazovky',
        //         'true': 'Do okna',
        //     }
        // });


        //add scale bar to map
        L.control.scale({
            position: 'bottomleft',
            imperial: false,
        }).addTo(this.map);



        this.controls.edit = new L.Control.EditButton({
            title: 'Režim úprav',
            onclick: ()=>this.edit_mode_toggle(),
        });

        // this.map.addControl(ctrlFullScreen);
        this.map.addControl(this.controls.edit);
        //this.map.addControl(drawControl);

        this.load_objects();

        this.map.on(L.Draw.Event.CREATED, (e)=>this.object_created(e));
        this.map.on(L.Draw.Event.EDITED, (e)=>this.object_edited(e));
        this.map.on(L.Draw.Event.DELETED, (e)=>this.object_deleted(e));
        this.map.on(L.Draw.Event.DRAWSTART, (e)=>{
            console.log('drawstart',e);
        });

        this.resizeObserver = new ResizeObserver(() => {
            this.map.invalidateSize();
        });
        this.resizeObserver.observe(this.container);
    }

    load_objects() {
        this.map.removeLayer(this.objects);
        server.request(
            'maps-usr', 'objects-list', {format:'geojson'}, null,
            (data)=>{
                this.objects = L.geoJSON(data, {
                    style: (feature) => {
                        switch (feature.properties.party) {
                            case 'Republican': return {color: "#ff0000"};
                            case 'Democrat':   return {color: "#0000ff"};
                        }
                    },
                    pointToLayer: (feature, latlng)=>{
                        return L.marker(latlng, {
                            icon: new L.icon({
                                iconUrl: '/assets/maps/icon-refpoint1.png',
                                iconSize: [24,24],
                                iconAnchor: [6,12],
                            }),
                        });
                    },
                    onEachFeature: (feature,layer)=>{
                        layer.on('click', (e)=>this.object_click(e));
                    },
                });
                this.map.addLayer(this.objects);
            }
        );

        //Overlay grouped layers    
        var groupOverLays = {
            "ASU": {
                "Lines": this.objects,
            },
        };

        //add layer switch control
        //L.control.groupedLayers({}, groupOverLays).addTo(this.map);


    }

    edit_mode_toggle() {
        if (!this.controls.draw) {
            this.controls.draw = new L.Control.DrawExtended({
                onObjectEditStart: (object)=>this.parent.mapobject_attributes_load(object.feature.
                    id),
                onObjectEditConfirm: ()=>this.parent.mapobject_attributes_save(),
                onObjectEditCancel: ()=>this.parent.mapobject_attributes_cancel(),
            });
            this.map.addControl(this.controls.draw);
            this.editmode = true;
        } else {
            this.map.removeControl(this.controls.draw);
            this.controls.draw = null;
            this.editmode = false;
        }        
    }

    object_created(e) {
        var type = e.layerType;
        var layer = e.layer;
        this.object_save(type, layer);
    }
        
    object_edited(e) {
        console.log('object_edited', e);
        //     event.layers.eachLayer(function(layer) {
        //         var geoJSON = layer.toGeoJSON();
        //         console.log(geoJSON);
        //     });
        return false;
    }

    object_deleted(e) {
        console.log('object_deleted', e);
        //     var type = e.layerType;
        //     var layer = e.layer;
        //     console.log('CREATED', type, layer);

        //     var idIW = L.popup();
        //     var content = '<span><b>Shape Name</b></span><br/><input id="shapeName" type="text"/><br/><br/><span><b>Shape Description<b/></span><br/><textarea id="shapeDesc" cols="25" rows="5"></textarea><br/><br/><input type="button" id="okBtn" value="Save" onclick="saveIdIW()"/>';
        //     idIW.setContent(content);
        //     idIW.setLatLng(layer.getLatLng());
        //     idIW.openOn(vue.map);

        //     layer.feature = layer.feature || {}; // Intialize layer.feature
        //     layer.feature.type = layer.feature.type || "Feature"; // Intialize feature.type
        //     var props = layer.feature.properties = layer.feature.properties || {}; // Intialize feature.properties
        //     layer.feature.properties.title = "my title";
        //     layer.feature.properties.content = "my content";
        //     console.log(layer);

        //     vue.drawnItems.addLayer(layer);
        //     var geoJSON = layer.toGeoJSON();
        //     console.log(geoJSON);

        //     server.request(
        //         'maps-usr', 'create',
        //         {id: 0, geojson:geoJSON}, null,
        //         (data, extra)=>{
        //         }
        //     );
    }

    object_save(object_type, layer) {
        var object = {
            object_id: 0,
            object_type,
            geojson: layer.toGeoJSON(7),
        };
        server.request('maps-usr', 'objects-save', object, null).then(()=>{
            this.load_objects();
        });
    }
        
    object_click(event) {
        var object = event.target;

        console.log('click', object);
        if (this.controls.draw) {
            this.controls.draw.startEditObject(object);
        } else {
            this.parent.mapobject_attributes_load(object.feature.id);
        }
    }
        

}

export default LeafletMap;
