import html from './people-list.html';
import lang from './people-list.lang.json';
import store from './people-list-store.js';
import * as mixins from '../../../client/globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.store_create(store,'people-list'),
        mixins.storeGetters(['people','loading']),
        mixins.storeAccess,
        mixins.storeLoadOnCreate,
    ],

    data() {
        var $t = this.$t.bind(this);
        return {
            people_fields: [
                {key: 'full_name', label:$t('list.full_name'), sortable: true},
                {key: 'email', label:$t('list.email')},
                {key: 'room', label:$t('list.room')},
                {key: 'phone1', label:$t('list.phone1')},
                {key: 'phone2', label:$t('list.phone2')},
                {key: 'actions', label:'', thStyle:{width:"15%"}}
            ],
        };
    },

    computed: {
        show_incomplete: {
            get () {
                return this.store_getter('show_incomplete');
            },
            set (value) {
                this.store_commit('show_incomplete', value);
                this.store_dispatch('load', value);
            }
        }
    },
};


