import html from './pages-list.html';
import lang from './pages-list.lang.json';
import server from '../../../client/asis-server.js';
import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate],

    data: function() {
        var $t = this.$t.bind(this);
        return {
            loading: false,
            groups: [],
            pages: [],
            pages_fields: [
                {key: 'title', label:$t('list.title'), sortable: true},
                {key: 'slug', label:$t('list.url'), sortable: false},
                {key: 'order', label:$t('list.order'), sortable: true},
                {key: 'owner_name', label:$t('list.owner'), sortable: true},
                {key: 'actions', label:''}
            ]
        };
    },

    methods: {
        load() {
            this.loading = true;
            server.request(
                'intranet-usr', 'pages-list', {}, null,
                (data, extra)=>{
                    this.pages = data;
                    this.groups = extra.groups;
                }
            ).then(()=>{
                this.loading = false;
            })
        },
    }
};


