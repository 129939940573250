import html from './settings.html';
import lang from './settings.lang.json';
import * as mixins from '../../../client/globals/mixins.js';
import * as validators from '../../../client/globals/validators.js';
import server from '../../../client/asis-server.js';
import moment from 'moment';


const regex_time_interval = /^[0-9]{1,2}:[0-9]{2}-[0-9]{1,2}:[0-9]{2}$/;
const regex_interval_array = /^[0-9]{1,2}:[0-9]{2}-[0-9]{1,2}:[0-9]{2}(,[0-9]{1,2}:[0-9]{2}-[0-9]{1,2}:[0-9]{2})*$/;


// date/datetime component
const timespan_input = {
    template: `
        <b-input 
            v-bind:value="value2str"
            v-on:change="onChange" 
            :state="state"
        ></b-input>
    `,  

    props: ['value', 'state', 'array'],

    methods:{
        onChange(value){
            // emit both input and change event on change event 
            // to update the prop value only after the component looses focus
            if (this.array) value = value.split(',');
            this.$emit('input', value);
            this.$emit('change', value);
        },
    },

    computed:{
        value2str() {
            return Array.isArray(this.value) ? this.value.join(',') : this.value;
        }
    }
};




export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate, validators.vuelidate],

    data: function() {
        return {
            settings: {},
            adjust_workhours_duty: 100.0,

            loading: false,
            saving: false,
            submit_error: null,
        };
    },

    validations: {
        settings: {
            shift_hours: {
                required: validators.required,
                format: validators.regex(regex_time_interval),
            },
            break_hours: {
                //required: validators.required, (can be empty)
                format: validators.regex(regex_interval_array),
            },
        },
        adjust_workhours_duty: {
            integer: validators.decimal,
            minValue: validators.minValue(0),
            maxValue: validators.maxValue(200),
        },
    },

    methods: {
        load() {
            server.request(
                this.$parent.module_name, 'settings-get', {}, null,
                (data, extra) => {
                    this.settings = data;
                }
            );
        },

        save() {
            // check if the form validates
            if (this.$v.$invalid) return;

            this.saving = true;
            this.submit_error = null;
            server.request(
                'timesheets-usr', 'settings-save', 
                this.settings, null,
                (data) => {
                    // if ok, go to previous view
                    this.$router.back();
                }, 
                (error) => {
                    this.submit_error = error;
                }
            ).then(()=>{
                 this.saving = false;
            });
        },

        adjust_workhours() {
            if (!this.$v.adjust_workhours_duty) return;
            var dutyHours = this.adjust_workhours_duty/100.0*8.0;
            var whStart = moment().set({hour:8, minute:0});
            if (dutyHours <= 4) {
                var whEnd = moment(whStart).add(dutyHours, 'hours');
                this.settings.shift_hours = whStart.format('HH:mm')+'-'+whEnd.format('HH:mm');
                this.settings.break_hours = '';
            } else {
                var whEnd = moment(whStart).add(dutyHours+0.5, 'hours');
                this.settings.shift_hours = whStart.format('HH:mm')+'-'+whEnd.format('HH:mm');
                this.settings.break_hours = '12:00-12:30';
            }
        }
    },

};
