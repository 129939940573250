import Vue from 'vue';
import server from '../../../client/asis-server.js';
//import * as validators from '../../../client/globals/validators.js';


export default function (store, namespace, module) {
    const store_module = {
        namespaced: true,

        state: () => ({
            people: [],
            show_incomplete: true,
            loading: false,
        }),


        getters: {
            people: state => state.people,
            show_incomplete: state => state.show_incomplete,
            loading: state => state.loading,
        },


        mutations: {
            people(state, people) {
                state.people = people;
            },

            show_incomplete(state, show_incomplete) {
                state.show_incomplete = show_incomplete;
            },

            loading(state, loading) {
                state.loading = loading;
            },
        },


        actions: {
            load(context) {
                context.commit('loading', true);
                return server.request(
                    module, 'people-list', {show_incomplete:context.state.show_incomplete}, null,
                    (data, extra)=>{
                        context.commit('people', data);
                    }
                ).then(()=>{
                    context.commit('loading', false);
                });
            },
        },
    };

    return store_module;
};
