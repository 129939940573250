import * as translators from '../../../../client/globals/translators.js';
import moment from 'moment';

const regex_route_date = /^\d{6}$/;

const component = {

    props: {
        date: Object,
    },

    i18n: {
        messages: {
            cz: {
                month_prev: "Předcházející měsíc",
                month_next: "Následující měsíc",
            }, 
            en: {
                month_prev: "Previous month",
                month_next: "Next month",
            }
        }
    },

    template: `
        <b-row class="text-center">
            <b-col order-sm="2" sm="auto">
                <span class="d-block font-size-xl font-weight-bold">{{moment_format(date,'YYYY/MM')}}</span>
            </b-col>
            <b-col order-sm="1" >
                <b-btn @click="click_prev()"><i class="fas fa-chevron-left"></i> {{$t('month_prev')}}</b-btn>
            </b-col>
            <b-col order-sm="3">
                <b-btn @click="click_next()">{{$t('month_next')}} <i class="fas fa-chevron-right"></i></b-btn>
            </b-col>
        </b-row>
    `,

    methods: {
        click_next() {
            // add 1 month to date and emit change event with new date
            var next = moment(this.date).add(1, 'months');
            this.$emit("change", next);
        },

        click_prev() {
            // subract 1 month from date and emit change event with new date
            var prev = moment(this.date).subtract(1, 'months');
            this.$emit("change", prev);
        },

        moment_format: translators.moment_format,
    }

}

const mixin = {
    components: {
        'month-select': component
    },

    data: function() {
        return {
            date: moment(),
        };
    },


    // react to route changes
    beforeRouteUpdate (to, from, next) {
        if (regex_route_date.test(to.params.date)) {
            this.month_set(to.params.date);
            next();
        } else {
            next(moment().format('YYYYMM'));
        }
    },


    created: function() {
        this.month_set(this.$route.params.date);
    },


    methods: {
        month_set(date) {
            // validate date paramenter
            if (!regex_route_date.test(date))
                date = moment();
            else 
                date = moment(date+'01', 'YYYYMMDD', true);
            if (!date || !date.isValid()) date = moment();

            // set date and refresh data
            // note: child components that are binded to this.date will refresh automaticly when date changes
            if (this.store_commit) this.store_commit('date', {year:date.year(), month:date.month()+1});
            this.date = date;
        },

        month_change(value) {
            this.$router.replace(value.format('YYYYMM'));
        },

        load() {
            // noop
        },
    },

    watch: {
        // reload data whenever date or pid change
        'date': function() {
            this.load();
        },  
    }


}



export default mixin;
