import html from './main-mgr.html';
import lang from './main-mgr.lang.json';
import * as mixins from '../../../client/globals/mixins.js';
import moment from 'moment';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.hasRole,                     // gives access to role-checking in templates
    ],

    data() {
        return {
            current_date: moment().format('YYYYMM'),
        };
    },

    // beforeRouteEnter(to, from) {
    //     // called before the route that renders this component is confirmed.
    //     // does NOT have access to `this` component instance,
    //     // because it has not been created yet when this guard is called!
    // },

    created() {
        if (this.hasRole('budget-administrator')) this.$router.replace({name:'timesheets-mgr-worksitetrips-overview'});
    }

};

