import html from './evaluation-report.html';
import lang from './evaluation-report.lang.json';
import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';
import server from '../../../client/asis-server.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.loadDataOnCreate, 
    ],

    data: function() {
        return {
            activities: [],
            loading: false,
        };
    },

    methods: {
        load() {
            this.loading = true;
            server.request(
                'activities-usr', 'activities-list-evaluations', {}, null,
                (data, extra) => {
                    this.activities = data;
                }
            ).then(()=>{
                this.loading = false;
            });
        },

        date_asis2format: translators.date_asis2format,
    },

    computed: {
        education_students() {
            return this.activities['students'] || [];
        },
        education_lectures() {
            return this.activities['university-lecturing'] || [];
        },
        education_other() {
            return [];
        },
        invited_talks() {
            return (this.activities['conference-contribution'] || []).filter(item => item.data.type=='invited_lecture');
        },
        research_conferences() {
            return this.activities['conference-organization'] || [];
        },
        research_executive_domestic() {
            return (this.activities['membership'] || []).filter(item => item.data.country=='CZ' && item.data.executive_position.length>0);
        },
        research_executive_foreign() {
            return (this.activities['membership'] || []).filter(item => item.data.country!='CZ' && item.data.executive_position.length>0);
        },
        research_refereeing_theses() {
            return (this.activities['referee-report'] || []).filter(item => ['thesis-bsc','thesis-msc','thesis-phd','thesis-hab'].includes(item.data.work_type));
        },
        research_refereeing_papers() {
            return (this.activities['referee-report'] || []).filter(item => item.data.work_type=='paper');
        },
        research_refereeing_projects() {
            return (this.activities['referee-report'] || []).filter(item => item.data.work_type=='project');
        },
        research_refereeing_other() {
            return (this.activities['referee-report'] || []).filter(item => item.data.work_type=='other');
        },
        stays() {
            return this.activities['longterm-stay'] || [];
        },
        outreach_media() {
            return this.activities['media-appearance'] || [];
        },
        outreach_lectures() {
            return this.activities['public-lecture'] || [];
        },
    },

};


