// ASIS - Infomační systém Astronomického ústavu AV ČR
//----------------------------------------------------------------------------
// asis-server.js
// komunikace se serverovou částí
//----------------------------------------------------------------------------
//
//----------------------------------------------------------------------------

// linter directives
// jshint esversion:6


import axios from 'axios';
import fileDownload from 'js-file-download';
import asis_version from '../asis-version.json';
import { asis } from './globals/mixins';

// must correspond to codes in asis-constants.php
const excode_invalid      = 400;
const excode_unauthorized = 401;
const excode_forbidden    = 403;
const excode_gone         = 410;
const excode_expired      = 440;
const excode_busy         = 503;
const excode_version_mismatch = 428;


axios.defaults.timeout = 15000;
axios.defaults.timeoutErrorMessage='timeout'


class ASIS_Server {

    constructor() {
        this.lang = 'cz';
        this.base_url = '/';
        this.asis_php = this.base_url+'asis.php';
    }

    request(module, action, data, extra, success_callback, error_callback, options)
    //! data ... json object
    {
        var self = this;
        options = options || {};

        //if (asis.dev_version && console) console.log('AXIOS REQUEST data-in', data);

        // check if data contains any File objects
        // - use a recursive lambda function to parse through the `data` object and
        //   detect and extract all the Files objects it contains
        // - Files object are all put to file_uploads[] array with the file and 
        //   the serialized path within the original object
        var data_file_uploads = [];
        var extra_file_uploads = [];
        function extractFiles(value, path, files) {
            if (value && typeof value === 'object' && value.constructor === Object) {
                for (let prop in value) extractFiles(value[prop], path+'!'+prop, files);  // . must not be used
            } else
            if (Array.isArray(value)) {
                for (let i = 0; i < value.length; i++) extractFiles(value[i], path+'{'+i+'}', files); // [] must not be used
           } else 
            if (value && typeof value === 'object' && value.constructor === File) {
                // use substring to ommit the leading . in the path
                files.push({name:path.substring(1), file:value});
            } else {
                // noop
            };
        };
        extractFiles(data, '!data', data_file_uploads);
        extractFiles(extra, '!extra', extra_file_uploads);

        if (data_file_uploads.length || extra_file_uploads.length) {
            var request_data = new FormData();
            request_data.append('module', module);
            request_data.append('action', action);
            request_data.append('lang', this.lang);
            request_data.append('version', asis_version.major+'.'+asis_version.minor+'.'+asis_version.patch);
            // attach data as json string
            request_data.append('data', JSON.stringify(data));
            request_data.append('extra', JSON.stringify(extra));
            // attach all files
            for (let f of data_file_uploads) request_data.append(f.name, f.file);
            for (let f of extra_file_uploads) request_data.append(f.name, f.file);
        } else {
            var request_data = {
                module: module,
                action: action,
                lang: this.lang,
                version: asis_version.major+'.'+asis_version.minor+'.'+asis_version.patch,
                data: data||{},
                extra: extra||null,
            };
        }

        if (asis.dev_version && console) {
            //console.log('AXIOS REQUEST data', request_data);
            //if (file_uploads.length > 0) console.log('AXIOS REQUEST uploads', file_uploads);
        }

        var startTime = (new Date()).getTime(), endTime, asis_url = this.asis_php;

        var axios_promise = axios({
            method: 'post',
            url: this.asis_php,
            data: request_data,
            headers: (request_data instanceof FormData) ? {'Content-Type':'multipart/form-data'} : {},
            responseType: options.responseType || 'json',
            timeout: options.timeout || 15000,
            onUploadProgress: function(progressEvent) {
                var uploadPercentage = Math.round((progressEvent.loaded*100)/progressEvent.total);
                if (options.onProgress && (options.onProgress instanceof Function)) options.onProgress(uploadPercentage);
            }
        })
        .then(function(response) {
            // send back timing response if server timing is present
            if (response.headers['x-asis-exectime']) {
                axios({
                    method: 'post',
                    url: asis_url,
                    data: {
                        module: 'asis',
                        action: 'timing',
                        data: {
                            module: module,
                            action: action,
                            server_time: parseInt(response.headers['x-asis-exectime']),
                            response_time: (new Date()).getTime() - startTime,
                        }
                    },
                });
            }


            //if (asis.dev_version && console) console.log('AXIOS REQUEST OK:', response);
            var responseData = response.data || {};

            // if response contains a file attachment (blob)
            if (responseData instanceof Blob) {
                // extract file name from Content-Disposition header
                var fileName = 'unknown';
                var contentDisposition = response.headers['content-disposition'];
                if (contentDisposition) {
                    var fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                    if (fileNameMatch && fileNameMatch.length === 2) fileName = fileNameMatch[1];
                    var fileNameMatch = contentDisposition.match(/filename\*=UTF-8\'\'(.+)/);
                    if (fileNameMatch && fileNameMatch.length === 2) fileName = decodeURIComponent(fileNameMatch[1]);
                }
                // force the browser to download the file
                //if (asis.dev_version && console) console.log('FILE-DOWNLOAD:', fileName, response.headers['content-type']);
                fileDownload(responseData, fileName, response.headers['content-type']);
                return response;
            };

            // if response contains JSON data
            if (responseData.data !== undefined) {
                // call success callback
                if (success_callback) {
                    //TODO: what happens if an error is raised inside the callback
                    // - make the error report clear abou the origin
                    success_callback(responseData.data, responseData.extra, responseData.schema);
                } else {
                    // no default handler
                }
                return responseData.data;
            }

            // if response contains a soft error
            if (responseData.error_code) {
                // call error callback
                if (error_callback)
                    error_callback(responseData.error_code, responseData.error_data);
                else {
                    // default handler
                    var debug_data =
                    "UNHANDLED SOFT ERROR: "+responseData.error_code.toString()+"\n"+
                    "error data: "+(responseData.error_data||'').toString()+"\n"+
                    "asis module: "+module+"\n"+
                    "asis action: "+action+"\n"+
                    self.error_report(debug_data);
                    //asis_dialogs.error('The server refused to process the last request with the following reason: <strong>'+responseData.error+'</strong>.');
                    alert(
                        'The server refused to process the last request with the reason "'+responseData.error_code+'", but the client does not know how to handle this error code.\n\n'+
                        'A report has been sent to the administrators and there is nothing you can do right now '+
                        'other than wait until the problem is resolved.'
                    );
                }
                return responseData.error_code;
            }

            // if response does not contain anything (still success)
            if (success_callback) success_callback({}, null);
            return response;
        })
        .catch(function(error) {
            if (error.response) {
                if (asis.dev_version && console) console.error('AXIOS REQUEST ERROR (w/ response)', error, error.response);
                // the request was made and the server responded with an error status code
                switch (error.response.status) {
                    case excode_invalid:
                        alert('Stránka předala serveru chybné parametry. Administrátor byl o problému informován.\n\nThe page has passed invalid parameters to the server. The administrator has been informed.');
                        break;
                    case excode_gone:
                        window.asis.vue.$bvModal.msgBoxOk(
                            window.asis.vue.$createElement('div', {domProps:{innerHTML:
                                '<p>Požadovaná data již nejsou k dispozici.</p><p>The requested data is no longer available.</p>',
                            }}),
                            {centered: true}
                        );
                        break;
                    case excode_forbidden:
                        // this error may occasionally happen at the client side (eg. an unauthorized user may click an ASIS link in a forwarded email)
                        // and for that reason it is greacefully handled
                        window.asis.vue.$router.push('/');
                        alert('K této části systému nebo funkci nemáte přístup.\n\nYou do not have access to this part of the system or function.');
                        break;
                    case excode_unauthorized:
                    case excode_expired:
                        window.asis.vue.$router.push('/logout', ()=>{
                            window.asis.vue.$bvModal.msgBoxOk(
                                window.asis.vue.$createElement('div', {domProps:{innerHTML:
                                    "<p>Platnost relace vypršela při nečinnosti nebo se změnily parametry připojení a byly jste odhlášeni z bezpečnostních důvodů. "+
                                    "Přihlašte se, prosím, znovu.</p><p>Your session has expired or connection parameters have changed and you have been logged out for security. Click OK and log in again please.</p>"
                                }}),
                                {centered: true}
                            );
                        });
                        break;
                    case excode_busy:
                        //asis_dialogs.warning('K této části systému nebo funkci nemáte přístup.\n\nYou do not have access to this part of the system or function.');
                        alert('Server stále zpracovává předchozí požadavek. Počkejte, až se operace dokončí a poté zadejte nový požadavek.\n\nThe server is still handling previous request. Wait for the response and then continue with a new action.');
                        break;
                    case excode_version_mismatch:
                        window.asis.vue.$bvModal.msgBoxOk(
                            window.asis.vue.$createElement('div', {domProps:{innerHTML:
                                "<p>Na serveru je k dispozici nová verze systému. Po zavření tohoto okna se stránka automaticky přenačte.\n"+
                                "V případě potíží obnovte stránku ručně (F5).</p>"+
                                "<p>A new version of the system is available on the server. Click OK to reload the page.\n"+
                                "In case of difficulties, try to reload the page manually (F5).</p>",
                            }}),
                            {centered: true}
                        ).then(()=>{
                            window.location.reload(true);
                        });
                        break;
                    default:
                        //no reporting needed - server exceptions are reported by the server
                        alert(
                            "ERROR: Server has returned an error code while processing this request.\n\n"+
                            "A report has been sent to the administrators and there is nothing you can do right now "+
                            "other than wait until the problem is resolved."
                        );
                }
            } else if (error.request && !options.ignore_errors) {
                if (asis.dev_version && console) console.error('AXIOS RESPONSE ERROR', error, error.request);
                // the request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest
                var debug_data =
                    "AXIOS ERROR - INVALID RESPONSE\n"+
                    "URL: "+error.request.responseURL+"\n"+
                    "dataType: "+(options.dataType||'json')+"\n"+
                    "asis module: "+module+"\n"+
                    "asis action: "+action+"\n"+
                    "server response: (none)\n"+
                    "error object:\n"+ JSON.stringify(error);
                self.error_report(debug_data);
                //asis_dialogs.error(asis.dev_version ? debug_data :
                //alert(asis.dev_version ? debug_data :
                //    "ASIS server has not responded within the time limit.\n\n"+
                //    "You can try again later, but most likely you will need to wait unitl the problem is resolved by administrators."
                //);
                if (error.message=='timeout') {
                    alert('Server neodpověděl v časovém limitu. Zkuste požadavek odeslat znovu, případně později. Chyba je zalogována. // Server has not responded in the time limit. Try to submit the request again or later. This error is logged.');
                }
            } else if (!options.ignore_errors) {
                if (asis.dev_version && console) console.error('AXIOS REQUEST ERROR (invalid request)', error);
                // something happened in setting up the request that triggered an error
                var debug_data =
                    "AXIOS ERROR - INVALID REQUEST\n"+
                    "URL: "+self.asis_php+"\n"+
                    "dataType: "+(options.dataType||'json')+"\n"+
                    "asis module: "+module+"\n"+
                    "asis action: "+action+"\n"+
                    "data: "+JSON.stringify(data)+"\n"+
                    "error: "+error+"\n"+
                    "error-json: "+JSON.stringify(error)+"\n";
                self.error_report(debug_data);
                //asis_dialogs.error(asis.dev_version ? debug_data :
                alert(asis.dev_version ? debug_data :
                    "There has been an error while setting up the request to the server.\n\n"+
                    "A report has been sent to the administrators and there is nothing you can do right now "+
                    "other than wait until the problem is resolved."
                );
            }
            return {data:null, error:true};
        });

        return axios_promise;
    }


    download(module, action, data) 
    {
        // with POST (only works well for small files):
        //return this.request(module, action, data, {responseType:'blob'});
        
        var query = 'm='+module+'&a='+action;
        for (let param in data) query = query + '&' + param + '=' + encodeURI(data[param]);
        window.location = this.asis_php + '?' + query;
    }


    link(module, action, data) 
    {
        var query = 'm='+module+'&a='+action;
        for (let param in data) query = query + '&' + param + '=' + encodeURI(data[param]);
        return this.asis_php + '?' + query;
    }



    error_report(reported_data) {
        if (asis.dev_version && console) console.log("Sending client error report to server\n"+reported_data.toString());
        axios({
            method: 'post',
            url: this.asis_php,
            data: {module:'asis', action:'error-report', data:{message:reported_data.toString()}}
        });
    }


}  //end of class





// create and export ASIS_Server class instance
// (one instance per application)
export default new ASIS_Server();

