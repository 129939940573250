import html from './main-usr.html';
import lang from './main-usr.lang.json';
import * as mixins from '../../../client/globals/mixins.js';
import server from '../../../client/asis-server.js';
import LeafletMap from './components/leaflet-map.js';
import component_powerline from './components/powerline.js';
import component_waterline from './components/waterline.js';
import component_refpoint from './components/refpoint.js';

export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate, mixins.module_name],

    components: {
        'powerline': component_powerline,
        'waterline': component_waterline,
        'refpoint': component_refpoint,
    },

    data() {
        return {
            map: new LeafletMap(this),
            object: null,
        };
    },

    mounted() {
        this.map.mount('asis-maps-map');
    },

    methods: {
        load() {
        },

        mapobject_attributes_load(object_id) {
            server.request(
                'maps-usr', 'objects-get',
                {object_id, attributes:true}, null,
                (data, extra)=> {
                    this.object = data;
                }
            );
        },

        mapobject_attributes_save() {
            console.log('mapobject_attributes_save');
            //console.log('mapobject_properties', object_id);
            //this.$refs['modal-polyline'].show();
            this.object = null;
        },

        mapobject_attributes_cancel() {
            console.log('mapobject_attributes_cancel');
            //console.log('mapobject_properties', object_id);
            //this.$refs['modal-polyline'].show();
            this.object = null;
        },

    }    

};

