'use strict';
// jshint esversion:6
// jshint -W069


import config from './activities.config.json';
import view_main_usr from './views/main-usr.js';
import view_activities_list_usr from './views/activities-list.js';
import view_activities_edit_usr from './views/activities-edit.js';
import view_evaluation_report_usr from './views/evaluation-report.js';



const module_activities_usr = {
    module_name: 'activities-usr',
    module_class: 'usr',
    title: config.usr.title,
    icon: config.usr.icon,
    routes: [
        // default route (with sub-tabs)
        {path:'', component: view_main_usr, redirect:{name:'activities-usr-list'}, children: [
            {name:'activities-usr-list', path:'list', component:view_activities_list_usr},
            {name:'activities-usr-evaluation-report', path:'evaluation-report', component:view_evaluation_report_usr},
        ]},
        // specific routes
        {name:'activities-usr-add', path:'add/:category', component:view_activities_edit_usr, props:true},
        {name:'activities-usr-edit', path:'edit/:activity_id', component:view_activities_edit_usr},
    ]
};


export default [module_activities_usr];
