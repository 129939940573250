//----------------------------------------------------------------------------
// ASIS Informational System
//----------------------------------------------------------------------------
// indexes/country.js
// component for the list of countries
//----------------------------------------------------------------------------
// (c) Astronomický ústav AV ČR, v.v.i. (see licence file for legal info)
//----------------------------------------------------------------------------

'use strict';
// jshint esversion:6


import Vue from 'vue';
//import jquery from 'jquery';
import server from '../../../client/asis-server.js';

// A general note on two-way data binding:
// The underlying component do not typicly  use v-model, but instead they use
// v-bind and v-on to handle proper 2-way propagation of
// the selected value. v-bind propagates the value from the parent
// to the child (e.g. to v-select) and v-on propagates changes of the value back to
// the parent using an event (in case of a component, $event is the actual value).
// The prop for the value MUST be named `value` for this to work.
// see: https://vuejs.org/v2/guide/components.html#Using-v-model-on-Components
// Example:
//      <v-select
//          v-bind:value="value"
//          v-on:input="$emit('input', $event)">
//      </v-select>



// worksite component
// a drop-down select for worksite
Vue.component('asis-country', {
    template: `
        <v-select 
                v-bind:value="value"
                v-on:input="$emit('input', $event)"
                :options="countries" 
                :searchable="true" 
                :label="selectLabel"
                :reduce="country => country.country_code"
                :clearable="false"
                :disabled="readonly || disabled"
                >
        </v-select>
    `,

    props: ['value','state','readonly','disabled'],

    data: function() {
        return {
            // define a variable to store the list of worksites
            // define a variable to store the list of worksites
            countries: [],    // list of countries
            country: null,   // selected country (this is a member of this.countries - an object)
            selectLabel: 'country_name',
        };
    },

    // load the list of available worksites from server
    created: function() {
        // load the list of countries from server
        server.request(
            'indexes-usr', 'countries-list', {}, null,
            (data)=>{this.countries = data;}
        );
    },

    // watch: {
    //     state() {
    //         // on state change, set/clear the error class of the v-select
    //         //TODO:rewrite this using Vue
    //         //if (this.state) jquery(this.$el).removeClass('is-invalid'); else jquery(this.$el).addClass('is-invalid');
    //     }
    // }

});

