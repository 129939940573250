import Vue from 'vue';
import server from '../../../client/asis-server.js';


export default function (store, namespace, module) {
    const store_module = {
        namespaced: true,

        state: () => ({
            activities: [],
            categories: [],
            filter: 'all',
            loading: false,
        }),


        getters: {
            activities: state => category => {
                return category ? state.activities[category] : [];
            },
            categories: state => state.categories,
            filter: state => state.filter,
            loading: state => state.loading,
        },


        mutations: {
            reset(state) {
                state.activities = [];
                state.categories = [];
                state.filter = default_filter;
                state.loading = false;                
            },

            activities(state, {activities,categories}) {
                state.activities = activities;
                state.categories = categories;
                //console.log('mutation-activities',state.activities,state.categories);
            },

            filter(state, filter) {
                state.filter = filter;
            },

            loading(state, loading) {
                state.loading = loading;
            },
        },


        actions: {
            load(context) {
                context.commit('loading', true);
                return server.request(
                    module, 'activities-list-by-category', {filter:context.state.filter}, null,
                    (data, extra) => {
                        context.commit('activities', {activities:data, categories:extra.categories});
                    }
                ).then(()=>{
                    context.commit('loading', false);
                });
            },

        }    
    };

    return store_module;
};

