import html from './main-mgr.html';
import lang from './main-mgr.lang.json';
import * as mixins from '../../../client/globals/mixins.js';
import server from '../../../client/asis-server.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate, mixins.module_name],

    data() {
        return {
            pending_approvals: null,
        };
    },

    methods: {
        load() {
            return server.request(
                'projects-mgr', 'projects-approvals-pending', {}, null,
                (data, extra) => {
                    this.pending_approvals = (data || []).length;
                }
            );
        },

    }    

};

