'use strict';
// jshint esversion:6
// jshint -W069


import config from './students.config.json';
import view_students_list_usr from './views/students-list-usr.js';
import view_students_list_mgr from './views/students-list-mgr.js';
import view_students_edit from './views/students-edit.js';


const module_students_usr = {
    module_name: 'students-usr',
    module_class: 'usr',
    title: config.usr.title,
    icon: config.usr.icon,
    routes: [
        // default route
        {path:'', redirect:{name:'students-usr-list'}},
        // specific routes
        {name:'students-usr-list', path:'list', component:view_students_list_usr},
        {name:'students-usr-add',  path:'add', component:view_students_edit},
        {name:'students-usr-edit', path:'edit/:student_id', component:view_students_edit},
        {name:'students-usr-copy', path:'copy/:student_id', component:view_students_edit},
    ]
};

const module_students_mgr = {
    module_name: 'students-mgr',
    module_class: 'mgr',
    title: config.mgr.title,
    icon: config.mgr.icon,
    routes: [
        // default route
        {path:'', redirect:{name:'students-mgr-list'}},
        // specific routes
        {name:'students-mgr-list', path:'list', component:view_students_list_mgr},
    ]
};


export default [module_students_usr, module_students_mgr];


