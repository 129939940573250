import html from './overviews-accounting.html';
import lang from './overviews-accounting.lang.json';
import month_select_mixin from './components/month-select.js';
import * as mixins from '../../../client/globals/mixins.js';
import server from '../../../client/asis-server.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [month_select_mixin],

    data: function() {
        return {
            accounting_data: {},
            loading: false,
        };
    },

    methods: {
        load() {
            this.loading = true;
            server.request(
                'timesheets-mgr', 'deductions-accounting', {year:this.date.year(), month:this.date.month()+1}, null,
                (data, extra) => {
                    this.accounting_data = data;
                },
                null,
                {timeout: 30000}
            ).then(()=>{
                this.loading = false;
            });
        },

    },


};
