import VueRouter from 'vue-router';
import VueRouterBackward from 'vue-router-backward';
import modules from './asis-modules.js';
import store from './asis-store.js';

import view_login from './ui/login.js';
import view_logout from './ui/logout.js';
import view_module_list from './ui/module_list.js';


export const global_routes = [
    {name:'home',   path:'/',       component: view_module_list},
    {name:'login',  path:'/login',  component: view_login, meta:{noAuth:true}},
    {name:'logout', path:'/logout', component: view_logout},
];


// // suppress NavigationDuplicated errors
// ['push','replace'].forEach(method => {
//     const originalMethod = VueRouter.prototype[method];
//     VueRouter.prototype[method] = function m(location) {
//         return originalMethod.call(this, location).catch(error => {
//             if (error.name !== 'NavigationDuplicated') {
//                 // capture exception
//             }
//         })
//     };
// });



  // set up vue router
const router = new VueRouter({
    mode: 'history',
    scrollBehavior (to, from, savedPosition) {
        return (savedPosition) ? savedPosition : {x: 0, y: 0};
    },
});



// add hook for authorization check
router.beforeEach((to, from, next) => {
    var user = store.getters['asis/auth/user'];
    if (to.meta.noAuth || user.pid) {
        if (to.meta.roles) {
            let roles = (typeof to.meta.roles === 'string') ? to.meta.roles.split(',') : to.meta.roles;
            if ((roles.filter(v => -1 !== user.roles.indexOf(v))).length == 0) {
                alert('K této funkci systému nemáte přístup.\n\nYou do not have access to this function.');
                next(false);
                return;
            }
        }
        next();
    } else {
        next({
            name:'login',
            query: (to.fullPath!='/logout') ? {redirect:to.fullPath} : null,
        });
    }
});
router.addRoutes(modules.routes.concat(global_routes));

export default VueRouterBackward(router);