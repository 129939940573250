import html from './documents-list.html';
import lang from './documents-list.lang.json';
import server from '../../../client/asis-server.js';
//import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate],

    data: function() {
        var $t = this.$t.bind(this);
        return {
            documents: [],
            documents_fields: [
                {key: 'title_cz', label:$t('list.title_cz'), sortable:true,},
                {key: 'actions', label:'', thStyle:{width:"15%"}}
            ]
        };
    },

    methods: {
        load() {
            server.request(
                'council-mgr', 'documents-list', {}, null,
                (data)=>{this.documents = data;}
            );
        },
    }

};


