import html from './events-edit.html';
import lang from './events-edit.lang.json';
import server from '../../../client/asis-server.js';
import * as directives from '../../../client/globals/directives.js';
import * as validators from '../../../client/globals/validators.js';
import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';
import moment from 'moment';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate, directives.label_required, validators.vuelidate],

    data: function() {
        return {
            calendar_name: null,                // calendar name
            calendar_options: {},               // calendar options
            event: {},                          // event data
            submit_error: null,
            submit_progress: null,
            temp_key: 0,                        //  a temporary key to force rerender the form
            can_change_start_date: true,        
        };
    },

    validations() {
        return {
            event: {
                title: {
                    required: validators.required,
                    maxLength: validators.maxLength(255),
                },
                start: {
                    required: validators.required,
                    date: validators.asis_datetime,
                    dateInFuture: this.can_change_start_date ? validators.dateInFuture('YYYY/MM/DD HH:mm:ss') : validators.alwaysTrue,
                },
                end: {
                    //required: validators.requiredIf('all_day'),
                    date: validators.asis_datetime,
                    dateAfter: validators.dateAfter('start','YYYY/MM/DD HH:mm:ss')
                },
                comment: {
                },
            }
        };
    },

    methods: {
        load() {
            var cal_id = this.$route.params.cal_id;
            var event_id = this.$route.params.event_id || 0;
            var date = this.$route.params.clicked_date || new Date();
            server.request(
                this.$parent.module_name, 'events-get',
                {cal_id, event_id, date:translators.date_date2asis(date)}, null,
                (data, extra)=>{
                    this.event = data;
                    this.calendar_name = extra.calendar_name;
                    this.calendar_options = extra.calendar_options;
                    // dynamicly set language strings
                    if (extra.lang) {
                        this.$i18n.setLocaleMessage('cz', Object.assign(this.$i18n.messages.cz, extra.lang.cz));
                        this.$i18n.setLocaleMessage('en', Object.assign(this.$i18n.messages.en, extra.lang.en));
                        this.temp_key++;
                    }
                    this.can_change_start_date = (this.event.event_id==0) || moment(this.event.start, 'YYYY/MM/DD HH:mm:ss').isAfter(moment().startOf('day'));
                }
            );
        },

        submit() {
            // check if the form validates
            if ((!this.can_update) || (this.$v.$invalid)) return;

            this.submit_error = null;
            this.submit_progress = 0;
            server.request(
                this.$parent.module_name, 'events-save', this.event, {},
                (data) => {
                    // if ok, go to previous view
                    this.$router.back();
                }, 
                (error) => {
                    this.submit_error = error;
                },
                {onProgress:(perc)=>{
                    this.submit_progress = perc
                }, timeout:600000}
            ).then(()=>{
                this.submit_progress = 0;       // hide upload progress bar
            });
            return false;
        },

        remove() {
            this.submit_error = null;
            server.request(
                this.$parent.module_name, 'events-delete', this.event, null, 
                (data) => {
                    // if ok, go to previous view
                    this.$router.back();
                }, 
                (error) => {
                    this.submit_error = error;
                }
            );
            return false;
        },
    },

    computed: {
        can_update() {
            return (this.event.can_update) || (!this.event.event_id);
        },
    }
};

