import html from './publications-import.html';
import lang from './publications-import.lang.json';
import server from '../../../client/asis-server.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [],

    data() {
        return {
            asep_file: null,
            submit_progress: null,
            submit_error: null,
            asep_import_done: null,
            import_warnings: null,
            import_errors: null,
            import_count: null,
        };
    },

    methods: {
        submit(event) {
            // check if the form validates
            //if (this.$v.$invalid) return;

            this.import_warnings = null;
            this.import_count = null;

            this.submit_progress = 0;
            this.submit_error = null;
            server.request(
                'publications-mgr', 'publications-import', 
                {file:this.asep_file}, null,
                (result)=>{
                    //this.$router.back();
                    this.import_warnings = result.warnings;
                    this.import_errors = result.errors;
                    this.import_count = result.record_count;
                },
                (error)=>{
                    this.submit_error = error;      // show error message
                },
                {onProgress:(perc)=>{
                    this.submit_progress=perc
                }, timeout:600000}
            ).then(()=>{
                this.submit_progress = 0;       // hide upload progress bar
            });
        },

    },
};

