import html from './menus-list-mgr.html';
import lang from './menus-list-mgr.lang.json';
import server from '../../../client/asis-server.js';
import * as mixins from '../../../client/globals/mixins.js';
import * as canteen_mixins from './mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate, canteen_mixins.week_shift, canteen_mixins.filters, canteen_mixins.day_heading],

    data() {
        return {
            menus: [],
            print_url: null,
            reservations_url: null,
            deductions_export_url: null,
        };
    },

    methods: {
        load() {
            var from = this.week_start.format('YYYY/MM/DD');  // format to asis date
            server.request(
                'canteen-mgr', 'menus-list', {from}, null,
                (data, extra) => {
                    this.menus = data;
                    this.print_url = extra.print_url;
                    this.reservations_url = extra.reservations_url;
                    this.deductions_export_url = extra.deductions_export_url;
                }
            );
        },

    },

};
