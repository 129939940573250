import html from './project-status.html';
import lang from './project-status.lang.json';
import * as mixins from '../../../../client/globals/mixins.js';
import timeline from 'timeline-vuejs';


export default {
    template: html,

    components: {
        'timeline': timeline,
    },


    i18n: {
        messages: lang
    },

    mixins: [
        mixins.asis,
        mixins.storeAccess,
        mixins.storeGetters(['project','$v']),
        //directives.label_required, 
        //validators.vuelidate
    ],


    data() {
        return {
            messageWhenNoItems: 'There are not items',
            timelineItems: [
            {
                from: new Date(2018, 7),
                title: 'Name',
                description:
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius earum architecto dolor, vitae magnam voluptate accusantium assumenda numquam error mollitia, officia facere consequuntur reprehenderit cum voluptates, ea tempore beatae unde.'
            },
            {
                from: new Date(2016, 1),
                title: 'Name',
                description:
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius earum architecto dolor, vitae magnam voluptate accusantium assumenda numquam error mollitia, officia facere consequuntur reprehenderit cum voluptates, ea tempore beatae unde.'
            },
            {
                from: new Date(2016, 6),
                title: 'Name',
                // description:
                // 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius earum architecto dolor, vitae magnam voluptate accusantium assumenda numquam error mollitia, officia facere consequuntur reprehenderit cum voluptates, ea tempore beatae unde.'
                showDayAndMonth: true,

            },
            {
                from: new Date(2012, 1),
                title: 'Name',
                description:
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius earum architecto dolor, vitae magnam voluptate accusantium assumenda numquam error mollitia, officia facere consequuntur reprehenderit cum voluptates, ea tempore beatae unde.',
                color: '#e74c3c',
                showDayAndMonth: true,
            }
            ]
        };
    },
    
    computed: {
    },

    methods: {
        set_status(status) 
        //! Send project status update request (ask for confirmation first).
        {
            this.$bvModal.msgBoxConfirm(
                this.$t('status_confirmation', [this.$t('project_states')[status].toUpperCase()])
            )
            .then(value => {
                console.log(value);
                if (value) this.store_dispatch('status_update', status);
            })
            .catch(err => {});
        },
    },


};

