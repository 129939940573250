import * as translators from '../../globals/translators.js';

'use strict';
// jshint esversion:6

export const document = {
    template: `
        <div>
            <b-table-simple responsive class="border-bottom mb-2">
                <b-tbody v-if="document">
                    <b-tr style="height:2em;">
                        <b-td v-if="showDescription == 'before'" class="align-middle">
                            {{ document.description }}
                        </b-td>
                        <b-td  class="align-middle" :style="(showDescription!='after')?'width:60%':null">
                            <b-link :href="document.url" class="text-info font-weight-bold" :class="{'text-strikethrough':remove}">{{document.name}}</b-link>
                            <span v-if="remove" class="text-danger ml-2">{{$t('to_be_removed')}}</span>
                            <span v-if="(showDescription=='bellow') && document.description" class="d-block" style="font-size:80%">{{document.description}}</span>
                        </b-td>
                        <b-td v-if="showDescription == 'after'"  class="align-middle" style="width:60%">
                            {{ document.description }}
                        </b-td>
                        <b-td class="align-middle text-right">{{ date_timestamp2ddmmyyyy(document.created) }}</b-td>
                        <b-td class="align-middle text-right">rev.&nbsp;{{ document.revision }}</b-td>
                        <b-td class="align-middle text-right">{{ filesize_auto(document.size) }}</b-td>
                        <b-td v-if="upload_render" v-show="!upload_visible" class="align-middle text-right" style="width:1%">
                            <b-btn @click.stop="upload_show" size="sm" variant="warning" :pressed="upload_visible">{{$t('replace')}}</b-btn>
                        </b-td>
                        <b-td v-if="remove_render" class="align-middle text-right" style="width:1%">
                            <b-btn @click.stop="document_remove" size="sm" variant="outline-danger" :pressed="remove" :title="$t('remove')"><i class="fas fa-times"></i></b-btn>
                        </b-td>
                    </b-tr>
                </b-tbody>
                <b-tbody v-else>
                    <b-tr style="height:2em;">
                        <b-td class="align-middle text-muted">
                            {{ $t('no_document') }}
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <div v-if="upload_render" v-show="upload_visible">
                <p v-if="uploadHeading" class="mt-3 mb-1">{{uploadHeading}}</p>
                <b-form-file
                    v-model:value="upload_file"
                    :accept="uploadAccept"
                    :placeholder="uploadPlaceholder"
                    :class="uploadClass"
                ></b-form-file>
                <asis-validation-feedback v-if="uploadState" :state="uploadState"></asis-validation-feedback>
                <b-form-input v-if="uploadDescription" v-model="upload_description" class="my-2" maxlength="255" :placeholder="$t('description')"></b-form-input>
            </div>
        </div>
    `,

    props: {
        document: Object,   // mandatory properties: document_id, name, description, revision, size, url
        upload: Object,     // use v-bind:upload.sync
        showDescription: {type:String},  // values: before,bellow,after (anything else suppreses description rendering)
        allowAdd: {type:Boolean, default:false},
        allowReplace: {type:Boolean, default:false},
        allowRemove: {type:Boolean, default:false},
        uploadAlwaysShow: {type:Boolean, default:false},
        uploadHeading: {type:String},
        uploadAccept: {type:String},
        uploadPlaceholder: {type:String},
        uploadClass: {type:String},
        uploadState: {type:Object},
        uploadDescription: {type:Boolean, default:true},
    },

    data() {
        return {
            upload_visible: this.uploadAlwaysShow || (this.allowAdd && !this.document),
            upload_file:null,
            upload_description:null,
            remove: false,
        };
    },

    i18n: {
        messages: {
            cz: {
                no_document: "(žádný dokument není nahrán)",
                description: "Popis obsahu souboru",
                replace: "Nahradit",
                remove: "Odstranit dokument",
                to_be_removed: "(bude odstranněn)"
            },
            en: {
                no_document: "(no document has been uploaded)",
                description: "Description of the file content",
                replace: "Replace",
                remove: "Remove document",
                to_be_removed: "(will be removed)"
            }
        }
    },

    methods: {
        upload_show() {
            this.upload_visible = (this.uploadAlwaysShow || !this.upload_visible) && (!this.remove);
            this.upload_file = null;
            this.upload_description = null;
        },

        document_update() {
            this.$emit('update:upload', this.upload_file ? {
                document_id: this.document ? this.document.document_id : null,
                file: this.upload_file,
                description: this.upload_description,
                action: !this.document ? 'add' : 'replace',
            } : null);
        },

        document_remove() {
            if (!this.allowRemove || !this.document) return;
            this.remove = !this.remove;
            this.upload_file = null;
            this.upload_description = null;
            this.$emit('update:upload', this.remove ? {
                document_id: this.document.document_id,
                file: null,
                action: 'remove',
            } : null);
        },

        // translators:
        date_asis2ddmmyyyy: translators.date_asis2ddmmyyyy,
        date_timestamp2ddmmyyyy: translators.date_timestamp2ddmmyyyy,
        filesize_auto: translators.filesize_auto,
    },

    computed: {
        upload_render() {
            // console.log('upload_render', (!this.remove) && ((this.allowAdd && !this.document) || (this.allowReplace && this.document)),
            // (!this.remove),
            // ((this.allowAdd && !this.document) || (this.allowReplace && this.document)),
            // (this.allowAdd && !this.document), (this.allowReplace && this.document));
            return (!this.remove) && ((this.allowAdd && !this.document) || (this.allowReplace && this.document));
        },
        remove_render() {
            return (this.allowRemove && this.document);
        },
    },

    watch: {
		upload_file(value) {
            this.document_update(value);
		},
		upload_description(value) {
            this.document_update(value);
		},
    },
};




















export const document_collection = {
    template: `
        <div>
            <b-table-simple responsive class="border-bottom mb-2">
                <b-tbody v-if="documents_render">
                    <template v-for="(document,index) in documents">
                    <b-tr :class="{'b-table-has-details' :replace_visible[index]}" :variant="files_removed[index]?'danger':null" style="height:2em;">
                        <b-td v-if="showDescription == 'before'" class="align-middle">
                            {{ document.description }}
                        </b-td>
                        <b-td  class="align-middle" :style="(showDescription!='after')?'width:60%':null">
                            <b-link :href="document.url" class="text-info font-weight-bold" :class="{'text-strikethrough':files_removed[index]}">{{document.name}}</b-link>
                            <span v-if="(showDescription=='bellow') && document.description" class="d-block" style="font-size:80%">{{document.description}}</span>
                        </b-td>
                        <b-td v-if="showDescription == 'after'" class="align-middle" style="width:60%">
                            {{ document.description }}
                        </b-td>
                        <b-td class="align-middle text-right text-nowrap">{{ date_timestamp2ddmmyyyy(document.created) }}</b-td>
                        <b-td class="align-middle text-right text-nowrap">rev.&nbsp;{{ document.revision }}</b-td>
                        <b-td class="align-middle text-right text-nowrap">{{ filesize_auto(document.size) }}</b-td>
                        <b-td v-if="allowReplace" class="align-middle text-right" style="width:1%">
                            <b-btn @click.stop="upload_show(index)" size="sm" variant="warning" :pressed="replace_visible[index]" :disabled="files_removed[index]">{{$t('replace')}}</b-btn>
                        </b-td>
                        <b-td v-if="allowRemove" class="align-middle text-right" style="width:1%">
                            <b-btn @click.stop="document_remove(index)" size="sm" variant="outline-danger" :title="$t('remove')"><i class="fas fa-times"></i></b-btn>
                        </b-td>
                    </b-tr>

                    <b-tr v-if="replace_visible[index]" class="b-table-details" variant="warning">
                        <b-td colspan="10">
                            <b-form-file
                                v-model="replace_files[index].file"
                                :accept="uploadAccept"
                                :placeholder="uploadPlaceholder"
                                :class="uploadClass"
                            ></b-form-file>
                            <asis-validation-feedback v-if="uploadState" :state="uploadState"></asis-validation-feedback>
                            <b-form-input v-if="show_description" v-model="replace_files[index].description" class="my-2" maxlength="255" :placeholder="$t('description')"></b-form-input>
                        </b-td>
                    </b-tr>
                    </template>
                </b-tbody>
                <b-tbody v-else>
                    <b-tr style="height:2em;">
                        <b-td class="align-middle text-muted">
                            {{ $t('no_document') }}
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>

            <div v-if="allowAdd">
                <p v-if="uploadHeading && files_added.length>0" class="mt-3 mb-1">{{uploadHeading}}</p>
                <div v-for="(f,index) in files_added" class="mb-3">
                    <b-form-file
                        v-model:value="f.file"
                        :accept="uploadAccept"
                        :placeholder="uploadPlaceholder"
                        :class="uploadClass"
                    ></b-form-file>
                    <div v-if="uploadDescription">
                        <b-form-input v-model="f.description" class="my-2" maxlength="255" :placeholder="$t('description')"></b-form-input>
                        <asis-validation-feedback :state="f.description || !uploadDescriptionRequired"></asis-validation-feedback>
                    </div>
                </div>
                <b-button variant="outline-success" size="sm" @click="files_added_expand" v-show="files_added.length==0 || files_added[files_added.length-1].file">{{$t('file_add')}}</b-button>
                <b-button variant="outline-warning" size="sm" @click="files_added_shrink" v-show="files_added.length>0">{{$t('file_remove')}}</b-button>
            </div>

            <!--
            <pre>{{files_added}}</pre>
            <pre>{{files_replaced}}</pre>
            <pre>{{files_removed}}</pre>
            -->
        </div>
    `,

    props: {
        documents: Array,   // mandatory properties: document_id, name, description, revision, size, url
        showDescription: {type:String},  // values: before,bellow,after (anything else suppreses description rendering)
        allowAdd: {type:Boolean, default:false},
        allowReplace: {type:Boolean, default:false},
        allowRemove: {type:Boolean, default:false},
        uploadHeading: {type:String},
        uploadAccept: {type:String},
        uploadPlaceholder: {type:String},
        uploadClass: {type:String},
        uploadState: {type:Object},
        uploadDescription: {type:Boolean, default:true},
        uploadDescriptionRequired: {type:Boolean, default:false},

        // documents: Array,   // mandatory properties: document_id, name, description, revision, size, url
        // showDescription: {type:String},  // values: before,bellow (anything else suppreses description rendering)
        // uploadAllowed: {type:Boolean, default:false},
        // uploadHeading: {type:String},
        // uploadFile: {type:Object},
        // uploadFileProps: {type:Object, default:null},
        // uploadAccept: {type:String},
        // uploadPlaceholder: {type:String},
        // uploadClass: {type:String},
        // uploadState: {type:Object},
        // uploadDescription: {type:Boolean, default:true},
        // replaceAllowed: {type:Boolean, default:false},
        // removeAllowed: {type:Boolean, default:false},
    },

    data() {
        return {
            replace_visible: [],
            files_removed: [],
            files_replaced: [],
            files_added: [],

            //temp:
            replaceAllowed:false,
            remove: false,
        };
    },

    i18n: {
        messages: {
            cz: {
                no_document: "(žádné dokumenty nejsou nahrány)",
                description: "Popis obsahu souboru",
                replace: "Nahradit",
                remove: "Odstranit dokument",
                file_add: "Přidat soubor",
                file_remove: "Odebrat poslední",
            },
            en: {
                no_document: "(no documents have been uploaded)",
                description: "Description of the file content",
                replace: "Replace",
                remove: "Remove document",
                file_add: "Add file",
                file_remove: "Remove last",
            }
        }
    },

    methods: {
        upload_show(index) {
            this.$set(this.replace_visible, index, this.replaceAllowed && !this.replace_visible[index]);

            // reactively update replace_files
            if (this.replace_visible[index]) 
                this.$set(this.replace_files, index, {
                    document_id: this.documents[index].document_id,
                    file: null,
                    description: this.documents[index].description,
                });
            else
                this.$set(this.replace_files, index, null);
        },

        document_remove(index) {
            this.$set(this.files_removed, index, !(this.files_removed[index] || false));
            this.emit_update_event();
        },

        emit_update_event() {
            var uploads = [];
            for (let f of this.files_added) if (f.file) uploads.push({
                file: f.file,
                description: f.description || '',
                document_id: null,
                action: 'add',
            });
            for (let f of this.files_replaced) if (f.file) uploads.push({
                file: f.file,
                description: f.description || '',
                document_id: f.document_id,
                action: 'replace',
            });
            for (let i=0; i<this.files_removed.length; i++) if (this.files_removed[i]) uploads.push({
                document_id: this.documents[i].document_id,
                action: 'remove',
            });
            this.$emit('update:uploads', uploads);
        },

        files_added_expand() {
            this.files_added.push({file:null, description:null});
        },

        files_added_shrink() {
            this.files_added.splice(-1,1);
        },

        // translators:
        date_asis2ddmmyyyy: translators.date_asis2ddmmyyyy,
        date_timestamp2ddmmyyyy: translators.date_timestamp2ddmmyyyy,
        filesize_auto: translators.filesize_auto,
    },

    computed: {
        documents_render() {
            return Array.isArray(this.documents) && (this.documents.length > 0);
        },

        show_description() {
            return this.uploadDescription && !(this.uploadFileProps||{}).hasOwnProperty('description');
        },
    },

    watch: {
		files_added: {
            handler: function(val, old_val) { this.emit_update_event() },
            deep: true,
        },
    },
};

















export const xdocument_collection = {
    template: `
        <b-table-simple responsive class="border-bottom mb-2">
            <b-tbody v-if="documents.length>0">
                <b-tr v-for="document in documents" :key="document.document_id">
                    <b-td style="width:60%">
                        <b-link v-if="document" :href="document.link" class="text-info font-weight-bold">{{document.name}}</b-link>
                        <span v-else class="text-muted">{{ $t('no_document') }}</span>
                    </b-td>
                    <b-td>{{ document ? date_timestamp2ddmmyyyy(document.created) : '' }}</b-td>
                    <b-td>{{ document ? 'rev.&nbsp;'+document.revision : '' }}</b-td>
                    <b-td>{{ document ? filesize_auto(document.size) : '' }}</b-td>
                </b-tr>
            </b-tbody>
            <b-tbody v-else>
                <b-tr>
                    <b-td>
                        <span class="text-muted">{{ $t('no_document') }}</span>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    `,

    props: {
        documents: Array
    },

    i18n: {
        messages: {
            cz: {
                "no_document": "(žádné dokumenty)"
            },
            en: {
                "no_document": "(no documents)"
            }
        }
    },

    methods: {
        // translators:
        date_asis2ddmmyyyy: translators.date_asis2ddmmyyyy,
        date_timestamp2ddmmyyyy: translators.date_timestamp2ddmmyyyy,
        filesize_auto: translators.filesize_auto,
    }
};
