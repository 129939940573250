'use strict';
// jshint esversion:6
// jshint -W069


import config from './projects.config.json';
import view_main_mgr from './views/main-mgr.js';
import view_projects_list_usr from './views/projects-list-usr.js';
import view_projects_list_mgr from './views/projects-list-mgr.js';
import view_projects_edit_usr from './views/projects-edit-usr.js';
import view_projects_edit_mgr from './views/projects-edit-mgr.js';
import view_projects_view from './views/projects-view.js';
import view_projects_summary_usr from './views/projects-summary.js';
import view_projects_reporting from './views/projects-reporting.js';
import view_projects_pending_approvals_mgr from './views/projects-pending-approvals-mgr.js';
import view_projects_approval_mgr from './views/projects-approval-mgr.js';


//const view_projects_pending_approvals_mgr = {};

const module_projects_usr = {
    module_name: 'projects-usr',
    module_class: 'usr',
    title: config.usr.title,
    icon: config.usr.icon,
    routes: [
        // default route
        {path:'', redirect:{name:'projects-usr-list'}},
        // specific routes
        {name:'projects-usr-list', path:'list', component:view_projects_list_usr},
        {name:'projects-usr-add',  path:'add', component:view_projects_edit_usr},
        {name:'projects-usr-edit',  path:'edit/:project_id', component:view_projects_edit_usr},
        {name:'projects-usr-view',  path:'view/:project_id', component:view_projects_edit_usr, props:{readonly:true}},
        {name:'projects-usr-summary',  path:'summary/:project_id', component:view_projects_summary_usr},
    ]
};

const module_projects_mgr = {
    module_name: 'projects-mgr',
    module_class: 'mgr',
    title: config.mgr.title,
    icon: config.mgr.icon,
    routes: [
        // default route (with sub-tabs)
        {path:'', component: view_main_mgr, redirect:{name:'projects-mgr-list'}, children: [
            {name:'projects-mgr-list', path:'list', component:view_projects_list_mgr},
            {name:'projects-mgr-pending-approvals', path:'approvals', component:view_projects_pending_approvals_mgr},
            {name:'projects-mgr-reporting', path:'reporting', component:view_projects_reporting},
        ]},
        // specific routes
        {name:'projects-mgr-edit', path:'edit/:project_id', component:view_projects_edit_mgr},
        {name:'projects-mgr-view', path:'view/:project_id', component:view_projects_view},
        {name:'projects-mgr-approval', path:'approval/:project_id/:approve_request_id/:approve_request_secret', component:view_projects_approval_mgr},
    ]
};


const module_projects_pub = {
    module_name: 'projects',
    module_class: 'pub',
    routes: [
        // public routes
        {name:'projects-pub-view', path:':authorization/:project_id/:secret', component:view_projects_view, meta:{noAuth:true}},
    ]
};


export default [module_projects_usr, module_projects_mgr, module_projects_pub];
