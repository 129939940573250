'use strict';
// jshint esversion:6
// jshint -W069


import config from './directives.config.json';
import view_directives_list from './views/directives-list.js';
import view_directives_edit from './views/directives-edit.js';


const module_directives_mgr = {
    module_name: 'directives-mgr',
    module_class: 'mgr',
    title: config.mgr.title,
    icon: config.mgr.icon,
    routes: [
        // default route
        {path:'', redirect:{name:'directives-list'}},
        // specific routes
        {name:'directives-list', path:'list', component:view_directives_list},
        {name:'directives-add', path:'add', component:view_directives_edit},
        {name:'directives-edit', path:'edit/:directive_id', component:view_directives_edit}
    ]
};


export default [module_directives_mgr];


