import html from './main-usr.html';
import lang from './main-usr.lang.json';


export default {
    template: html,

    i18n: {
        messages: lang
    },
};

