import html from './project-approvals-usr.html';
import lang from './project-approvals-usr.lang.json';
import * as directives from '../../../../client/globals/directives.js';
import * as validators from '../../../../client/globals/validators.js';
import * as translators from '../../../../client/globals/translators.js';
import * as mixins from '../../../../client/globals/mixins.js';
import server from '../../../../client/asis-server.js';


export default {
    template: html,

    props: {
        readonly:Boolean,
    },

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.asis,
        mixins.storeAccess,
        mixins.storeGetters([
            'project','$v','pending_approval_financial', 'pending_approval_council', 
            'save_errors','missing_abstract','missing_budget','missing_proposal'
        ]),
    ],

    data() {
        return {
            financial_approval_comments: '',
            financial_approval_running: false,
            financial_approval_sent: false,
            financial_approval_error: null,
            council_approval_comments: '',
            council_approval_running: false,
            council_approval_sent: false,
            council_approval_error: null,
        };
    },

    methods: {
        async send_financial_approval() {
            this.financial_approval_running = true;

            // save project and check any save errors before the approval request
            await this.store_dispatch('save');
            if (this.save_errors.length > 0) {
                this.financial_approval_running = false;
                this.$bvModal.msgBoxOk(this.$t('save_errors'));
                return false;
            }

            await server.request(
                'projects-usr', 'projects-approval-request-financial', 
                {project_id: this.project.project_id, comments:this.financial_approval_comments}, null,
                (result) => {
                    this.financial_approval_sent = true;
                },
                (error_code) => {
                    this.financial_approval_error = error_code;
                }
            );

            this.financial_approval_running = false;
        },

        async send_council_approval() {
            this.council_approval_running = true;

            // save project and check any save errors before the approval request
            await this.store_dispatch('save');
            if (this.save_errors.length > 0) {
                this.financial_approval_running = false;
                this.$bvModal.msgBoxOk(this.$t('save_errors'));
                return false;
            }

            await server.request(
                'projects-usr', 'projects-approval-request-council', 
                {project_id: this.project.project_id, comments:this.council_approval_comments}, null,
                (result) => {
                    this.council_approval_sent = true;
                },
                (error_code) => {
                    this.council_approval_error = error_code;
                }
            );

            this.council_approval_running = false;
        },

        // translators:
        date_timestamp2ddmmyyyy: translators.date_timestamp2ddmmyyyy,
    },

};
