import html from './worksitetrips-usr-request.html';
import lang from './worksitetrips-usr-request.lang.json';
import * as directives from '../../../../client/globals/directives.js';
import * as validators from '../../../../client/globals/validators.js';
import * as translators from '../../../../client/globals/translators.js';
import * as mixins from '../../../../client/globals/mixins.js';
import server from '../../../../client/asis-server.js';
import moment from 'moment';



export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.module_name, mixins.loadDataOnCreate, 
        mixins.storeGetters(['user'],'asis/auth'),              // provides this.user in the component
        directives.label_required, validators.vuelidate,
    ],

    data: function() {
        var date = moment(this.$route.params.date, 'YYYYMMDD');
        return {
            request: {
                date: date.toDate(),
                destination: '',
                purpose: '',
                transport: null,
                comment: '',
            },
            submit_error: null,
            calendar_date_min: moment().toDate(),
            calendar_date_max: moment().endOf('month'),
        };
    },

    validations() {
        return {
            request: {
                destination: {
                    required: validators.required,
                },
                purpose: {
                    required: validators.required,
                    maxLength: validators.maxLength(255),
                },
                // date: {
                //     required: validators.required,
                //     date: validators.asis_datetime,
                //     dateInFuture: this.can_change_start_date ? validators.dateInFuture('YYYY/MM/DD HH:mm:ss') : validators.alwaysTrue,
                // },
                date:{
                    required: validators.required,
                },
                transport:{
                    required: validators.required,
                },
                comment: {
                    maxLength: validators.maxLength(64000),
                },
            }
        };
    },

    methods: {
        submit() {
            server.request(
                'timesheets-usr', 'worksitetrip-request', 
                Object.assign({}, this.request, {date: translators.jsdate_format(this.request.date, translators.asis_date_format)})
            ).then(()=>{
                this.$bvModal.msgBoxOk(this.$t('request_sent'),{centered:true}).then(value => {
                    this.$router.go(-1);
                });
            });
        },

    }

};
