import html from './drives-list-mgr.html';
import lang from './drives-list-mgr.lang.json';
import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';
import store from './drives-list-store.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.module_name,
        mixins.loadDataOnCreate, 
        mixins.store_create(store, 'drives-list-mgr'), 
        mixins.storeGetters(['cars','car','drives','hide_old','loading']), 
        mixins.storeAccess, 
    ],

    data: function() {
        var $t = this.$t.bind(this);
        return {
            drives_fields: [
                {key:'date_start', label:$t('list.date_start'), class:'min', formatter:translators.datetime_asis2ddmmyyyy_hhmm},
                {key:'date_end', label:$t('list.date_end'), class:'min', formatter:translators.datetime_asis2ddmmyyyy_hhmm},
                {key:'car', label:$t('list.car'), class:'min'},
                {key:'pid', label:$t('list.pid'), class:'min'},
                {key:'origin', label:$t('list.origin')},
                {key:'destination', label:$t('list.destination')},
                {key:'odo', label:$t('list.odo'), class:'min'},
                {key:'distance', label:$t('list.distance'), class:'min'},
                {key:'fueling_volume', label:$t('list.fueling'), class:'min'},
                {key:'actions', label:'', class:'min'},
            ],
        };
    },

    methods: {
        load() {
            this.store_dispatch('load_cars').then(() => {
                console.log(this.cars, this.car);
                if (!this.car && this.cars.length>0) this.store_commit('car', this.cars[0].regplate);
                console.log(this.cars, this.car);
                this.store_dispatch('load_drives');
            });
        }
    },

    computed: {
        car_names: function() {
            return this.cars.map(c => ({
                value: c.regplate, 
                text:  c.regplate + (c.alias ? ' ('+c.alias+')' : '')
            }));
        },
        hide_old: {
            get () {
                return this.store_getter('hide_old');
            },
            set (value) {
                this.store_commit('hide_old', value);
                this.store_dispatch('load_drives', value);
            }
        },
    },


};


