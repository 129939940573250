import html from './logout.html';
import lang from './logout.lang.json';
import server from '../asis-server.js';
import store from '../asis-store.js';

const logout = {
    template: html,

    i18n: {
        messages: lang
    },

    created: function() {
        store.dispatch('asis/auth/logout');
    }
};

export default logout;

