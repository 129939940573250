'use strict';
// jshint esversion:6
// jshint -W069


import config from './test.config.json';
import view_test from './views/test.js';


const module_test_usr = {
    module_name: 'test-usr',
    module_class: 'usr',
    title: config.usr.title,
    icon: config.usr.icon,
    routes: [
        {path:'', component:view_test}
    ],
};


export default [module_test_usr];
