import html from './personalinfo-edit.html';
import lang from './personalinfo-edit.lang.json';
import server from '../../../client/asis-server.js';
import * as directives from '../../../client/globals/directives.js';
import * as validators from '../../../client/globals/validators.js';
import * as mixins from '../../../client/globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate, mixins.module_name, directives.label_required, validators.vuelidate],

    data: function() {
        return {
            personalinfo: null,
            photo_url: null,
            employee_card_url: null,
            uploaded_photo: null,
            submit_error: null,
            submit_progress: null,
            loading:  false,
        };
    },

    validations: {
        personalinfo: {
            phone1: {
                phonenumber: validators.phonenumber,
            },
            phone2: {
                phonenumber: validators.phonenumber,
            },
            mobile: {
                phonenumber: validators.phonenumber_international,
            },
            room: {
                format: validators.regex(/^[A-Z]\d{1,3}$/),
            },
            email: {
                email: validators.email,
            },
            web: {
                email: validators.url,
            },
            address: {
                maxLength: validators.maxLength(255),
            },
            researcherid: {
                format: validators.regex(/^\w-\d{4}-\d{4}$/),
            },
            orcid: {
                orcidid: validators.orcidid,
            },
            profile_cz: {
                maxWords: validators.maxWords(3),
            },
            profile_en: {
                maxWords: validators.maxWords(3),
            },
            description_cz: {
                minLength: validators.minLength(50),
                maxLength: validators.maxLength(400),
            },
            description_en: {
                minLength: validators.minLength(50),
                maxLength: validators.maxLength(400),
            },
            titles_before: {
            },
            titles_after: {
            },
        },
        uploaded_photo: {
            file: validators.file,
        }
    },

    methods: {
        load() {
            this.loading = true;
            server.request(
                this.module_name, 'personalinfo-get',
                {}, null,
                (data,extra)=>{
                    this.personalinfo = data;
                    this.photo_url = extra.photo_url;
                    this.employee_card_url = extra.employee_card_url;
                }
            ).then(()=>{
                this.loading = false;
            })
        },

        submit(event) {
            // check if the form validates
            if (this.$v.$invalid) return;

            // send to server
            this.submit_progress = 0;
            this.submit_error = null;
            server.request(
                this.module_name, 'personalinfo-save', 
                this.personalinfo, {uploaded_photo:this.uploaded_photo}, 
                (result)=>{
                    //this.$router.back();
                    this.$bvModal.msgBoxOk(this.$t('saved'),{centered:true}).catch(e=>{});
                },
                (error)=>{
                    this.submit_error = error;      // show error message
                },
                {onProgress:(perc)=>{
                    this.submit_progress=perc
                }, timeout:600000}
            ).then(()=>{
                this.submit_progress = null;
            });
            return false;
        }
    },

    watch: {
        // watch for changes in publish.profile
        // if publish.profile is not set, deselect all other
        'personalinfo.publish.profile': function(newVal, oldVal) {
            if (newVal == false) {
                this.personalinfo.publish.phone = false;
                this.personalinfo.publish.mobile = false;
                this.personalinfo.publish.email = false;
                this.personalinfo.publish.web = false;
                this.personalinfo.publish.photo = false;
            }
        },
        // update photo if file changes
        'uploaded_photo': function(newVal, oldVal) {
            if (newVal instanceof File) {
                // https://stackoverflow.com/questions/3814231/loading-an-image-to-a-img-from-input-file
                var selectedFile = newVal;
                var reader = new FileReader();
                var imgtag = document.getElementById("personalinfo-photo-img");
                imgtag.title = selectedFile.name;
                reader.onload = function(event) {
                    imgtag.src = event.target.result;
                };
                reader.readAsDataURL(selectedFile);
            }
        }
 }

}
