import html from './publications-edit.html';
import lang from './publications-edit.lang.json';
import * as mixins from '../../../client/globals/mixins.js';
import server from '../../../client/asis-server.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate],

    data() {
        return {
            publication: null,
            submit_error: null,
        };
    },

    methods: {
        load() {
            server.request(
                'publications-mgr', 'publications-get',
                {publication_id: this.$route.params.publication_id},
                null,
                (data, extra, schema)=>{
                    this.publication = data;
                }
            );
        },

        submit(event) {
            // // check if the form validates
            // //if (this.$v.$invalid) return;

            // this.import_warnings = null;
            // this.import_count = null;

            // this.submit_progress = 0;
            // this.submit_error = null;
            // server.request(
            //     'publications-mgr', 'publications-import', 
            //     {file:this.asep_file},
            //     (result)=>{
            //         //this.$router.back();
            //         this.import_warnings = result.warnings;
            //         this.import_count = result.record_count;
            //         this.submit_progress = 0;       // hide upload progress bar
            //     },
            //     (error)=>{
            //         this.submit_error = error;      // show error message
            //         this.submit_progress = 0;       // hide upload progress bar
            //     },
            //     {onProgress:(perc)=>{
            //         this.submit_progress=perc
            //     }, timeout:600000},
            // );
        },

    },

    computed: {
        publication_details: {
            get() { return this.publication ? JSON.stringify(this.publication.details, null, 4) : ''; },
            set(value) { /*noop*/ }
        }
    }
};

