import html from './university-lecturing.html';
import lang from './university-lecturing.lang.json';
import * as directives from '../../../../client/globals/directives.js';
import * as mixins from '../../../../client/globals/mixins.js';
import * as validators from '../../../../client/globals/validators.js';


export default {
    template: html,

    props: {
        data: Object,
        readonly: Boolean,
        v_state: Object,
    },

    mixins: [
        directives.label_required, 
        validators.vuelidate
    ],

    i18n: {
        messages: lang
    },

    data() {
        let $t = this.$t.bind(this);
        return {
            semester_options: Object.keys($t('semesters')).map((key) => ({
                value: key,
                text: $t('semesters')[key],
            })),
            course_type_options: Object.keys($t('course_types')).map((key) => ({
                value: key,
                text: $t('course_types')[key],
            })),
        };
    },

    mounted() {
        // emit update event to initialize the validation state
        this.$emit('update:v_state', this.$v);
    },

    validations() {
        return {
            data: {
                academic_year: {
                    required: validators.required,
                    format: validators.regex(/^\d{4}\/\d{4}$/),
                },
                semester: {
                    required: validators.required,
                },
                university: {
                    required: validators.required,
                },
                faculty: {
                    required: validators.required,
                },
                course_title: {
                    required: validators.required,
                },
                course_type: {
                    required: validators.required,
                },
                hours_per_week: {
                    required: validators.required,
                    integer: validators.integer,
                    minValue: validators.minValue(1),
                },
                comments: {},
            },
        };
    },

    watch: {
        '$v.$invalid': function() {
            // emit update event on each change of the validation state
            this.$emit('update:v_state', this.$v);
        },
    },    

};


