import html from './students-list-mgr.html';
import lang from './students-list-mgr.lang.json';
import server from '../../../client/asis-server.js';
import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';
import student_table from './components/student-table.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.module_name, mixins.loadDataOnCreate],

    components: {'student-table': student_table},

    data: function() {
        return {
            students: [],
            loading: false,
        };
    },

    methods: {
        load() {
            this.loading = true;
            server.request(
                this.module_name, 'students-list', {}, null,
                (data)=>{
                    this.students = data;
                }
            ).then(()=>{
                this.loading = false;
            });
        },

    },

    computed: {
        university_active() {return this.students.filter(s => s.is_university && s.is_active); },
        university_finished() {return this.students.filter(s => s.is_university && !s.is_active); },
        other_active() {return this.students.filter(s => !s.is_university && s.is_active); },
        other_finished() {return this.students.filter(s => !s.is_university && !s.is_active); },
    }


};

 
