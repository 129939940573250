import html from './projects-pending-approvals-mgr.html';
import lang from './projects-pending-approvals-mgr.lang.json';
import server from '../../../client/asis-server.js';
import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate, mixins.module_name],

    data: function() {
        var locale = this.$i18n.locale;
        var $t = this.$t.bind(this);
        return {
            projects_fields: [
                {key:'pi_last_name', label:$t('pi_last_name')},
                //{key:'project_acronym', label:$t('project_acronym')},
                {key:'project_title', label:$t('project_title')},
                {key:'project_start', label:$t('project_start'), formatter:translators.date_asis2mmyyyy},
                {key:'project_end', label:$t('project_end'), formatter:translators.date_asis2mmyyyy},
                {key:'timestamp', label:$t('timestamp'), formatter:translators.datetime_timestamp2ddmmyyyy_hhmm},
                {key:'actions', label:''},
            ],
            projects: [],
            loading: false,
        };
    },

    methods: {
        load() {
            this.loading = true;
            return server.request(
                this.module_name, 'projects-approvals-pending', {}, null,
                (data, extra) => {
                    this.projects = data;            
                }
            ).then(()=>{
                this.loading = false;
            });
        },
    },

};


