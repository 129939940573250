import html from './module_list.html';
import lang from './module_list.lang.json';
import * as mixins from '../globals/mixins.js';
import i18n from '../asis-i18n.js';

export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.storeAccess,
        mixins.storeGetters(['modules_usr','modules_mgr','modules_loading'], 'asis/ui'), 
    ],

    created() {
        this.store_dispatch('asis/ui/modules_list');
    },

};
