import jquery from 'jquery';

export const label_required = {
    directives: {
        'label-required': {
            bind(el, binding, vnode) {
                var label = jquery(el).children("label");
                if (label) label.html(label.html()+'<span class="text-danger">*</span>');
            }
        }
    }
}
