import html from './menus-edit.html';
import lang from './menus-edit.lang.json';
import server from '../../../client/asis-server.js';
import * as directives from '../../../client/globals/directives.js';
import * as validators from '../../../client/globals/validators.js';
import * as mixins from '../../../client/globals/mixins.js';
import moment from 'moment';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate, directives.label_required, validators.vuelidate],

    data: function() {
        return {
            menu: {},                       // server data
            replacement_subsidy_allowed: false,
            submit_error: null,
        };
    },

    validations: {
        menu: {
            date: {
                required: validators.required,
            },
            soup: {
                required: validators.requiredUnless('not_cooking')
            },
            soup_alergens: {
                format: validators.regex(/^\d+(?:,\d+)*,{0,1}$/)
            },
            meal1: {
                required: validators.requiredUnless('not_cooking')
            },
            meal1_alergens: {
                format: validators.regex(/^\d+(?:,\d+)*,{0,1}$/)
            },
            meal1_max_portions: {
                integer: validators.integer,
                minValue: validators.minValue(0),
            },
            meal2: {
                //required: validators.requiredUnless('not_cooking')
            },
            meal2_alergens: {
                format: validators.regex(/^\d+(?:,\d+)*,{0,1}$/)
            },
            meal2_max_portions: {
                integer: validators.integer,
                minValue: validators.minValue(0),
            },
            meal3: {
            },
            meal3_alergens: {
                format: validators.regex(/^\d+(?:,\d+)*,{0,1}$/)
            },
            meal3_max_portions: {
                integer: validators.integer,
                minValue: validators.minValue(0),
            },
            supplement: {
            },
            supplement_alergens: {
                format: validators.regex(/^\d+(?:,\d+)*,{0,1}$/)
            },
            comment: {
            },
        }
    },

    methods: {
        load() {
            server.request(
                'canteen-mgr', 'menus-get',
                {menu_id: this.$route.params.menu_id}, null,
                (data,extra)=>{
                    this.menu = data;
                    this.replacement_subsidy_allowed = extra.replacement_subsidy_allowed;
                }
            );
        },

        submit(event) {
            // check if the form validates
            if (this.$v.$invalid) return;

            // send to server
            this.submit_error = null;
            server.request(
                this.$parent.module_name, 'menus-save', 
                this.menu, null, 
                (result)=>{
                    this.$router.back();
                (error)=>{
                    this.submit_error = error;      // show error message
                }
            });
            return false;
        },

        toggleNotCooking(checked) {
            if (!checked) this.menu.replacement_subsidy = 0;
        },

    },

    computed: {
        // a computed getter
        dayofweek: function () {
            // `this` points to the vm instance
            const daysofweek_cz = ['pondělí','úterý','středa','čtvrtek','pátek','sobota','neděle'];
            var d = moment(this.menu.date,  "YYYY/MM/DD");
            return daysofweek_cz[d.isoWeekday()-1] + ', ' + d.week() + '. týden';
        }
    }
}
