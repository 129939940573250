import Vue from 'vue';
import VueI18n from 'vue-i18n'
import config from '../../conf/asis-config-client.json';
Vue.use(VueI18n);


var browser_locale = navigator.language || navigator.userLanguage || window.defaultLanguage;

export default new VueI18n({
    locale: (browser_locale=='cs') ? 'cz' : 'en',
    messages:{
        cz: {title: config.title_cz},
        en: {title: config.title_en}
    },
    pluralizationRules: {
        'cz': function (choice, choicesLength) {
            if (choice === 0) return 0;
            if (choice === 1) return Math.max(1, choicesLength-1);
            if (choice > 1 && choice < 5) return Math.max(2, choicesLength-1);
            return Math.max(3, choicesLength-1);
        }
    },
    // does nothing (why?)
    // missingWarn: false,
    // missing: function(locale, key) {
    //     if (console && ((asis.dev_version || asis.test_version))) console.log('i18n: missing string for ' + key + ' ['+locale+']');
    // }
});
