import moment from 'moment';


export const week_shift = {
    data() {
        return {
            week_start: moment().startOf('isoWeek'),
            week_end: moment().endOf('isoWeek'),
            week_number: moment().week(),
        };
    },

    created: function() {
        this.update_week(this.$route.params.day);
        this.load();
    },

    // react to route paramater changes
    beforeRouteUpdate (to, from, next) {
        this.update_week(to.params.day);
        this.load();
        next();
    },

    methods: {
        load() {},

        shift_next() {
            var next = moment(this.week_start).add(7, 'days');
            this.$router.replace(next.format('YYYYMMDD'));
        },

        shift_prev() {
            var prev = moment(this.week_start).subtract(7, 'days');
            this.$router.replace(prev.format('YYYYMMDD'));
        },

        update_week(day) {
            day = moment(day, 'YYYYMMDD', true);
            if (!day || !day.isValid()) day = moment();

            this.week_start  = moment(day).startOf('isoWeek');
            this.week_end    = moment(day).endOf('isoWeek');
            this.week_number = this.week_start.week();
        }

    }
};


export const filters = {
    filters: {
        date(value) {
            return value ? value.format('DD.MM.YYYY') : '';
        },

        date_DAY_DDMMYYYY(value, lang) {
            if (!value) return '';
            var d = moment(value,'YYYY/MM/DD');
            const daysofweek_cz = ['pondělí','úterý','středa','čtvrtek','pátek','sobota','neděle'];
            const daysofweek_en = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'];
            var day = (lang=='en') ? daysofweek_en[d.isoWeekday()-1] : daysofweek_cz[d.isoWeekday()-1];
            return day.toUpperCase() + ' - ' + d.format('DD.MM.YYYY');
        },

        alergens(value, fallback) {
            return value ? value : fallback;
        }
    }
};


export const day_heading = {
    methods: {
        dayHeading(date) {
            return moment(date,'YYYY/MM/DD').format('DD.MM.YYYY - dddd').toLocaleUpperCase();
        },
    }
}

