import html from './project-info.html';
import lang from './project-info.lang.json';
import * as directives from '../../../../client/globals/directives.js';
import * as mixins from '../../../../client/globals/mixins.js';


export default {
    template: html,

    props: {
        readonly:Boolean,
    },

    mixins: [
        directives.label_required, 
        mixins.storeAccess,
        mixins.storeGetters(['project','$v'])
    ],

    i18n: {
        messages: lang
    },

    computed: {
        asu_beneficiary() {
            return (this.project.beneficiary == asis.self_institution_id);
        },
    },

    methods: {
        cobeneficiary_add: function () {
            this.project.cobeneficiaries.push(null);
        },

        cobeneficiary_remove: function () {
            this.project.cobeneficiaries.pop();
        },
    },

};


