import html from './project-approvals-mgr.html';
import lang from './project-approvals-mgr.lang.json';
import * as directives from '../../../../client/globals/directives.js';
import * as translators from '../../../../client/globals/translators.js';
import * as mixins from '../../../../client/globals/mixins.js';
import server from '../../../../client/asis-server.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.asis,
        mixins.loadDataOnCreate, 
        mixins.storeAccess,
        mixins.storeGetters(['project']),
    ],

    props: {
        request: Object,
    },

    data() {
        return {
            financial_approval_comments: '',
            council_approval_comments: '',
            approval_sent: false,
        };
    },

    methods: {
        approve_financial(status) {
            server.request(
                'projects-mgr', 'projects-approve', 
                {request_id: this.request.request_id, secret:this.request.secret, approve:status, comments:this.financial_approval_comments}, null,
                (result) => {
                    this.approval_sent = true;
                }
            );
        },

        approve_council(status) {
            server.request(
                'projects-mgr', 'projects-approve', 
                {request_id: this.request.request_id, secret:this.request.secret, approve:status, comments:this.council_approval_comments}, null,
                (result) => {
                    this.approval_sent = true;
                }
            );
        },

        // translators:
        date_timestamp2ddmmyyyy: translators.date_timestamp2ddmmyyyy,
    },

};
