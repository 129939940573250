import html from './rooms-edit.html';
import lang from './rooms-edit.lang.json';
import server from '../../../client/asis-server.js';
import * as directives from '../../../client/globals/directives.js';
import * as validators from '../../../client/globals/validators.js';
import * as mixins from '../../../client/globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate, directives.label_required, validators.vuelidate],

    data: function() {
        return {
            room: {},                      // employee data
            submit_error: null                  // soft error
        };
    },

    validations: {
        room: {
            room_name: {
                required: validators.required,
            },
            worksite: {
                required: validators.required
            },
            phone1: {
                required: validators.required,
                phonenumber: validators.phonenumber,
            },
            phone2: {
                phonenumber: validators.phonenumber,
            },
        }
    },

    methods: {
        load() {
            server.request(
                'personalinfo-mgr', 'rooms-get',
                {room_id: this.$route.params.room_id||0}, null,
                (data)=>{this.room = data;}
            );
        },

        submit() {
            // check if the form validates
            if (this.$v.$invalid) return;

            this.submit_error = null;
            server.request(
                'personalinfo-mgr', 'rooms-save', this.room, null, 
                (data) => {
                    // if ok, go to previous view
                    this.$router.back();
                }, 
                (error) => {
                    this.submit_error = error;
                }
            );
            return false;
        }
    }
};

