'use strict';
// jshint esversion:6
// jshint -W069


import config from './personalinfo.config.json';
import view_usr_edit from './views/personalinfo-edit.js';
import view_mgr_main from './views/main-mgr.js';
import view_mgr_rooms_list from './views/rooms-list.js';
import view_mgr_rooms_edit from './views/rooms-edit.js';
import view_mgr_people_list from './views/people-list.js';
import view_mgr_people_edit from './views/people-edit.js';


const module_personalinfo_usr = {
    module_name: 'personalinfo-usr',
    module_class: 'usr',
    title: config.usr.title,
    icon: config.usr.icon,
    routes: [
        // default route
        {path:'', redirect:{name:'personalinfo-usr-edit'}},
        // specific routes
        {name:'personalinfo-usr-edit', path:'edit', component:view_usr_edit},
    ]
};


const module_personalinfo_mgr = {
    module_name: 'personalinfo-mgr',
    module_class: 'mgr',
    title: config.mgr.title,
    icon: config.mgr.icon,
    routes: [
        // default route
        {path:'', component: view_mgr_main, redirect:{name:'personalinfo-mgr-rooms'}, children: [
            {name:'personalinfo-mgr-rooms',  path: 'rooms', component: view_mgr_rooms_list},
            {name:'personalinfo-mgr-people', path: 'people', component: view_mgr_people_list},
        ]},
        // specific routes
        {name:'personalinfo-mgr-rooms-add',  path:'rooms/add', component:view_mgr_rooms_edit},
        {name:'personalinfo-mgr-rooms-edit', path:'rooms/edit/:room_id', component:view_mgr_rooms_edit},
        {name:'personalinfo-mgr-people-edit', path:'people/edit/:pid', component:view_mgr_people_edit},
    ]
};


export default [module_personalinfo_usr, module_personalinfo_mgr];




// const config = {
//     usr:{
//         module_name: "personalinfo-usr",
//         title: {"cz":"Osobní informace", "en": "Personal information"},
//         icon: "personalinfo.svg",
//         access: ["V","O"]
//     },
//     mgr:{
//         module_name: "personalinfo-mgr",
//         title: {cz:"Telefonní seznam", en: "Phonebook"},
//         icon: "personalinfo.svg",
//         access: ["phonebook-manager"]
//     }
// }




// const usr = Object.assign(config.usr, {
//     module_class: 'usr',
//     routes: [],
//     oldurl: "/v1/#personalinfo_usr"
// });

// const mgr = Object.assign(config.mgr, {
//     module_class: 'mgr',
//     routes: [],
//     oldurl: "/v1/#phonebook_mgr"
// });


// export default [usr, mgr];


