import Vue from 'vue';
import server from '../../../client/asis-server.js';


export default function (store, namespace, module) {
    const initial_state = {
        drives: [],
        last_drive: null,
        cars: [],
        car: null,
        hide_old: true,
        loading: false,
    };

    const store_module = {
        namespaced: true,

        state() {
            return {...initial_state};
        },

        getters: {
            car: state => state.car,
            cars: state => state.cars,
            drives: state => state.drives,
            last_drive: state => state.last_drive,
            hide_old: state => state.hide_old,
            loading: state => state.loading,
        },


        mutations: {
            stateReset(state) {
                // we want to keep the state persistent
            },

            car(state, car) {
                state.car = car;
            },

            cars(state, cars) {
                state.cars = cars || [];
            },

            drives(state, drives) {
                state.drives = drives || [];
            },

            last_drive(state, last_drive) {
                state.last_drive = last_drive;
            },

            hide_old(state, hide_old) {
                state.hide_old = Boolean(hide_old);
            },

            loading(state, loading) {
                state.loading = loading;
            },
        },


        actions: {
            load_cars(context) {
                if (context.state.cars.length > 0) return true;
                return server.request(
                    module, 'cars-list', {}, null,
                    (data, extra) => {
                        context.commit('cars', data);
                    }
                );
            },

            load_drives(context) {
                if (!context.state.car) return Promise.resolve();
                context.commit('loading', true);
                return server.request(
                    module, 'drives-list', {car:context.state.car, brief:context.state.hide_old}, null,
                    (data, extra) => {
                        context.commit('drives', data);
                        context.commit('last_drive', extra.last);
                    }
                ).then(()=>{
                    context.commit('loading', false);
                });
            },

            set_car(context, car) {
                context.commit('car', car);
                context.dispatch('load_drives');
            },

        }    
    };

    return store_module;
};

