import html from './drives-list-usr.html';
import lang from './drives-list-usr.lang.json';
import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';
import store from './drives-list-store.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.module_name,
        mixins.loadDataOnCreate,
        mixins.store_create(store, 'drives-list'), 
        mixins.storeGetters(['cars','drives','last_drive','hide_old','loading']), 
        mixins.storeAccess, 
    ],

    props: {
        car: {type: String, default: null},
    },

    data: function() {
        var $t = this.$t.bind(this);
        return {
            drives_fields: [
                {key:'date_start', label:$t('list.date_start'), class:'min', formatter:translators.datetime_asis2ddmmyyyy_hhmm},
                {key:'date_end', label:$t('list.date_end'), class:'min', formatter:translators.datetime_asis2ddmmyyyy_hhmm},
                {key:'car', label:$t('list.car'), class:'min'},
                {key:'pid', label:$t('list.pid'), class:'min'},
                {key:'origin', label:$t('list.origin')},
                {key:'destination', label:$t('list.destination')},
                {key:'odo', label:$t('list.odo'), class:'min'},
                {key:'distance', label:$t('list.distance'), class:'min'},
                {key:'fueling_volume', label:$t('list.fueling'), class:'min'},
                {key:'actions', label:'', class:'min'},
            ],
            car_selected: this.car || null,
        };
    },

    beforeRouteUpdate(to, from, next) {
        this.load();
        next();
    },

    methods: {
        load() {
            this.store_dispatch('load_cars').then(() => {
                if (!this.car_selected) this.car_selected = (this.cars.length > 0) ? this.cars[0].regplate : null;
                this.store_dispatch('set_car', this.car_selected);
            });
        },

        table_row_class(item, type) {
            if (!item || type !== 'row') return
            return (item.own_drive) ? null : 'text-muted';
        },
    },

    computed: {
        car_names: function() {
            return this.cars.map(c => ({
                value: c.regplate, 
                text:  c.regplate + (c.alias ? ' ('+c.alias+')' : '')
            }));
        },
        hide_old: {
            get () {
                return this.store_getter('hide_old');
            },
            set (value) {
                this.store_commit('hide_old', value);
                this.store_dispatch('load_drives', value);
            }
        },
    },

    watch:{
        car_selected(newValue) {
            this.$router.replace({name:this.$route.name, params:{car:newValue}});
        }
    }

};


