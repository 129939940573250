'use strict';
// jshint esversion:6
// jshint -W069


import config from './events.config.json';
import view_events_list from './views/events-list.js';
import view_events_edit from './views/events-edit.js';


const module_events_usr = {
    module_name: 'events-usr',
    module_class: 'usr',
    title: config.usr.title,
    icon: config.usr.icon,
    routes: [
        {path:'', redirect:{name:'events-list'}},
        {name:'events-list', path:'list', component:view_events_list},
        {name:'events-add',  path:'add', component:view_events_edit},
        {name:'events-edit', path:'edit/:event_id', component:view_events_edit},
    ]
};


export default [module_events_usr];

