import Vue from 'vue';
import server from '../../../client/asis-server.js';
import * as validators from '../../../client/globals/validators.js';




export default function (store, namespace, module) {
    const min_abstract_length = 75;
    const max_abstract_length = 2000;

    const validator = new Vue({
        mixins: [
        validators.vuelidate
        ],
        computed: {
            project() {
                return store.getters[namespace + '/project'];
            }
        },
        validations() {
            return {
            project: {
                acronym: {
                    required: validators.required,
                    minLength: validators.minLength(3),
                    maxLength: validators.maxLength(20),
                },
                title_cz: {
                    required: validators.required,
                    maxLength: validators.maxLength(255),
                },
                title_en: {
                    required: validators.required,
                    maxLength: validators.maxLength(255),
                },
                provider: {
                    required: validators.required,
                    minValue: validators.minValue(1),
                },
                call_deadline: {
                    required: validators.requiredUnless(()=>(this.module_class=='mgr')),
                    date: validators.date,
                },
                call_title: {
                    maxLength: validators.maxLength(100),
                },
                beneficiary: {
                    required: validators.required,
                    minValue: validators.minValue(1),
                },
                cobeneficiaries: {
                    minLength: validators.minLength(0),
                    $each: {
                        required: validators.required,
                        minValue: validators.minValue(1),
                    }
                    //TODO: add check for duplicates (https://stackoverflow.com/questions/56944541/how-to-properly-add-custom-validation-to-array-in-vuelidate)
                },
                pi_asu: {
                    required: validators.required,
                    personalNumber: validators.personalNumber,
                },
                pi: {
                    required: validators.requiredUnless((model)=>{
                        return model ? (model.beneficiary == asis.self_institution_id) || (this.module_class=='mgr') : false;
                    }),
                    fullNameWithComma: validators.fullNameWithComma,
                },
                pi_email: {
                    required: validators.requiredUnless((model)=>{
                        return model ? (model.beneficiary == asis.self_institution_id) || (this.module_class=='mgr') : false;
                    }),
                    email: validators.email,
                },
                number: {
                    minLength: validators.minLength(3),
                    maxLength: validators.maxLength(25),
                },
                start: {
                    required: validators.required,
                    date: validators.date,
                },
                end: {
                    required: validators.required,
                    date: validators.date,
                    dateAfter: validators.dateAfter(
                        //(model)=>{ console.log('xx',this.project, model); return (this.project ? this.project.start : 0); }
                        'start'                    
                    ),
                },
                abstract_cz: {
                    //required: validators.requiredUnless(()=>(this.module_class=='mgr')),
                    minLength: validators.minLength(min_abstract_length),
                    maxLength: validators.maxLength(max_abstract_length),
                },
                abstract_en: {
                    //required: validators.requiredUnless(()=>(this.module_class=='mgr')),
                    minLength: validators.minLength(min_abstract_length),
                    maxLength: validators.maxLength(max_abstract_length),
                },
                budget_currency:{
                },
                budget_total:{
                    integer: validators.integer,
                    minValue: validators.minValue(0),
                },
                budget: {
                    minLength: validators.minLength(1),
                    $each: {
                        total:       {required:validators.required, integer:validators.integer, minValue:validators.minValue(0)},
                        personal:    {required:validators.required, integer:validators.integer, minValue:validators.minValue(0)},
                        investments: {required:validators.required, integer:validators.integer, minValue:validators.minValue(0)},
                        indirect:    {required:validators.required, integer:validators.integer, minValue:validators.minValue(0)},
                        cofinancing: {required:validators.required, integer:validators.integer, minValue:validators.minValue(0)},
                    }
                },
            },
            // uploads: {
            //     proposal: {
            //         file: validators.file,
            //     },
            //     attachments: {
            //         minLength: validators.minLength(0),
            //         $each: {
            //             file: {
            //                 required: validators.required,
            //                 file: validators.file,
            //             },
            //             description: {
            //                 required: validators.required,
            //             },
            //         }
            //     },
            // },
            };
        },
    });


    const store_module = {
        namespaced: true,

        state: () => ({
            project: null,
            project_proposal: null,
            project_documents: [],
            pending_approval_financial: false,
            pending_approval_council: false,
            uploads_proposal: null,
            uploads_documents: [],
            loading: false,
            saving: false,
            save_progress: 0,
            save_errors: [],
        }),


        getters: {
            project: state => state.project,
            project_dates: state => (state.project ? state.project.start + '-' + state.project.end : null),
            project_proposal: state => state.project_proposal,
            project_documents: state => state.project_documents,
            pending_approval_financial: state => state.pending_approval_financial,
            pending_approval_council: state => state.pending_approval_council,
            loading: state => state.loading,
            saving: state => state.saving,
            save_progress: state => state.save_progress,
            save_errors: state => state.save_errors,
            $v: state => Object.assign({}, validator.$v),    // this is not to expose API of $v
            missing_budget: state => validator.$v.project.budget_total.$invalid || validator.$v.project.budget.$invalid || (state.project.budget_total <= 0),
            missing_proposal: state => !state.project.proposal,
            missing_abstract: state => (state.project.abstract_cz.length < min_abstract_length) || (state.project.abstract_en.length < min_abstract_length),
            missing_approval_financial: state => !state.approval_financial,
            missing_approval_council: state => !state.approval_council,
        },


        mutations: {
            project(state, data) {
                state.project = data.project;
                state.project_proposal = data.proposal;
                state.project_documents = data.documents || [];
                state.pending_approval_financial = data.pending_approval_financial;
                state.pending_approval_council = data.pending_approval_council;
            },

            project_status(state, status) {
                state.project.status = status;
            },

            project_dates_update(state) 
            // update budget years according to the project duration
            {
                var new_budget_data = {};
                var budget_defaults = {total:0, personal:0, investments:0, indirect:0, cofinancing:0};
                var budget_total = 0;
                var current_budget = ((typeof state.project.budget === 'object') && (!Array.isArray(state.project.budget)) &&
                    (state.project.budget !== null)) ? state.project.budget : {};
                // get the first and last year of the project
                var year_min = new Date(state.project.start).getFullYear();
                var year_max = new Date(state.project.end).getFullYear();
                // push each year data into the new budget object
                for (let year=year_min; year<=year_max; year++) {
                    var year_key = year.toString();
                    var obj = current_budget[year_key] || {};
                    new_budget_data[year_key] = Object.assign({}, budget_defaults, obj);
                    budget_total += obj.total || 0;
                }
                // assign the new budget object
                state.project.budget = new_budget_data;
            },

            project_budget(state, data) 
            // change a single budget field: store the provided value & update other fields
            // - data is supposed to contain: {year, field, value} (can also be null)
            {
                // update budget if data is submitted
                if (data && state.project.budget[data.year]) {
                    state.project.budget[data.year][data.field] = data.value;
                }

                // recalculate total budget if there is only a single beneficiary
                if (state.project.cobeneficiaries.length == 0) {
                    var budget_total = 0;
                    for (let year in state.project.budget) budget_total += parseFloat(state.project.budget[year].total) || 0;
                    state.project.budget_total = budget_total;
                }
            },

            upload_proposal(state, doc) {
                state.uploads_proposal = {
                    file: doc.file,
                    action: state.project.proposal ? 'replace' : 'add',
                    document_id: state.project.proposal,
                };
                //console.log('state.uploads_proposal',state.uploads_proposal);
            },

            upload_documents(state, docs) {
                state.uploads_documents = docs;
                console.log('state.uploads_documents',state.uploads_documents);
            },

            loading(state, loading) {
                state.loading = loading;
            },

            saving(state, saving) {
                state.saving = saving;
                state.save_progress = 0;                // reset progress on any saving state change
                if (saving) state.save_errors = [];     // reset error on start saving
            },

            save_progress(state, progress) {
                state.save_progress = progress;
            },

            save_errors(state, errors) {
                state.save_errors = errors;
            },
        },


        actions: {
            load(context, project_id) 
            //! Load full project data.
            {
                context.commit('loading', true);
                return server.request(
                    module, 'projects-get', {project_id}, null,
                    (data, extra) => {
                        context.commit('project', {
                            project: data, 
                            proposal: extra.proposal, 
                            documents: extra.documents,
                            pending_approval_financial: extra.pending_approval_financial,
                            pending_approval_council: extra.pending_approval_council,
                        });
                    }
                ).then(()=>{
                    context.commit('loading', false);
                });
            },

            save(context) 
            //! Save full project data including document upload.
            {
                if (validator.$v.$invalid) return;
                context.commit('saving', true);
                return server.request(
                    module, 'projects-save', context.state.project, {
                        proposal: context.state.uploads_proposal,
                        documents: context.state.uploads_documents,
                    },
                    (data, extra) => {
                        context.commit('project', {
                            project: data, 
                            proposal: extra.proposal, 
                            documents: extra.documents,
                            pending_approval_financial: extra.pending_approval_financial,
                            pending_approval_council: extra.pending_approval_council,
                        });
                    },
                    (errors)=>{
                        context.commit('save_errors', errors);
                    },
                    {onProgress:(percent)=>{
                        context.commit('save_progress', percent);
                    }, timeout:600000},
                ).then(()=>{
                    // when finished, reset uploads and reset saving flag
                    context.commit('uploads_proposal', null);
                    context.commit('upload_documents', []);
                    context.commit('saving', false);
                });
            },

            status_update(context, status) 
            //! Update project status.
            {
                var project_id = context.state.project.project_id;
                return server.request(
                    module, 'projects-status-update', {project_id,status}, null,
                    (data) => {
                        context.commit('project_status', data.status);
                    },
                ).then(()=>{
                });
            },

        },

        // watcher for project start end end date
        // - upon change of start or end date the budget has to be updated
        // (note: `watchers` are not a native part of the vuex store, but it is used by our store mixin to set up watchers on the getters)
        watchers: {
            project_dates: {
                getter: namespace+'/project_dates',
                callback: (context) => context.commit('project_dates_update')
            }
        }
    };

    return store_module;
};
