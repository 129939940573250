import html from './statusbar.html';
import lang from './statusbar.lang.json';
import * as mixins from '../../globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.storeAccess, 
        mixins.storeGetters(['notifications'], 'asis/ui'), 
        mixins.storeGetters(['user'], 'asis/auth'), 
    ],

    created() {
        // periodiclly fetch notifications each 1 minute
        setInterval(()=>this.store_dispatch('asis/ui/statusbar_notifications'), 60000);
    },

    mounted: function() {
        // show popover at setting button if the user has not defined any settings yet
        // the delay is necessary for proper positioning of the popover component
        // var vue = this;
        // setTimeout(function() { 
        //     if (asis.user.pid>0 && !asis.user.preferences) vue.$refs.settings_popover.$emit('open');
        // }, 1500);
    },

    methods: {
        // logout() {
        //     server.logout();
        //     this.$router.push('/login');
        // }
    }
};

