'use strict';
// jshint esversion:6
// jshint -W069


import config from './intranet.config.json';
import view_main from './views/main.js';
import view_news_list from './views/news-list.js';
import view_news_edit from './views/news-edit.js';
import view_pages_list from './views/pages-list.js';
import view_pages_edit from './views/pages-edit.js';
import view_news_pub from './views/news-pub.js';


const module_intranet_usr = {
    module_name: 'intranet-usr',
    module_class: 'usr',
    title: config.usr.title,
    icon: config.usr.icon,
    routes: [
        // default route
        {path:'', component: view_main, redirect:{name:'news-list'}, children: [
            {name:'news-list',  path: 'news', component: view_news_list},
            {name:'pages-list',  path: 'pages', component: view_pages_list},
        ]},
        // news
        {name:'news-add',  path:'news/add', component:view_news_edit},
        {name:'news-edit', path:'news/edit/:news_id', component:view_news_edit},
        // pages
        {name:'pages-add',  path:'pages/add', component:view_pages_edit},
        {name:'pages-edit', path:'pages/edit/:page_id', component:view_pages_edit},
    ]
};

const module_intranet_pub = {
    module_name: 'intranet',
    module_class: 'pub',
    routes: [
        // public routes
        {name:'intranet-pub-news', path:'news/:news_id/:lang', component:view_news_pub, meta:{noAuth:true}},
    ]
};



export default [module_intranet_usr, module_intranet_pub];

