import html from './homeoffice-contracts-edit.html';
import lang from './homeoffice-contracts-edit.lang.json';
import server from '../../../client/asis-server.js';
import * as directives from '../../../client/globals/directives.js';
import * as validators from '../../../client/globals/validators.js';
import * as mixins from '../../../client/globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate, mixins.module_name, directives.label_required, validators.vuelidate],

    data: function() {
        return {
            homeoffice_contract: {},
            submit_error: null,                 // soft error

            homeoffice_days_options: [
                { text: 'Po', value: 1 },
                { text: 'Ut', value: 2 },
                { text: 'St', value: 3 },
                { text: 'Ct', value: 4 },
                { text: 'Pa', value: 5 },
            ],
        };
    },

    validations() {
        // validation rules for employee object fields
        // (due to reference to this.employee object it has to be declared as a function)
        return {
            homeoffice_contract: {
                contract_refnum: {
                    required: validators.required,
                    maxLength: validators.maxLength(30)
                },
                date_from: {
                    required: validators.required,
                    date: validators.asis_date
                },
                date_until: {
                    date: validators.asis_date,
                    dateAfter: validators.dateAfter('date_from'),
                },
            }
        };
    },

    methods: {
        load() {
            server.request(
                this.module_name, 'homeoffice-contracts-get',
                this.$route.params, null,
                (data) => {
                    this.homeoffice_contract = data;
                }
            );
        },

        submit() {
            // check if the form validates
            if (this.$v.$invalid) return;

            this.submit_error = null;
            server.request(
                this.module_name, 'homeoffice-contracts-save', this.homeoffice_contract, null, 
                (data) => {
                    // if ok, go to previous view
                    this.$router.back();
                }, 
                (error) => {
                    this.submit_error = error;
                }
            );
            return false;
        }
    }
};

