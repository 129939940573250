import html from './meetings-list.html';
import lang from './meetings-list.lang';
import server from '../../../client/asis-server.js';
import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate],

    data: function() {
        var $t = this.$t.bind(this);
        return {
            meetings: [],
            meetings_fields: [
                {key: 'meeting_id', label:$t('list.number'), sortable:false, thStyle:{width:"15%"}},
                {key: 'date', label:$t('list.date'), formatter:translators.date_asis2ddmmyyyy, thStyle:{width:"15%"}},
                {key: 'spacer', label:''},
                {key: 'actions', label:'', thStyle:{width:"15%"}}
            ]
        };
    },

    methods: {
        load() {
            server.request(
                'council-mgr', 'meetings-list', {}, null,
                (data)=>{this.meetings = data;}
            );
        },

    }

};
