import html from './directives-edit.html';
import lang from './directives-edit.lang.json';
import server from '../../../client/asis-server.js';
import * as directives from '../../../client/globals/directives.js';
import * as validators from '../../../client/globals/validators.js';
import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';
import document_view from '../components/document-view.js';
import revision_attachment from '../components/revision-attachment.js';

const mgr_directive_edit = {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate, directives.label_required, validators.vuelidate],

    components: {
        'document-view': document_view,
        'revision-attachment': revision_attachment,
    },

    data: function() {
        return {
            directive: {},
            documents: [],
            new_revision: null,
            doc_replacements: {},
            new_attachments: {cz:[], en:[]},
            directive_types: [],  // content loaded from schema
            directive_categories: [],  // content loaded from schema
            submit_progress: null,
            submit_error: null,
        };
    },

    validations() {
        // validation rules for directive object fields
        //return {
        var validation = {};
        validation.directive = {
            type: {
                required: validators.required
            },
            year: {
                required: validators.required,
                year: validators.year
            },
            number: {
                required: validators.required,
                integer: validators.integer,
                minValue: validators.minValue(1),
            },
            title_cz: {
                required: validators.required
            },
            title_en: {
                required: validators.required
            },
            valid_from: {
                required: validators.required,
                asis_date: validators.asis_date
            },
            valid_until: {
                asis_date: validators.asis_date
            },
        };

        if (this.new_revision)
        validation.new_revision = {
            date: {
                required: validators.required,
                asis_date: validators.asis_date
            },
            doc_cz: {
                required: validators.required,
                file: validators.file,
            },
            pdf_cz: {
                required: validators.required,
                file: validators.file,
            },
            doc_en: {
                file: validators.file,
            },
            pdf_en: {
                required: validators.requiredIf('doc_en'),
                file: validators.file,
            },
            attachments_cz: {
                minLength: validators.minLength(0),
                $each: {
                    doc: {
                        required: validators.required,
                        file: validators.file,
                    },
                    pdf: {
                        file: validators.file,
                    },
                    description: {
                        required: validators.required,
                    },
                }
            },
            attachments_en: {
                minLength: validators.minLength(0),
                $each: {
                    doc: {
                        required: validators.required,
                        file: validators.file,
                    },
                    pdf: {
                        file: validators.file,
                    },
                    description: {
                        required: validators.required,
                    },
                }
            },
        };

        validation.new_attachments = {
            cz: {
                minLength: validators.minLength(0),
                $each: {
                    doc: {
                        required: validators.required,
                        file: validators.file,
                    },
                    pdf: {
                        file: validators.file,
                    },
                    description: {
                        required: validators.required,
                    },
                }
            },
            en: {
                minLength: validators.minLength(0),
                $each: {
                    doc: {
                        required: validators.required,
                        file: validators.file,
                    },
                    pdf: {
                        file: validators.file,
                    },
                    description: {
                        required: validators.required,
                    },
                }
            },
        };

        return validation;
    },


    methods: {
        load() {
            server.request(
                'directives-mgr', 'directives-get',
                {directive_id: this.$route.params.directive_id||0},
                null,
                (data, extra, schema)=>{
                    this.directive = data;
                    this.directive_types = extra.types;
                    this.directive_categories = extra.categories;
                    this.documents = extra.documents;
                }
            );
            // automatically add new revision in adding mode
            if (!this.$route.params.directive_id) this.new_revision_add();
        },

        submit(event) {
            // check if the form validates
            if (this.$v.$invalid) return;

            if (this.new_revision) this.directive.new_revision = this.new_revision;
            
            this.directive.doc_replacements = [];
            for (let docId in this.doc_replacements) this.directive.doc_replacements.push({document_id:docId, file:this.doc_replacements[docId]});
            
            this.directive.new_attachments = [];
            for (let att of this.new_attachments.cz) this.directive.new_attachments.push({revision:att.revision, lang:'cz', doc:att.doc, pdf:att.pdf, description:att.description});
            for (let att of this.new_attachments.en) this.directive.new_attachments.push({revision:att.revision, lang:'en', doc:att.doc, pdf:att.pdf, description:att.description});

            this.submit_progress = 0;
            this.submit_error = null;
            server.request(
                'directives-mgr', 'directives-save', this.directive, null,
                (result)=>{
                    this.$router.back();
                },
                (error)=>{
                    this.submit_error = error;      // show error message
                },
                {onProgress:(perc)=>{
                    this.submit_progress=perc
                }, timeout:600000}
            ).then(()=>{
                this.submit_progress = 0;       // hide upload progress bar
            });
        },

        new_revision_add() {
            // add new revision
            this.new_revision = {
                date: null,
                doc_cz: null,
                pdf_cz: null,
                doc_en: null,
                pdf_en: null,
                attachments_cz: [],
                attachments_en: [],
            };
        },

        new_attachments_add(lang, revision) {
            // add new attachment to an existing revision
            this.new_attachments[lang].push({
                revision: revision,
                doc: null,
                pdf: null,
                description: '',
            });
        },

        attachment_cz_add() {
            // add cz attachment
            this.new_revision.attachments_cz.push({
                attachment_id: this.new_revision.attachments_cz.length,
                doc: null,
                pdf: null,
                description: ''
            });
        },

        attachment_cz_del() {
            // remove last cz attachment
            this.new_revision.attachments_cz.pop();
        },

        attachment_en_add() {
            // add en attachmant
            this.new_revision.attachments_en.push({
                attachment_id: this.new_revision.attachments_en.length,
                doc: null,
                pdf: null,
                description: ''
            });
        },

        attachment_en_del() {
            // remove last en attachment
            this.new_revision.attachments_en.pop();
        },

        document_replace(replacements) {
            for (let docId in replacements) {
                if (replacements[docId] instanceof File)
                    this.doc_replacements[docId] = replacements[docId]
                else
                    delete this.doc_replacements[docId];
            }
            //console.log('received:document_replace', replacements, this.doc_replacements);
        },

        // translators:
        date_asis2ddmmyyyy: translators.date_asis2ddmmyyyy,

    },


    computed: {
        directive_type_options() {
            var options = [];
            for (let type of this.directive_types) options.push({value:type, text:this.$t('directive_types.'+type)});
            return options;
        },

        directive_catagories_options() {
            var options = [];
            for (let category of this.directive_categories) options.push({
                value: category, 
                text: this.$te('directive_categories.'+category) ? this.$t('directive_categories.'+category) : category
            });
            //console.log('directive_category_options', options);
            return options;
        },
    }
};

export default mgr_directive_edit;

