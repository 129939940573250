import html from './projects-list-mgr.html';
import lang from './projects-list-mgr.lang.json';
//import server from '../../../client/asis-server.js';
import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';
import store from './projects-list-store.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.loadDataOnCreate, 
        mixins.store_create(store, 'projects-list'), 
        mixins.storeAccess,
        mixins.storeGetters(['projects','loading']), 
    ],

    data: function() {
        var locale = this.$i18n.locale;
        var $t = this.$t.bind(this);
        return {
            projects_fields: [
                {key:'acronym', sortable:true, label:$t('list.acronym')},
                {key:'number', sortable:true, label:$t('list.number')},
                {key:'pi_asu_last_name', sortable:true, label:$t('list.pi_asu')},
                {key:'provider_acronym', sortable:true, label:$t('list.provider')},
                {key:'start', sortable:true, label:$t('list.start'), formatter:translators.date_asis2ddmmyyyy},
                {key:'end', sortable:true, label:$t('list.end'), formatter:translators.date_asis2ddmmyyyy},
                {key:'status', sortable:true, label:$t('list.status')},
                {key:'actions', sortable:false, label:''},
            ],

            filter_status_options: [
                { value: null, text: 'Please select an option' },
                { value: 'draft', text: 'draft' },
                { value: 'submitted', text: 'submitted' },
                { value: 'accepted', text: 'accepted' },
                { value: 'declined', text: 'declined' },
                { value: 'running', text: 'running' },
                { value: 'finished', text: 'finished' },
                { value: 'terminated', text: 'terminated' },
            ],            
        };
    },

    methods: {
        load() {
            this.store_dispatch('load');
        },

        formater_status(status) {
            return (status=='draft') ? 'dr' : status.charAt(0);
        }
    },

    computed: {
        filter_categories() {
            // add 'all' item as the first category and map the list to {value:, text:} pairs
            return (['all'].concat(this.categories||[])).map(i => ({value:i, text:this.$t('categories.'+i).toLowerCase()}));
        },
        filter_status: {
            get() {
                return this.store_getter('filter_status');
            },
            set(value) {
                this.store_commit('filter_status', value);
            },
        }        

    }


};


