//TODO: go back button shouls go to global components
import go_back_button from './go-back-button.js';


export default {
    template: `
        <section class="container-fluid router-view" :class="[module_name,module_basename]">
            <header class="container w-xxl clearfix">
                <h1 class="float-left" :class="module_class" :style="{'--h1-module-image':'url('+icon_url+')'}">{{title_lang}}</h1>
                <go-back-button v-if="logged_in" class="float-right d-print-none"></go-back-button>
            </header>
            <transition name="fade" mode="out-in">
                <router-view></router-view>
            </transition>
        </section>
    `,

    components: {
        'go-back-button': go_back_button
    },

    props: {
        module_name:  String,
        module_class:  String,
        module_basename:  String,
        title:  Object,
        icon:  String,
    },

    computed: {
        logged_in() { 
            return this.$store.getters['asis/auth/authenticated']; 
        },
        title_lang() { 
            var lang = this.$store.getters['asis/ui/lang'];
            return (this.title || {})[lang]
        },
        icon_url() { 
            return 'assets/'+this.module_basename+'/'+this.icon;
        },
    },

    created(){
        //console.log('module component created', this);
    }
};
