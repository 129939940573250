import Vue from 'vue';
import server from './asis-server.js';
import asis_version from '../asis-version.json';

export default {
    namespaced: true,

    state: () => ({
        authenticated: false,
        user: null,
        login_error: null,
        tfa_required: false,
        loading: false,
    }),


    getters: {
        authenticated: state => state.authenticated,
        user: state => state.user ? state.user : {},
        login_error: state => state.login_error,
        tfa_required: state => state.tfa_required,
        loading: state => state.loading,
    },


    mutations: {
        authenticated(state, authenticated) {
            state.authenticated = authenticated;
        },

        user(state, user) {
            state.user = user || null;
            state.login_error = null;
            state.tfa_required = false;
            state.authenticated = (state.user && (state.user.pid > 0));
        },

        error(state, error) {
            state.user = null;
            state.tfa_required = (error == 'tfa_required');
            state.login_error  = (error == 'tfa_required') ? null : error;
            state.authenticated = false;
        },

        loading(state, loading) {
            state.loading = loading;
        },
    },


    actions: {
        login(context, credentials) {
            var login, password, tfa, remember, version;
            if (typeof credentials === 'object' && credentials !== null) {
                login    = credentials.login || '';
                password = credentials.password || '';
                tfa      = credentials.tfa || 0;
                remember = credentials.remember;
            }
            version = asis_version.major+'.'+asis_version.minor+'.'+asis_version.patch;

            //console.log('login request');
            context.commit('loading', true);
            return server.request(
                'system', 'login', 
                {login, password, tfa, remember, version}, {},
                (data) => {
                    //console.log('login request repsonse',data);
                    // set user object and UI language
                    context.commit('user', data);
                    context.commit('asis/ui/lang', data.preferences.lang, {root:true});
                    context.dispatch('asis/ui/statusbar_notifications', null, {root:true});
                },
                (error) => {
                    context.commit('error', error);
                },
                {timeout:10000}
            ).then(()=>{
                context.commit('loading', false);
            })
            // .catch((xx)=>{
            //     console.log('xx',xx);
            // });
        },

        logout(context) {
            // send logout request to server
            return server.request('system', 'logout').then(()=>{
                context.commit('user', null);
            });
        },
    }
};

