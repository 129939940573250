import html from './attribute-photo.html';
import lang from './attribute-photo.lang.json';
import server from '../../../../client/asis-server.js';
import * as translators from '../../../../client/globals/translators.js';
import moment from 'moment';


export default {

    props: {
        attribute: Object,
        edit: Boolean,
    },

    template: html,

    data() {
        return {
            upload_date: translators.date_date2asis(new Date()),
            upload_files: [],
            upload_description: '',
            upload_progress: null,
        };
    },

    i18n: {
        messages: lang
    },

    methods: {
        save_photos() {
            server.request(
                'maps-usr', 'objects-attributes-photo-save',
                {object_id:data.object_id,date:this.upload_date, files:this.upload_files, description:this.upload_description}, null,
                null, null, // callbacks
                {onProgress:(percent)=>{
                    this.upload_progress = percent;
                }, timeout:600000},
            ).then(()=>{
                // when finished, reset uploads and reset saving flag
                this.upload_files = [];
                this.upload_description = '';
                this.upload_progress = null;
            });
        },

        onUploadFilesChange(e) {
            for (let f of e.target.files) {
                console.log(f);
                f.url = URL.createObjectURL(f);
            }
            console.log(upload_files);
        }
    },

};

