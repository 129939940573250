import html from './directives-list.html';
import lang from './directives-list.lang.json';
import server from '../../../client/asis-server.js';
import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';


const view_mgr_overview = {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate],

    data: function() {
        var $t = this.$t.bind(this);
        return {
            directives: [],
            fields: [
                {key: 'number', label:$t('list.number'), sortable:true, sortByFormatted:true, formatter:'directiveNumber', thClass:'text-nowrap'},
                {key: 'title_cz', label:$t('list.title_cz'), sortable: true, thClass:'text-nowrap'},
                {key: 'category', label:$t('list.category'), sortable: true, thClass:'text-nowrap'},
                {key: 'valid_from', label:$t('list.valid_from'), sortable: true, formatter:translators.date_asis2ddmmyyyy, thClass:'text-nowrap'},
                {key: 'valid_until', label:$t('list.valid_until'), sortable: true, formatter:translators.date_asis2ddmmyyyy, thClass:'text-nowrap'},
                {key: 'actions', label:''}
            ]
        };
    },

    methods: {
        load() {
            server.request(
                'directives-mgr', 'directives-list', {orderby:"number", orderdir:"asc"}, null,
                (data)=>{this.directives = data;}
            );
        },

        edit(data) {
            this.$router.push({name:'directives-edit', params: {directive_id:data.item.directive_id}});
        },

        directiveNumber(value, prop, row) {
            return `${row.year}/${row.number}`;
        }
    }

};

export default view_mgr_overview;

