import html from './project-budget.html';
import lang from './project-budget.lang.json';
import * as mixins from '../../../../client/globals/mixins.js';
import * as directives from '../../../../client/globals/directives.js';


export default {
    template: html,

    mixins: [
        directives.label_required, 
        mixins.storeAccess,
        mixins.storeGetters(['project','$v']),
    ],

    props: {
        readonly:Boolean,
    },

    i18n: {
        messages: lang
    },

    data() {
        return {
            currencies: ['CZK','EUR','USD'],
        };
    },

    methods: {
        budget_update(year, field, value) {
            // commit an update of a single budget field
            this.store_commit('project_budget', {year, field, value:parseFloat(value)});
        }
    },

    computed: {
        budget_totals() {
            // compute totals for each budget category over years
            var budget_totals = {total:0, personal:0, investments:0, indirect:0, cofinancing:0};
            for (let key in this.project.budget) {
                budget_totals.total += parseFloat(this.project.budget[key].total);
                budget_totals.personal += parseFloat(this.project.budget[key].personal);
                budget_totals.investments += parseFloat(this.project.budget[key].investments);
                budget_totals.indirect += parseFloat(this.project.budget[key].indirect);
                budget_totals.cofinancing += parseFloat(this.project.budget[key].cofinancing);
            }
            return budget_totals;
        },
    },

};
