//----------------------------------------------------------------------------
// ASIS Informational System
//----------------------------------------------------------------------------
// indexes/department.js
// component for the list of departments
//----------------------------------------------------------------------------
// (c) Astronomický ústav AV ČR, v.v.i. (see licence file for legal info)
//----------------------------------------------------------------------------

'use strict';
// jshint esversion:6


import Vue from 'vue';
import jquery from 'jquery';
import server from '../../../client/asis-server.js';

// A general note on two-way data binding:
// The underlying component do not typicly  use v-model, but instead they use
// v-bind and v-on to handle proper 2-way propagation of
// the selected value. v-bind propagates the value from the parent
// to the child (e.g. to v-select) and v-on propagates changes of the value back to
// the parent using an event (in case of a component, $event is the actual value).
// The prop for the value MUST be named `value` for this to work.
// see: https://vuejs.org/v2/guide/components.html#Using-v-model-on-Components
// Example:
//      <v-select
//          v-bind:value="value"
//          v-on:input="$emit('input', $event)">
//      </v-select>

// TODO: see https://medium.com/@jithilmt/vue-js-2-two-way-data-binding-in-parent-and-child-components-1cd271c501ba



// department component
// a drop-down select for department
Vue.component('asis-department', {
    template: `
        <v-select 
                v-model="department"
                :options="departments" 
                :searchable="false" 
                :label="selectLabel"
                :clearable="clearable"
                :disabled="readonly || disabled">
        </v-select>
    `,

    props: {
        value: String,
        state: {type:Boolean, default:null},
        disabled: {type:Boolean, default:false},
        readonly: {type:Boolean, default:false},
        scientific: {type:Boolean, default:false},
        clearable: {type:Boolean, default:false},
    },

    data: function() {
        return {
            // define a variable to store the list of worksites
            departments: [],    // list of departments
            department: null,   // selected department (this is a member of this.departments - an object)
            selectLabel: 'department_name', //this.fullNames ? 'department_name' : 'department'
        };
    },

    created: function() {
        // load the list of departments from server
        server.request(
            'indexes-usr', 'departments-list', {scientific:this.scientific}, null,
            (data)=>{
                this.departments = data;
                // select a default
                this.department = null;
                for (let d of this.departments) {
                    if (d['department'] == this.value) {this.department = d; break;}
                }
            }
        );
    },

    watch: {
        value(value){
            // on value change (induced by the parent) find the corresponding item in the list of departments
            // and set the local `department` variable accordingly
            var new_value = null;
            if (typeof value === 'string') value = value.toUpperCase();
            for (var d of this.departments) {
                if (d['department'] == this.value) {new_value = d; break;}
            }
            this.department = new_value;
        },
        department(value) {
            // on local value change (induced by v-select), notify the parent of the change,
            // but send back only the scalar value
            this.$emit('input', this.department ? this.department['department'] : null);
        },
        state() {
            // on state change, set/clear the error class of the v-select
            //TODO:rewrite this using Vue
            if (this.state) jquery(this.$el).removeClass('is-invalid'); else jquery(this.$el).addClass('is-invalid');
        }
    }
});

