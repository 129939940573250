import html from './student-table.html';
import lang from './student-table.lang.json';
import server from '../../../../client/asis-server.js';
import student_card from './student-card.js';
//import * as translators from '../../../../client/globals/translators.js';
//import moment from 'moment';


export default {

    props: {
        students: Array,
    },

    template: html,

    data() {
        var $t = this.$t.bind(this);
        return {
            table_fields: [
                {key: 'full_name', label:$t('full_name'), sortable: true, sortByFormatted: true},
                {key: 'supervisor', label:$t('supervisor'), sortable: true, sortByFormatted: true},
                {key: 'supervisor_role', label:$t('supervisor_role'), sortable: true, sortByFormatted: true},
                {key: 'institution', label:$t('institution'), sortable: true, sortByFormatted: true},
                {key: 'contract_type', label:$t('contract_type'), sortable: true, sortByFormatted: true},
                {key: 'contract_start', label:$t('contract_start'), sortable: true},
                {key: 'contract_end', label:$t('contract_end'), sortable: true},
                {key: 'defense_date', label:$t('defense_date'), sortable: true},
                {key: 'details', label:null},
            ],
        };
    },

    components: {'student-card': student_card},

    i18n: {
        messages: lang
    },

    methods: {
    },

};

