//----------------------------------------------------------------------------
// ASIS Informational System
//----------------------------------------------------------------------------
// indexes/worksite.js
// component for the list of worksites
//----------------------------------------------------------------------------
// (c) Astronomický ústav AV ČR, v.v.i. (see licence file for legal info)
//----------------------------------------------------------------------------

'use strict';
// jshint esversion:6


import Vue from 'vue';
import server from '../../../client/asis-server.js';

// A general note on two-way data binding:
// The underlying component do not typicly  use v-model, but instead they use
// v-bind and v-on to handle proper 2-way propagation of
// the selected value. v-bind propagates the value from the parent
// to the child (e.g. to v-select) and v-on propagates changes of the value back to
// the parent using an event (in case of a component, $event is the actual value).
// The prop for the value MUST be named `value` for this to work.
// see: https://vuejs.org/v2/guide/components.html#Using-v-model-on-Components
// Example:
//      <v-select
//          v-bind:value="value"
//          v-on:input="$emit('input', $event)">
//      </v-select>



// worksite component
// a drop-down select for worksite
Vue.component('asis-worksite', {
    template: `
        <v-select 
                v-bind:value="value"
                @input="$emit('input', $event)"
                :options="worksites" 
                :searchable="false" 
                :disabled="readonly || disabled"
                :selectable="selectable"
                :clearable="false">
        </v-select>
    `,

    props: {
        value: String,
        state: Boolean,
        readonly: Boolean,
        disabled: Boolean,
        disabledItems: Array,
    },

    data: function() {
        return {
            // define a variable to store the list of worksites
            worksites: [],
            selectable: (item) => !(this.disabledItems||[]).includes(item),
        };
    },

    // load the list of available worksites from server
    created: function() {
        server.request(
            'indexes-usr', 'worksites-list', {}, null,
            (data)=>{
                this.worksites = [];
                for (var w of data) this.worksites.push(w['worksite']);
                //set default if there is only one choice available
                if (!this.value && (this.worksites.length>0)) {
                    var ws = this.worksites.filter(this.selectable);
                    console.log('ws',ws);
                    if (ws.length == 1) this.$emit('input', ws[0]);
                } 
            }
        );
    }
});

