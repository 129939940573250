import html from './events-list.html';
import lang from './events-list.lang.json';
import server from '../../../client/asis-server.js';
import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate],

    data: function() {
        var $t = this.$t.bind(this);
        return {
            loading: false,
            events: [],
            events_fields: [
                {key: 'title_cz', label:$t('list.title'), thStyle:{width:"50%"}},
                {key: 'date_from', label:$t('list.date_from'), formatter:'datetime_formatter'},
                {key: 'date_until', label:$t('list.date_until'), formatter:'datetime_formatter'},
                {key: 'owner_name', label:$t('list.owner')},
                {key: 'actions', label:''}
            ],
            events_old: 0,
            events_page: 1,
        };
    },

    methods: {
        load() {
            this.loading = true;
            server.request(
                this.$parent.module_name, 'events-list', {show_old:this.events_old}, null,
                (data)=>{
                    this.events = data;
                }
            ).then(()=>{
                this.loading = false;
            });
        },

        event_class(item, type) {
            //if (!item || type !== 'row') return;
            if (item && item.past_event) return 'text-muted';
        },

        datetime_formatter(value, key, item) {
            return item.all_day ? translators.datetime_asis2ddmmyyyy(value) : translators.datetime_asis2ddmmyyyy_hhmm(value);
        }     
    },

    watch: {
        events_old(newV, oldV) {
            this.load();
        }
    }


};

 
