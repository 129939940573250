import html from './timesheet.html';
import lang from './timesheet.lang.json';
import * as mixins from '../../../client/globals/mixins.js';
import * as translators from '../../../client/globals/translators.js';
import server from '../../../client/asis-server.js';
import moment from 'moment';
import timesheet_table from './timesheet-table.js';
import timesheet_month_select from './components/month-select.js';

import store from './timesheet-store.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.module_name, 
        mixins.loadDataOnCreate,            // calls data loading on view create
        mixins.hasRole,                     // gives access to role-checking in templates
        mixins.asis,                        // gives access to asis global object in templates
        mixins.module_name,                 // gives access to this.module_class and this.module_name
        timesheet_month_select,             // implements month selection and shift
        mixins.store_create(store, 'timesheet-edit'),
        mixins.storeAccess,
    ],

    components: {
        'timesheet-table': timesheet_table
    },

    data: function() {
        var $t = this.$t.bind(this);
        return {
            impersonification_users: [],
            impersonification_allowed: false,
            timesheet_valid: false,
            timesheet_locked: true,
            timesheet_print_url: null,
            loading_saving: true,
        };
    },

    methods: {
        load() {
            if (this.module_class == 'mgr') {
                server.request(
                    'timesheets-mgr', 'impersonification-list', {}, null,
                    (data) => {
                        this.impersonification_users = data || [];
                        this.impersonification_allowed = (this.impersonification_users.length > 0);
                    }
                );
            }
        },

        timesheet_save() {
            this.$refs['timesheet-table'].save(false);
        },

        timesheet_check() {
            this.$refs['timesheet-table'].save(true);
        },

        timesheet_onvalidation(status) {
            this.timesheet_valid = status;
        },

        timesheet_onchange(status) {
            if (typeof status.loading_saving !== 'undefined') this.loading_saving = status.loading_saving;
            if (typeof status.can_edit !== 'undefined') this.timesheet_locked = !status.can_edit;
            if (typeof status.print_url !== 'undefined') this.timesheet_print_url = status.print_url;
        },

        moment_format: translators.moment_format,
    },

    computed: {
        can_edit() {
            //return this.$refs.timesheet_table.timesheet && this.$refs.timesheet_table.timesheet.can_edit;
        },
        saving() {
            return false;
        },

        pid: {
            get() {
                return this.store_getter('pid');
            },
            set(value) {
                this.store_commit('pid', value);
            },
        }        
    }

};
