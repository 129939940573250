'use strict';
// jshint esversion:6
// jshint -W069


import config from './maps.config.json';
import view_main_usr from './views/main-usr.js';


//const view_projects_pending_approvals_mgr = {};

const module_maps_usr = {
    module_name: 'maps-usr',
    module_class: 'usr',
    title: config.usr.title,
    icon: config.usr.icon,
    routes: [
        // default route
        {path:'', component: view_main_usr},
    ]
};

const module_maps_mgr = {
    module_name: 'maps-mgr',
    module_class: 'mgr',
    title: config.mgr.title,
    icon: config.mgr.icon,
    routes: [
    ]
};



export default [module_maps_usr];
