import html from './login.html';
import lang from './login.lang.json';
import * as validators from '../globals/validators.js';
import * as mixins from '../globals/mixins.js';

import store from '../asis-store.js';

const login = {
    template: html,

    mixins: [
        mixins.storeAccess,
        mixins.storeGetters(['authenticated','tfa_required','login_error'], 'asis/auth'), 
        validators.vuelidate,
    ],

    i18n: {
        locale: 'cz',
        sync: false,
        messages: lang
    },

    data() {
        return {
            login: '',
            password: '',
            tfa: null,
            remember: false,
            isSSL: (document.location.protocol=="https:"),
            error: null,
            sending: false,
        };
    },

    validations: {
        login: {
            //required: validators.required,
            //token: validators.token,
        },
        password: {
            //required: validators.required,
        },
        tfa: {
            regex: validators.regex(/^[0-9]{6}$/)
        },
    },

    beforeRouteEnter(to, from, next) {
        var user = store.getters['asis/auth/user'];
        if (user.pid > 0) {
            next('/'); 
        } else {
            next();
        }
    },

    methods: {
        switch_language: function() {
            this.$i18n.locale = (this.$i18n.locale=='en') ? 'cz' : 'en';
        },

        submit: async function(e) {
            this.error = null;

            //TODO - move to form validation
            if (this.login.indexOf('@')>=0) {
                this.error = "Přihlašovací jméno nemůže obsahovat zavináč, viz info v červeném rámečku. / Your login cannot contain the '@' sign, see info in the red box.";
                return;
            }

            if (this.$v.$invalid) return;

            var credentials = {
                login: this.login, 
                password: this.password, 
                tfa: this.tfa, 
                remember: this.remember,
            };

            this.sending = true;
            await this.store_dispatch('asis/auth/login', credentials);
            this.tfa = null;
            this.sending = false;

            if (this.tfa_required) {
                return;
            }

            if (this.authenticated) {
                // redirect to previous page or to homepage
                this.$router.push(this.$route.query.redirect || '/');
            } else {
                this.error = this.login_error;
            }
        }
    }
};

export default login;

