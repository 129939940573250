'use strict';
// jshint esversion:6
// jshint -W069


import config from './system.config.json';
import view_preferences from './views/preferences.js';
import view_report from './views/report.js';
import view_notifications from './views/notifications.js';

const module_system_usr = {
    module_name: 'system-usr',
    module_class: 'usr',
    title: config.usr.title,
    icon: config.usr.icon,
    routes: [
        {name:'system-usr-preferences', path:'preferences', component:view_preferences},
        {name:'system-usr-report', path:'report', component:view_report},
        {name:'system-usr-notifications', path:'notifications', component:view_notifications},
    ]
};


const module_system_mgr = {
    module_name: 'system-mgr',
    module_class: 'mgr',
    title: config.mgr.title,
    icon: config.mgr.icon,
    routes: [
        //{path:'', redirect:{name:'system-roles'}},
        //{name:'system-roles', path:'roles', component:view_roles},
    ]
};


export default [module_system_usr, module_system_mgr];

