/* jshint esversion:6 */

import moment from 'moment';

export const asis_date_format = 'YYYY/MM/DD';

export function date_asis2dmyyyy(value) {
    if (!value || !value.length) return '';
    var m = (value instanceof Date) ? moment(value) : moment(new Date(value));
    return m.format('D.M.YYYY');
};

export function date_asis2ddmmyyyy(value) {
    if (!value || !value.length) return '';
    var m = (value instanceof Date) ? moment(value) : moment(new Date(value));
    return m.format('DD.MM.YYYY');
};

export function date_asis2mmyyyy(value, format) {
    if (!value || !value.length) return '';
    var m = (value instanceof Date) ? moment(value) : moment(new Date(value));
    return m.format('MM/YYYY');
};

export function date_asis2format(value, format) {
    if (!value || !value.length) return '';
    var m = (value instanceof Date) ? moment(value) : moment(new Date(value));
    return m.format(format);
};

export function date_date2asis(value) {
    if (!value || !(value instanceof Date)) return '';
    return moment(value).format(asis_date_format);
};

export function date_dmyyyy2asis(value) {
    if (!value || !value.length) return '';
    var m = (value instanceof Date) ? moment(value) : moment(value, 'D.M.YYYY', true);
    return m.format(asis_date_format);
};

export function date_ddmmyyyy2asis(value) {
    if (!value || !value.length) return '';
    var m = (value instanceof Date) ? moment(value) : moment(value, 'DD.MM.YYYY', true);
    return m.format(asis_date_format);
};

export function date_timestamp2dmyyyy(value) {
    if (!value) return '';
    var m = (value instanceof Date) ? moment(value) : moment(new Date(value*1000));
    return m.format('D.M.YYYY');
};

export function date_timestamp2ddmmyyyy(value) {
    if (!value) return '';
    var m = (value instanceof Date) ? moment(value) : moment(new Date(value*1000));
    return m.format('DD.MM.YYYY');
};

export function datetime_asis2ddmmyyyy_hhmm(value) {
    if (!value || !value.length) return '';
    var m = (value instanceof Date) ? moment(value) : moment(new Date(value));
    return m.format('DD.MM.YYYY HH:mm');
};

export function datetime_asis2ddmmyyyy(value) {
    if (!value || !value.length) return '';
    var m = (value instanceof Date) ? moment(value) : moment(new Date(value));
    return m.format('DD.MM.YYYY');
};

export function datetime_timestamp2ddmmyyyy_hhmm(value) {
    if (!value) return '';
    var m = (value instanceof Date) ? moment(value) : moment(new Date(value*1000));
    return m.format('DD.MM.YYYY HH:mm');
};

export function datetime_timestamp2dmyyyy_hhmm(value) {
    if (!value) return '';
    var m = (value instanceof Date) ? moment(value) : moment(new Date(value*1000));
    return m.format('D.M.YYYY HH:mm');
};

export function jsdate_format(value, format) {
    return moment(value).format(format);
};

export function moment_format(value, format) {
    return value.format(format);
};

export function filesize_kB(value) {
    return Math.round(value/1024).toString() + ' kB';
};

export function filesize_MB(value) {
    return Math.round(value/1024/1024,1).toString() + ' MB';
};

export function filesize_GB(value) {
    return Math.round(value/1024/1024/1024,1).toString() + ' GB';
};

export function uppercase(value) {
    return value.toUpperCase();
};

export function lowercase(value) {
    return value.toLowerCase();
};

export function filesize_auto(value) {
    if (value < 1024) {
        return Math.round(value).toString() + ' B';
    }

    value  = value/1024;
    if (value < 1024) {
        return Math.round(value).toString() + ' kB';
    }

    value  = value/1024;
    if (value < 1024) {
        return Math.round(value,1).toString() + ' MB';
    }

    value  = value/1024;
    if (value < 1024) {
        return Math.round(value,1).toString() + ' GB';
    }

    value  = value/1024;
    return Math.round(value,1).toString() + ' TB';
};

export function float(value,decimal_places) {
    if (!value) return '';
    if (typeof value == "string") value = parseFloat(value);
    return !isNaN(value) ? value.toFixed(decimal_places) : '';
};
