//----------------------------------------------------------------------------
// ASIS Informational System
//----------------------------------------------------------------------------
// indexes/institution.js
// component for the list of institutions
//----------------------------------------------------------------------------
// (c) Astronomický ústav AV ČR, v.v.i. (see licence file for legal info)
//----------------------------------------------------------------------------

'use strict';
// jshint esversion:6


import Vue from 'vue';
import jquery from 'jquery';
import server from '../../../client/asis-server.js';
import * as validators from '../../../client/globals/validators.js';
import * as directives from '../../../client/globals/directives.js';
import * as mixins from '../../../client/globals/mixins.js';

// A general note on two-way data binding:
// The underlying component do not typicly  use v-model, but instead they use
// v-bind and v-on to handle proper 2-way propagation of
// the selected value. v-bind propagates the value from the parent
// to the child (e.g. to v-select) and v-on propagates changes of the value back to
// the parent using an event (in case of a component, $event is the actual value).
// The prop for the value MUST be named `value` for this to work.
// see: https://vuejs.org/v2/guide/components.html#Using-v-model-on-Components
// Example:
//      <v-select
//          v-bind:value="value"
//          v-on:input="$emit('input', $event)">
//      </v-select>



// department component
// a drop-down select for department
Vue.component('asis-institution', {
    template: `
        <div class="d-table w-100">
            <v-select 
                    v-model="institution"
                    :options="institutions"
                    :searchable="true" 
                    :label="selectLabel"
                    :clearable="false"
                    :disabled="readonly || disabled"
                    class="d-table-cell w-100">
            </v-select>
            <div v-if="show_add_button">
                <b-button v-b-modal="add_modal_name" variant="outline-success" class="d-table-cell ml-2 text-nowrap">{{$t('add')}}</b-button>
                <b-modal :id="add_modal_name" ref="modal" size="lg" centered :title="$t('modal_add_title')" @hide="load">
                    <asis-institution-add-form></asis-institution-add-form>
                    <template #modal-footer>
                        <p class="text-muted">{{$t('close_hint')}}</p>
                    </template>
                </b-modal>
            </div>
        </div>
    `,

    i18n: {
        messages: {
            cz: {
                "add": "Přidat do seznamu",
                "modal_add_title": "Vložení nové instituce",
                "close_hint": "Toto okno zavřete stisknutím ESC nebo kliknutím na křížek nahoře.",
            },
            en: {
                "add": "Add to list",
                "modal_add_title": "Adding new institution",
                "close_hint": "Hit ESC or click X mark on the top to close this box.",
            }
        }
    },

    props: {
        value: Number,
        allowAdd: Boolean,
        state: Boolean, 
        readonly: Boolean,
        disabled: Boolean,
    },

    data: function() {
        return {
            // define a variable to store the list of worksites
            institutions: [],    // list of institutions
            institution: null,   // selected institutions (this is a member of this.institutions - an object)
            selectLabel: 'institution_name',
            add_modal_name: 'asis-institution-add-modal-'+Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1),
        };
    },

    created: function() {
        this.load();
    },

    methods: {
        load() {
            // load the list of departments from server
            server.request(
                'indexes-pub', 'institutions-list', {}, null,
                (data)=>{
                    this.institutions = data;
                    // select a default
                    this.institution = null;
                    for (let i of this.institutions) {
                        if (i['institution_id'] == this.value) {this.institution = i; break;}
                    }
                }
            );
        },
    },

    computed: {
        show_add_button() {
            return this.allowAdd && !(this.readonly||this.disabled);
        }
    },

    watch: {
        value(){
            // on value change (induced by the parent) find the corresponding item in the list of departments
            // and set the local `department` variable accordingly
            var new_value = null;
            for (let i of this.institutions) {
                if (i['institution_id'] == this.value) {new_value = i; break;}
            }
            this.institution = new_value;
        },
        institution(value) {
            // on local value change (induced by v-select), notify the parent of the change,
            // but send back only the scalar value
            this.$emit('input', this.institution ? this.institution['institution_id'] : null);
        },
        // state() {
        //     // on state change, set/clear the error class of the v-select
        //     //TODO:rewrite this using Vue
        //     if (this.state) jquery(this.$el).removeClass('is-invalid'); else jquery(this.$el).addClass('is-invalid');
        // }
    }
});






// department component
// a drop-down select for department
Vue.component('asis-institution-add-form', {
    mixins:[directives.label_required, validators.vuelidate, mixins.state_validation],
    template: `
        <b-overlay :show="!institution">
        <b-form v-if="institution" @submit.stop.prevent="save">
            <b-form-group 
                    vertical
                    :label="$t('name_cz')"
                    id="groupInstituteNameCz"
                    label-for="inputInstituteNameCz"
                    :description="$t('name_cz_desc')"
                    v-label-required>
                <b-form-input 
                        id="inputInstituteNameCz"
                        type="text"
                        v-model="institution.institution_name_cz"
                        required
                        :state="state_validation($v.institution.institution_name_cz)"
                >
                </b-form-input>
                <asis-validation-feedback :state="$v.institution.institution_name_cz"></asis-validation-feedback>
            </b-form-group>
            <b-form-group 
                    vertical
                    :label="$t('name_en')"
                    id="groupInstituteNameEn"
                    label-for="inputInstituteNameEn"
                    :description="$t('name_en_desc')"
                    v-label-required>
                <b-form-input 
                        id="inputInstituteNameEn"
                        type="text"
                        v-model="institution.institution_name_en"
                        required
                        :state="state_validation($v.institution.institution_name_en)"
                >
                </b-form-input>
                <asis-validation-feedback :state="$v.institution.institution_name_en"></asis-validation-feedback>
            </b-form-group>
            <b-form-group 
                    vertical
                    :label="$t('acronym_cz')"
                    id="groupInstituteAcronymCz"
                    label-for="inputInstituteAcronymCz"
                    :description="$t('acronym_cz_desc')">
                <b-form-input 
                        id="inputInstituteAcronymCz"
                        type="text"
                        v-model="institution.institution_acronym_cz"
                        :state="state_validation($v.institution.institution_acronym_cz)"
                >
                </b-form-input>
                <asis-validation-feedback :state="$v.institution.institution_acronym_cz"></asis-validation-feedback>
            </b-form-group>
            <b-form-group 
                    vertical
                    :label="$t('acronym_en')"
                    id="groupInstituteAcronymEn"
                    label-for="inputInstituteAcronymEn"
                    :description="$t('acronym_en_desc')">
                <b-form-input 
                        id="inputInstituteAcronymEn"
                        type="text"
                        v-model="institution.institution_acronym_en"
                        :state="state_validation($v.institution.institution_acronym_en)"
                >
                </b-form-input>
                <asis-validation-feedback :state="$v.institution.institution_acronym_en"></asis-validation-feedback>
            </b-form-group>
            <b-form-group 
                    vertical
                    :label="$t('type')"
                    id="groupInstituteType"
                    label-for="inputInstituteType"
                    x-description=""
                    v-label-required>
                <b-form-select 
                        id="inputInstituteType" 
                        v-model="institution.institution_type" 
                        :options="institution_types.map(i=>({value:i,text:$t('types.'+i)}))"
                        :state="state_validation($v.institution.institution_type)"
                        required
                        >
                </b-form-select>
                <asis-validation-feedback :state="$v.institution.institution_type"></asis-validation-feedback>
            </b-form-group>
            <b-form-group 
                    vertical
                    :label="$t('country')"
                    id="groupInstituteCountry"
                    label-for="inputInstituteCountry"
                    x-description=""
                    v-label-required>
                <asis-country
                        id="inputInstituteCountry"
                        v-model="institution.country_code"
                        :state="state_validation($v.institution.country_code)"
                >
                </asis-country>
                <asis-validation-feedback :state="$v.institution.country_code"></asis-validation-feedback>
            </b-form-group>
            <b-alert :show="Boolean(save_error)" variant="danger">{{$t('errors.'+save_error)}}</b-alert>
            <asis-submit-button :state="$v" :saving="saving"></asis-submit-button>
        </b-form>
        </b-overlay>
    `,

    i18n: {
        messages: {
            cz: {
                "name_cz": "Český název",
                "name_cz_desc": "Plný oficiální český název instituce",
                "name_en": "Anglický název",
                "name_en_desc": "Plný oficiální anglický název instituce",
                "acronym_cz": "Český akronym",
                "acronym_cz_desc": "Užívaný český akronym (uveďte pouze, pokud jde o známou a úžívanou zkratku)",
                "acronym_en": "Anglická zkratka",
                "acronym_en_desc": "Užívaný anglický akronym (uveďte pouze, pokud jde o známou a úžívanou zkratku)",
                "type": "Druh instituce",
                "country": "Stát",
                "types": {
                    "university": "vysoká škola",
                    "high-school": "střední škola",
                    "elementary-school": "základní škola",
                    "research": "výzkumná organizace",
                    "government": "vládní organizace",
                    "industry": "průmyslový podnik",
                    "other": "ostatní",
                },
                "errors": {
                    "duplicate": "Instituce se stejným názvem již v seznamu existuje.",
                }
            },
            en: {
                "name_cz": "Czech title",
                "name_cz_desc": "Full official Czech name of the institution",
                "name_en": "English title",
                "name_en_desc": "Full official English name of the institution",
                "acronym_cz": "Czech acronym",
                "acronym_cz_desc": "(only if it is known and commonly used)",
                "acronym_en": "English acronym",
                "acronym_en_desc": "(only if it is known and commonly used)",
                "type": "Institution type",
                "country": "Country",
                "types": {
                    "university": "university",
                    "high-school": "high school",
                    "elementary-school": "elementary school",
                    "research": "research organization",
                    "government": "government organization",
                    "industry": "industry",
                    "other": "other",
                },
                "errors": {
                    "duplicate": "An institution with the same name is already in the list.",
                }
            }
        }
    },

    props: {
    },

    data: function() {
        return {
            institution: null,
            institution_types: [],
            saving: false,
            save_ok: null,
            save_error: null,
        };
    },

    validations() {
        return {
            institution: {
                institution_name_cz: {
                    required: validators.required,
                    maxLength: validators.maxLength(255),
                },
                institution_name_en: {
                    required: validators.required,
                    maxLength: validators.maxLength(255),
                },
                institution_acronym_cz: {
                    maxLength: validators.maxLength(30),
                },
                institution_acronym_en: {
                    maxLength: validators.maxLength(30),
                },
                institution_type: {
                    required: validators.required,
                },
                country_code: {
                    required: validators.required,
                },
            },
        };
    },

    created() {
        // get an empty record
        server.request(
            'indexes-usr', 'institutions-get', {}, null,
            (data,extra) => {
                this.institution = data;
                this.institution_types = extra.institution_types;
            }
        );
    },

    methods: {
        save() {
            this.saving = true;
            this.save_ok = null;
            this.save_error = null;
            server.request(
                'indexes-usr', 'institutions-add', this.institution, null,
                (data) => {
                    this.save_ok = true;
                    this.$bvModal.hide('asis-institution-add-modal');
                },
                (error) => {
                    this.save_error = error;
                }
            ).then(()=>{
                this.saving = false;
            });
        }
    }
});

