import html from './menus-list-pub.html';
import lang from './menus-list-pub.lang.json';
import server from '../../../client/asis-server.js';
import * as mixins from '../../../client/globals/mixins.js';
import * as canteen_mixins from './mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.loadDataOnCreate, 
        mixins.storeGetters(['lang'],'asis/ui'), 
        canteen_mixins.filters
    ],

    data: function() {
        return {
            menus: [],
        };
    },

    methods: {
        load() {
            server.request(
                'canteen-pub', 'menus-list', {}, null,
                (data, extra)=>{
                    this.menus = data;
                }
            );
        },
    },

};
