'use strict';
// jshint esversion:6
// jshint -W069


import config from './publications.config.json';
import view_publications_list_mgr from './views/publications-list-mgr.js';
import view_publications_edit from './views/publications-edit.js';
import view_publications_import from './views/publications-import.js';
import view_publications_list_usr from './views/publications-list-usr.js';


const module_publications_usr = {
    module_name: 'publications-usr',
    module_class: 'usr',
    title: config.usr.title,
    icon: config.usr.icon,
    routes: [
        {path:'', redirect:{name:'publications-usr-list'}},
        {name:'publications-usr-list', path:'list', component:view_publications_list_usr},
    ]
};

const module_publications_mgr = {
    module_name: 'publications-mgr',
    module_class: 'mgr',
    title: config.mgr.title,
    icon: config.mgr.icon,
    routes: [
        {path:'', redirect:{name:'publications-mgr-list'}},
        {name:'publications-mgr-list', path:'list', component:view_publications_list_mgr},
        {name:'publications-mgr-edit', path:'edit/:publication_id', component:view_publications_edit},
        {name:'publications-mgr-import', path:'import', component:view_publications_import},
    ]
};

export default [module_publications_usr,module_publications_mgr];

