import html from './projects-list-usr.html';
import lang from './projects-list-usr.lang.json';
//import server from '../../../client/asis-server.js';
import * as translators from '../../../client/globals/translators.js';
import * as mixins from '../../../client/globals/mixins.js';
import store from './projects-list-store.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.loadDataOnCreate, 
        mixins.store_create(store, 'projects-list'), 
        mixins.storeAccess,
        mixins.storeGetters(['projects','loading']), 
    ],

    data: function() {
        var $t = this.$t.bind(this);
        // var usr = (this.module_class == 'usr');
        // var mgr = (this.module_class == 'mgr');
        return {
            // usr,
            // mgr,
            // definition of table fields with variable items
            // (https://stackoverflow.com/questions/44908159/how-to-define-an-array-with-conditional-elements)
            projects_fields: [
                {key:'acronym', sortable:true, label:$t('list.acronym')},
                {key:'number', sortable:true, label:$t('list.number')},
                //... (mgr) ? [{key:'pi_asu', sortable:true, label:$t('list.pi_asu')}] : [],
                {key:'provider_acronym', sortable:true, label:$t('list.provider')},
                {key:'start', sortable:true, label:$t('list.start'), formatter:translators.date_asis2ddmmyyyy},
                {key:'end', sortable:true, label:$t('list.end'), formatter:translators.date_asis2ddmmyyyy},
                {key:'status', sortable:true, label:$t('list.status')},
                {key:'actions', sortable:false, label:''},
            ],
        };
    },

    methods: {
        load() {
            this.store_dispatch('load');
        },

        project_delete(project) {
            this.$bvModal.msgBoxConfirm(
                this.$t('delete_confirmation', [project.acronym])
            )
            .then(value => {
                if (value) this.store_dispatch('delete', project.project_id);
            })
            .catch(err => {});
        }
    },

};


