import Vue from 'vue';
import server from '../../../client/asis-server.js';
import * as validators from '../../../client/globals/validators.js';
import moment from 'moment';




export default function (store, namespace, module) {
    const validator = new Vue({
        mixins: [
            validators.vuelidate
        ],
        computed: {
            drive() {
                return store.getters[namespace + '/drive'];
            },
            drive_duration() {
                var drive = store.getters[namespace + '/drive'];
                if (!drive) return 0.0;
                var dt_start = moment(drive.date_start+' '+drive.date_start_time, "YYYY/MM/DD HH:mm");
                var dt_end   = moment(drive.date_end+' '+drive.date_end_time, "YYYY/MM/DD HH:mm");
                return moment.duration(dt_end.diff(dt_start)).hours();
            }
        },
        validations() {
            return {
                drive: {
                    car: {
                        required: validators.required,
                    },
                    date_start: {
                        required: validators.required,
                        asis_date: validators.asis_date,
                    },
                    date_start_time: {
                        required: validators.required,
                        time: validators.time('HH:mm'),
                    },
                    date_end: {
                        required: validators.required,
                        asis_date: validators.asis_date,
                    },
                    date_end_time: {
                        required: validators.required,
                        time: validators.time('HH:mm'),
                        notSameAs: validators.notSameAs('date_start_time'),
                    },
                    origin: {
                        required: validators.required,
                        maxLength: validators.maxLength(50),
                    },
                    destination: {
                        required: validators.required,
                        notSameAs: validators.notSameAs('origin'),
                        maxLength: validators.maxLength(50),
                    },
                    odo_start: {
                        required: validators.required,
                        integer: validators.integer,
                    },
                    odo_end: {
                        required: validators.required,
                        integer: validators.integer,
                        minValue: validators.minValue(this.drive ? this.drive.odo_start+1 : null),
                    },
                    purpose: {
                        maxLength: validators.maxLength(100),
                    },
                    fueling_volume: {
                        required: validators.required,
                        decimal: validators.decimal,
                        minValue: validators.minValue(0),
                    },
                    fueling_cost: {
                        required: validators.required,
                        decimal: validators.decimal,
                        minValue: validators.minValue(0),
                    },
                    fueling_currency: {
                        required: validators.required,
                        minLength: validators.minLength(3),
                        maxLength: validators.maxLength(3),
                    },
                    other_cost: {
                        required: validators.required,
                        decimal: validators.decimal,
                        minValue: validators.minValue(0),
                    },
                    other_currency: {
                        required: validators.required,
                        minLength: validators.minLength(3),
                        maxLength: validators.maxLength(3),
                    },
                    breaks: {
                        required: validators.requiredIf(()=>Boolean(this.drive_duration>4.5)),
                        format: validators.regex(/^(\d\d\:\d\d\-\d\d\:\d\d[,; ]*)*$/),
                    },
                    comments: {
                    },
                }
            };
        },
    });


    const store_module = {
        namespaced: true,

        state: () => ({
            drive: null, 
            cars: [],
            destinations: [],
            can_edit: false,
            loading: false,
            saving: false,
            removing: false,
            error: null,
        }),

        getters: {
            drive: state => state.drive,
            cars: state => state.cars,
            destinations: state => state.destinations,
            can_edit: state => state.can_edit,
            loading: state => state.loading,
            saving: state => state.saving,
            removing: state => state.removing,
            error: state => state.error,
            $v: state => Object.assign({}, validator.$v),    // this is not to expose API of $v
        },


        mutations: {
            cars(state, cars) {
                state.cars = cars;
            },

            drive(state, {drive, destinations, can_edit}) {
                if (drive) {
                    // extract times from datetime fields to separate fields
                    var d = new Date(drive.date_start);
                    drive.date_start = d.asis_date();
                    drive.date_start_time = new Intl.DateTimeFormat('cs', {hour:'2-digit' }).format(d) + ":" + new Intl.DateTimeFormat('en', {minute:'2-digit' }).format(d);
                    var d = new Date(drive.date_end);
                    drive.date_end = d.asis_date();
                    drive.date_end_time = new Intl.DateTimeFormat('cs', {hour:'2-digit' }).format(d) + ":" + new Intl.DateTimeFormat('en', {minute:'2-digit' }).format(d);
                    // remember the original origin, destination and odo_end
                    drive.origin_valid = Boolean(drive.origin.length > 0);
                    drive.destination_original = drive.destination;
                    drive.odo_end_original = drive.odo_end;
                }
                state.drive = drive;
                state.destinations = destinations || [];
                state.can_edit = can_edit || false;
            },

            loading(state, loading) {
                state.loading = loading;
            },

            saving(state, saving) {
                state.saving = saving;
            },

            removing(state, removing) {
                state.removing = removing;
            },

            error(state, error) {
                state.error = error;
            },
        },


        actions: {
            load_cars(context) {
                if (context.state.cars.length > 0) return true;
                return server.request(
                    module, 'cars-list', {}, null,
                    (data, extra) => {
                        context.commit('cars', data);
                    }
                );
            },

            load_drive(context, {drive_id, car}) {
                if (!drive_id && !car) return context.commit('drive', {drive:null, destinations:[], can_edit:false});

                context.commit('loading', true);
                return server.request(
                    module, 'drives-get', {drive_id, car}, {},
                    (data, extra) => {
                        context.commit('drive', {
                            drive: Object.assign(data, {last:extra.last||false}), 
                            destinations: extra.destinations,
                            can_edit: extra.can_edit,
                        });
                    }
                ).then(()=>{
                    context.commit('loading', false);
                })
                .catch(()=>{
                    context.commit('drive', {}, [], false);
                });
            },

            save(context) {
                if (validator.$v.$invalid) return;
                // combine time info back with date info
                var drive_data = Object.assign({}, context.state.drive);  // make copy before modifying it
                drive_data.date_start += ' '+context.state.drive.date_start_time + ':00';
                drive_data.date_end   += ' '+context.state.drive.date_end_time + ':00';

                // send to server
                // return a promise that resolves based on the server response
                return new Promise((resolve, reject) => {
                    context.commit('saving', true);
                    context.commit('error', null);
                    server.request(
                        module, 'drives-save', drive_data, {},
                        (data) => {
                            resolve();
                        },
                        (error) => {
                            context.commit('error', error);
                            reject(error);
                        }
                    ).then(()=>{
                        // when finished, reset uploads and reset saving flag
                        context.commit('saving', false);
                    });
                });
            },

            remove(context) {
                context.commit('removing', true);
                return server.request(
                    module, 'drives-remove', context.state.drive
                ).then(()=>{
                    context.commit('removing', false);
                });
            },

        },

    };

    return store_module;
};
