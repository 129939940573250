import html from './projectsheet-tab.html';
import lang from './projectsheet-tab.lang.json';
import * as mixins from '../../../../client/globals/mixins.js';
import * as translators from '../../../../client/globals/translators.js';


export default {
    props: {
        contract_id: Number,
    },

    template: html,

    i18n: {
        messages: lang
    },

    mixins: [
        mixins.storeAccess,
    ],

    data: function() {
        return {
            readonly: false,
            save_btn_class: null,
        };
    },

    methods: {
        balance_hours() {
            if (this.contract.type !== 'STD') return false;

            if (!confirm(this.$t('balance_hours_confirmation'))) return false;

            try {
                // total amount of hours that is to be worked on the contract in the month
                var targetTotal = this.contract.calendar_info.contract_working_hours * this.contract.duty/100.0;
            
                // sum currently stated contract hours
                var currentSum = 0.0;
                this.contract.tasks.forEach(task => {
                    currentSum += parseFloat(((this.contract.task_hours[task] || {}).hours) || 0.0);
                });

                // adjust hours to match the target total
                var f = targetTotal / currentSum;
                this.contract.tasks.forEach(task => {
                    if (currentSum > 0) {
                        this.contract.task_hours[task].hours = 
                            (f*parseFloat((this.contract.task_hours[task]||{}).hours || 0.0)).toFixed2(1);
                    } else {
                        this.contract.task_hours[task].hours = (targetTotal/this.contract.tasks.length).toFixed2(1);
                    }
                });
            } catch(e) {
                window.alert(this.$t('balance_hours_error'));
            }
        },

        save() {
            this.save_btn_class = 'fas fa-circle-notch fa-spin mr-2';
            this.store_dispatch('save_project_contracts').then(()=>{
                this.save_btn_class = 'fas fa-check-circle';
                setTimeout(()=>{this.save_btn_class=null;}, 1500);
            });
        },

        date_asis2format: translators.date_asis2format
    },

    computed:{
        contract() {
            return this.store_getter('contract')(this.contract_id);
        }
    },

};

 
