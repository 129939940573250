//----------------------------------------------------------------------------
// ASIS Informational System
//----------------------------------------------------------------------------
// indexes/class.js
// component for the list of employees work-classes
//----------------------------------------------------------------------------
// (c) Astronomický ústav AV ČR, v.v.i. (see licence file for legal info)
//----------------------------------------------------------------------------

'use strict';
// jshint esversion:6


import Vue from 'vue';

// A general note on two-way data binding:
// The underlying component do not typicly  use v-model, but instead they use
// v-bind and v-on to handle proper 2-way propagation of
// the selected value. v-bind propagates the value from the parent
// to the child (e.g. to v-select) and v-on propagates changes of the value back to
// the parent using an event (in case of a component, $event is the actual value).
// The prop for the value MUST be named `value` for this to work.
// see: https://vuejs.org/v2/guide/components.html#Using-v-model-on-Components
// Example:
//      <v-select
//          v-bind:value="value"
//          v-on:input="$emit('input', $event)">
//      </v-select>


// date/datetime component
Vue.component('asis-class', {
    template: `
        <v-select 
                v-bind:value="value"
                @input="$emit('input', $event)"
                :options="classes" 
                :searchable="false" 
                :clearable="false"
                :disbaled="readonly || disabled"
                >
        </v-select>
    `,

    props: ['value','state','readonly','disabled'],

    data: function() {
        return {
            // define a variable to store the list of worksites
            classes: ['OS','OT','V1','V2','V3','V4','V5','V6','VE']
        };
    },

    //TODO: // load the list from server
    // created: function() {
    //     // set default
    //     if (!this.value) this.value = this.classes[0];
    // },

    watch: {
        state() {
            // on state change, set/clear the error class of the v-select
            //TODO:rewrite this using Vue
            if (this.state) jquery(this.$el).removeClass('is-invalid'); else jquery(this.$el).addClass('is-invalid');
        }
    }
});

