import html from './pages-edit.html';
import lang from './pages-edit.lang.json';
import server from '../../../client/asis-server.js';
import * as directives from '../../../client/globals/directives.js';
import * as validators from '../../../client/globals/validators.js';
import * as mixins from '../../../client/globals/mixins.js';
import document_info from './components/document-info.js';

// import 'tinymce'
// import 'tinymce-theme-silver';
// import 'tinymce-plugins-code';
// import 'tinymce-plugins-image';
// import 'tinymce-plugins-link';
// import Editor from 'tinymce-vue';
// import ClassicEditor from 'ckeditor';
// import 'ckeditor-cs';
// import CKEditor from 'ckeditor-vue';


export default {
    template: html,

    // components:{
    //     //'tinymce-editor': Editor
    //     'ckeditor': CKEditor.component
    // },

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate, directives.label_required, validators.vuelidate],

    components: {
        'page-assets': document_info
    },

    data: function() {
        return {
            //editor: ClassicEditor,
            page: {},                           // page data
            groups: [],                         // page groups
            content_types: [],                  // content types
            content_sources: [],                // content sources
            assets: [],
            assets_upload: {
                file: null,
                description: '',
            },
            assets_upload_progress: 0,
            assets_upload_error: null,
            submit_error: null,                 // soft error
        };
    },

    validations: {
        page: {
            title_cz: {
                required: validators.required,
            },
            title_en: {
                required: validators.required,
            },
            menu_title_cz: {
                //required: validators.required,
                maxLength: validators.maxLength(25),
            },
            menu_title_en: {
                //required: validators.required,
                maxLength: validators.maxLength(25),
            },
            group: {
                required: validators.required,
            },
            slug: {
                required: validators.required,
                format: validators.regex(/^[a-z0-9-]+$/),
                minLength: validators.minLength(3),
                maxLength: validators.maxLength(30),
            },
            order: {
                required: validators.required,
                integer: validators.integer,
            },
            content_type: {
                required: validators.required,
            },
            content_cz: {
            },
            content_en: {
            },
        },
        assets_upload: {
            file: {
                file: validators.file,
            },
            description: {
            }
        }

    },

    methods: {
        load() {
            server.request(
                'intranet-usr', 'pages-get',
                {page_id: this.$route.params.page_id||0}, null,
                (data, extra)=>{
                    this.page = data;
                    this.assets = extra.assets,
                    this.groups = extra.groups;
                    this.content_types = extra.content_types;
                    this.content_sources = extra.content_sources;
                }
            );
        },

        load_assets() {
            server.request(
                'intranet-usr', 'assets-list',
                {page_id: this.$route.params.page_id||0}, null,
                (data, extra)=>{
                    this.assets = data;
                }
            );
        },

        submit() {
            // check if the form validates
            if (this.$v.$invalid) return;

            if (!this.page.menu_title_cz) this.page.menu_title_cz = this.page.title_cz;
            if (!this.page.menu_title_en) this.page.menu_title_cz = this.page.title_en;

            this.submit_error = null;
            server.request(
                'intranet-usr', 'pages-save', this.page, null, 
                (data) => {
                    // if ok, go to previous view
                    this.$router.back();
                }, 
                (error) => {
                    this.submit_error = error;
                }
            );
            return false;
        },

        assets_upload_file() 
        //! Uploads a file as a page attachment.
        {
            // check if page has been assigned an ID
            if (!this.page.page_id) return;
            // check if the form validates and file is selected
            if (this.$v.assets_upload.$invalid || !this.assets_upload.file) return;

            this.assets_upload_progress = 0;
            this.assets_upload_error = null;
            var data = Object.assign({page_id:this.page.page_id}, this.assets_upload);
            server.request(
                'intranet-usr', 'assets-save', data, null,
                (result)=>{
                    this.assets_upload.file = null;
                    this.assets_upload.description = '';
                    this.load_assets();
                },
                (error)=>{
                    this.assets_upload_error = error;      // show error message
                },
                {onProgress:(perc)=>{
                    this.assets_upload_progress = perc
                }, timeout:600000}
            ).then(()=>{
                this.assets_upload_progress = 0;       // hide upload progress bar
            })
        },

        assets_remove(document_id) 
        //! Remove an asset from the page.
        {
            if (!confirm(this.$t('assets.remove'))) return;

            server.request(
                'intranet-usr', 'assets-remove', {page_id:this.page.page_id, doc_id:document_id}, null,
                (result)=>{
                    this.load_assets();
                }
            );
        }

    }
};

