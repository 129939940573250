import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import modules from './asis-modules.js';
import store_auth from './asis-store-auth.js';
import store_ui from './asis-store-ui.js';

const store = new Vuex.Store({
    modules: {
        // global stores
        asis: {
            namespaced: true,
            state: {},
            modules: {
                auth: store_auth,
                ui: store_ui,
            }
        },
        // module-specific stores
        ...modules.stores
    }
});

export default store;
