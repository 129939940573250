import * as translators from '../../../client/globals/translators.js';


export default {
    props: {
        number: {                       // sequential number of the attachment (>=0); (not displayed if not given)
            type: Number,
            default: null,
        },
        description: String,            // attachment textual description
        descriptionState: Object,       // validation object for attachment description
        descriptionHint: String,        // input placeholder text for attachment description
        doc: File,
        docState: Object,
        docHint: String,
        pdf: File,
        pdfState: Object,
        pdfHint: String,
    },

    data() {
        return {};
    },

    template: `
        <div>
            <p v-if="number!==null" class="my-0 mb-1 font-weight-bold" style="font-size:0.8rem;">
                {{$t('attachment')}} {{Number(number)+1}}
            </p>

            <b-form-input
                type="text"
                v-bind:value="description"
                v-on:input="$emit('update-description', $event)"
                :state="descriptionState.$dirty ? !descriptionState.$error : null"
                :placeholder="descriptionHint"
                required
            ></b-form-input>
            <asis-validation-feedback :state="descriptionState"></asis-validation-feedback>

            <b-form-file
                v-bind:value="doc"
                v-on:input="$emit('update-doc', $event)"
                :state="docState.$dirty ? !docState.$error : null"
                :placeholder="docHint"
                class="mt-2"
            ></b-form-file>
            <asis-validation-feedback :state="docState"></asis-validation-feedback>

            <b-form-file
                v-bind:value="pdf"
                v-on:input="$emit('update-pdf', $event)"
                accept=".pdf"
                :state="pdfState.$dirty ? !pdfState.$error : null"
                :placeholder="pdfHint"
                class="mt-2"
            ></b-form-file>
            <asis-validation-feedback :state="pdfState"></asis-validation-feedback>
        </div>
    `,

    i18n: {
        messages: {
            cz: {
                attachment: "Příloha",
            },
            en: {
                attachment: "Attachment",
            }
        }
    },

};
