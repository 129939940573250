import html from './menus-list-usr.html';
import lang from './menus-list-usr.lang.json';
import server from '../../../client/asis-server.js';
import * as mixins from '../../../client/globals/mixins.js';
import * as canteen_mixins from './mixins.js';


export default {
    template: html,

    i18n: {
        messages: lang
    },

    mixins: [mixins.loadDataOnCreate, canteen_mixins.week_shift, canteen_mixins.filters, canteen_mixins.day_heading],

    data: function() {
        return {
            loading: false,
            menus: [],
            deductions_allowed: null,
            max_reservations: 1,
        };
    },

    methods: {
        load() {
            this.loading = true;
            var from = this.week_start.format('YYYY/MM/DD');  // format to asis date
            server.request(
                'canteen-usr', 'menus-list', {from}, null,
                (data, extra)=>{
                    this.menus = data;
                    this.deductions_allowed = extra.deductions_allowed;
                    this.max_reservations = extra.max_reservations;
                }
            ).then(()=>{
                this.loading = false;
            });
        },

       reservation_make(menu_id, choice) {
            var menu = this.menus.findObject('menu_id', menu_id);
            this.$set(menu, 'reserving', choice);
            server.request(
                'canteen-usr', 'reservations-make', {menu_id, choice}, null,
                (data)=>{this.load()},
                (error)=>{
                    this.$bvModal.msgBoxOk(this.$t('error_'+error), {centered:true,okVariant:'danger'});
                    this.load();
                }
            ).then(()=>{
                this.$set(menu, 'reserving', null);
            });
        },

        reservation_cancel(menu_id, reservation_id) {
            var menu = this.menus.findObject('menu_id', menu_id);
            var reservation = menu.reservations.findObject('reservation_id', reservation_id);
            this.$set(reservation, 'canceling', true);
            server.request(
                'canteen-usr', 'reservations-cancel', {reservation_id}, null,
                (data)=>{this.load()},
                (error)=>{}
            ).then(()=>{
                this.$set(reservation, 'canceling', null);
            });
        },

        rate(menu_id, choice, rating) {
            server.request(
                'canteen-usr', 'menus-rate', {menu_id, choice, rating, comment:''}, null,
                (data)=>{this.load()},
                (error)=>{}
            );
        },
    },

};
